import React, { useState, useEffect, useContext } from 'react';
import { SelectInput } from 'react-admin';
import { Form } from 'react-final-form';
import { head, isEmpty, orderBy, range } from 'lodash';
import dayjs from 'dayjs';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	dropDownEllipsisStyle,
	InsightReportTableGlobalStyles,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	Box,
	Typography,
	makeStyles,
	ThemeProvider,
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
	TableHead,
	Table,
	Tooltip,
	TablePagination,
	CardContent,
	Card,
	TextField,
	Checkbox,
	CircularProgress,
} from '@material-ui/core';
import {
	exportToCsv,
	formatEmployeeId,
	getPrefix,
} from '../../../Utils/string.util';
import { UserProfileContext } from '../../../App';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
	useGetActiveUsersQuery,
	useGetLocationsByOrgIdQuery,
	useGetOrganiztionHolidayYearQuery,
	useGetUserOptionalHolidaysForReportQuery,
} from '../../../generated/graphql';
import {
	OPTIONAL_HOLIDAY_EXPORT_COLUMN_WITH_LOCATION,
	OPTIONAL_HOLIDAY_EXPORT_COLUMN_WITHOUT_LOCATION,
} from '../Constant';
import { Autocomplete } from '@material-ui/lab';

interface Holiday {
	created_at?: Date;
	date: string;
	id: string;
	is_restricted?: boolean | null;
	name: string;
	org_id: string;
	updated_at?: Date;
	year?: string | null;
	location_id?: string;
}
interface YearProps {
	id: number;
	name: number;
}

const useStyles = makeStyles({
	loading: {
		fontSize: '17px',
		fontFamily: 'Manrope-medium',
		marginRight: '5px',
	},
	heading: {
		fontSize: '14px',
		fontFamily: 'Manrope-extrabold',
		fontWeight: 500,
		lineHeight: 1.6,
		letterSpacing: '0.0075em',
		marginTop: '10px',
		marginLeft: '30px',
	},
	reducedCountContainer: {
		height: '65px',
		background: '#FFFFFF',
		borderRadius: '4px',
		marginTop: '16px',
		display: 'flex',
	},
	countContainerWithoutGrey: {
		minWidth: '161px',
		height: '50px',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginLeft: '30px',
		marginTop: '20px',
	},
	largeContainer: {
		marginTop: '16px',
		display: 'grid',
		gap: '15px',
		minHeight: '200px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
		minHeight: '200px',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	dropdownLabel: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});

interface option {
	id: any;
	name?: String | null | undefined;
}

interface HolidayMapping {
	full_name: string;
	emp_id: string | number;
	holiday: string;
	date: string;
	location: string;
}

const OptionalHolidayReport = () => {
	const {
		dateFormat,
		noOfLocations,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		orgId: organizationId,
	} = useContext<any>(UserProfileContext);
	const classes = modalFormStyle();
	const commonStyle = InsightReportTableGlobalStyles();
	const customStyles = useStyles();
	const ellipsis = dropDownEllipsisStyle();
	const [yearDropDownOption, setYearDropDownOption] = useState<YearProps[]>();
	const [year, setYear] = useState<number | undefined>(dayjs().year());
	const [isTableLoading, setIsTableLoading] = useState(false);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<option[]>([]);
	const [reporte, setReportee] = useState<any>();
	const dropDownEllipsis = dropDownEllipsisStyle();
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [organizationHolidayDates, setOrganizationHolidayDates] = useState({
		startDate: '',
		endDate: '',
	});
	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<option[]>([]);

	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		String[]
	>([]);
	const [locationDropDown, setLocationDropDown] = useState<option[]>([]);

	const [holidayMappingTableData, setHolidayMappingTableData] = useState<
		HolidayMapping[]
	>([]);

	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return [];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return [{}];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						contractors: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},

					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
		}
		return [{}];
	};
	const { data: holiday } = useGetOrganiztionHolidayYearQuery({
		fetchPolicy: 'network-only',
	});

	const { data: optionalHolidayList, loading: isOptionalHolidayLoading } =
		useGetUserOptionalHolidaysForReportQuery({
			fetchPolicy: 'network-only',
			variables: {
				filter: {
					user: {
						_or: getLocationFilter(),
						id:
							selectedResourceIdList && selectedResourceIdList?.length > 0
								? {
										_in: selectedResourceIdList,
								  }
								: { _in: [] },
					},
					org_holiday: {
						date: {
							_gte: organizationHolidayDates?.startDate,
							_lte: organizationHolidayDates?.endDate,
						},
					},
				},
			},
		});
	const { data: resourceList } = useGetActiveUsersQuery({
		skip: !year,
		variables: {
			filter: {
				is_active: { _eq: true },
			},
		},
	});

	const { data: orgLocations } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});
	useEffect(() => {
		if (isEmpty(orgLocations?.org_location)) {
			return;
		}
		const locationOptionList = orgLocations?.org_location?.map((value) => {
			return {
				id: value?.id || '--',
				name: value?.name || '--',
			};
		});
		const defaultLocationIdList = locationOptionList?.map(
			(resource: { id: string; name: string }) => resource?.id
		);
		const sortedLocationOption = orderBy(locationOptionList, ['name'], ['asc']);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{
				id: 'Unassigned',
				name: 'Unassigned',
			},
		]);
		setSelectedLocationIdList(defaultLocationIdList || []);
		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(locationOptionList || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [orgLocations]);
	useEffect(() => {
		if (!resourceList) {
			return;
		}
		const filterUserList = resourceList?.user;
		const idList = filterUserList?.map((resource) => resource?.id);
		setReportee(filterUserList);
		setSelectedResourceIdList(idList);
		setSelectedResourceOptionValues([
			{ id: 'All', name: 'All' },
			...filterUserList,
		]);
		return;
	}, [resourceList]);

	useEffect(() => {
		if (!year) {
			return;
		}
		const startDate = dayjs(year).startOf('month').format('MM-DD');
		const endDate = dayjs().endOf('year').format('MM-DD');
		setOrganizationHolidayDates({
			startDate: `${year}-${startDate}`,
			endDate: `${year}-${endDate}`,
		});
	}, [year]);
	useEffect(() => {
		if (
			!holiday ||
			!holiday?.org_holidays_aggregate ||
			!holiday?.org_holidays_aggregate?.aggregate
		) {
			return;
		}
		const aggregate = holiday?.org_holidays_aggregate?.aggregate;
		const maxDate = dayjs(aggregate?.max?.date).year() + 1;
		const minDate = dayjs(aggregate?.min?.date).year();
		const yearOption = range(minDate, maxDate).map((value: number) => {
			return {
				id: value,
				name: value,
			};
		});
		setYearDropDownOption(yearOption);
	}, [holiday]);

	useEffect(() => {
		if (isEmpty(optionalHolidayList?.user_optional_holiday_mapping)) {
			setHolidayMappingTableData([]);
			return;
		}
		setIsTableLoading(true);
		const tableData =
			optionalHolidayList?.user_optional_holiday_mapping.map((mapping) => {
				const userDetails = mapping?.user;
				const isEmployee = userDetails?.user_type === 'employee' ? true : false;
				const existingEmployeeId = userDetails?.employee?.emp_id || '';
				const existingContractorId =
					head(userDetails?.contractors)?.contractor_id || '';
				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);
				const employeeId = isEmployee
					? userDetails?.employee?.emp_id
						? `${
								getPrefix(
									userDetails?.employee?.employee_prefix?.name,
									userDetails?.employee?.employee_prefix?.name
								) || ''
						  }${formattedEmployeeId || ''}`
						: '--'
					: head(userDetails?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(userDetails?.contractors)?.contractor_prefix?.name,
								head(userDetails?.contractors)?.contractor_prefix?.name
							) || ''
					  }${
							userDetails?.contractors &&
							head(userDetails?.contractors)?.contractor_id
								? formattedContractorId
								: ''
					  }`
					: '--';
				const userLocation = isEmployee
					? userDetails?.employee?.org_location?.name || '--'
					: head(userDetails?.contractors)?.org_location?.name || '--';

				const reportingTo = isEmployee
					? userDetails?.employee?.reportsTo?.full_name || '--'
					: head(userDetails?.contractors)?.reportee?.full_name || '--';
				const reportingToEmail = isEmployee
					? userDetails?.employee?.reportsTo?.email || '--'
					: head(userDetails?.contractors)?.reportee?.email || '--';
				return {
					full_name: userDetails?.full_name || '--',
					user_type: isEmployee ? 'Employee' : 'Contractor',
					user_email: userDetails?.email || '--',
					emp_id: employeeId || '--',
					holiday: mapping?.org_holiday?.name || '--',
					date: mapping?.org_holiday?.date
						? dayjs(mapping?.org_holiday?.date).format(dateFormat)
						: '--',
					location: userLocation || '--',
					reporting_to: reportingTo || '--',
					reporting_to_email: reportingToEmail || '--',
				};
			}) || [];
		setHolidayMappingTableData(tableData);
		setIsTableLoading(false);
	}, [optionalHolidayList, dateFormat, isFixedEmpIdLengthEnabled, empIdLength]);

	const handlePageChange = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleRowsPerPageChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateCSV = () => {
		if (isEmpty(holidayMappingTableData)) {
			return;
		}

		const holidayRow: any = holidayMappingTableData?.map((user: any) => {
			return noOfLocations && noOfLocations > 0
				? [
						user?.emp_id || '--',
						user?.full_name || '--',
						user?.user_email,
						user?.user_type || '--',
						user?.holiday || '--',
						user?.date || '--',
						user?.location || '--',
						user?.reporting_to || '--',
						user?.reporting_to_email || '--',
				  ]
				: [
						user?.emp_id || '--',
						user?.full_name || '--',
						user?.user_email,
						user?.user_type || '--',
						user?.holiday || '--',
						user?.date || '--',
						user?.reporting_to || '--',
						user?.reporting_to_email || '--',
				  ];
		});

		const optionalHolidayExportData = [
			noOfLocations && noOfLocations > 0
				? OPTIONAL_HOLIDAY_EXPORT_COLUMN_WITH_LOCATION
				: OPTIONAL_HOLIDAY_EXPORT_COLUMN_WITHOUT_LOCATION,
			...holidayRow,
		];
		exportToCsv('OptionalHolidayList.csv', optionalHolidayExportData);
	};
	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<Box
				className={customStyles.dropdownLabel}
				marginTop={'6px'}
				marginLeft={'4px'}
			>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? head(currentSelectedOptions)?.name
					: `Selected ${currentSelectedOptions?.length} items`}
			</Box>
		);
	};
	const getLocationFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${customStyles.dropdownLabel} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	return (
		<div>
			<Box>
				<Typography className={customStyles.heading}>
					Optional Holiday Report
				</Typography>
			</Box>
			<Box className={customStyles.reducedCountContainer} display='flex'>
				<Box className={customStyles.countContainerWithoutGrey} marginTop='5px'>
					<Box display='flex'>
						<Box marginTop='-15px' marginRight='15px' width='max-content'>
							<Form onSubmit={() => {}}>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<Box display='flex'>
												<Box>
													<Box className={classes.label}>Choose Year</Box>
													<SelectInput
														source='name'
														label=''
														choices={yearDropDownOption || []}
														defaultValue={year}
														onChange={(event: any) => {
															setPage(0);
															setYear(event?.target?.value);
														}}
													/>
												</Box>
												{noOfLocations && noOfLocations > 0 && (
													<Box marginLeft='5px' width='200px'>
														<Box className={classes.label}>Choose location</Box>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedLocationOptionValue &&
																selectedLocationOptionValue?.length > 0
																	? selectedLocationOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter((status) => status.id !== 'All')
																	.map((status) => status?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const allStatusIds = locationDropDown?.map(
																		(status) => status?.id
																	);
																	setSelectedLocationIdList(allStatusIds);

																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	locationDropDown?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(status) => status?.id !== 'All'
																	);
																	setSelectedLocationOptionValue(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource) => resource?.id
																	);
																	setSelectedLocationIdList(currentIds);

																	return;
																}
																if (
																	selectedIds?.length ===
																		locationDropDown?.length &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const responseIds = locationDropDown?.map(
																		(status) => status?.id
																	);
																	setSelectedLocationIdList(responseIds);

																	return;
																}
																if (
																	selectedLocationOptionValue.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedLocationIdList([]);
																	setSelectedLocationOptionValue([]);

																	return;
																}
																const selectedId = value?.map(
																	(status) => status?.id
																);
																setSelectedLocationIdList(selectedId);
																setSelectedLocationOptionValue(value);
															}}
															options={
																locationDropDown && locationDropDown?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...locationDropDown,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getLocationFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextField
																		{...params}
																		label=''
																		placeholder={''}
																	/>
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${dropDownEllipsis.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												)}
												<Box display='block' marginLeft='5px' width='200px'>
													<Box className={classes.label}>Resource</Box>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															selectedResourceOptionValue &&
															selectedResourceOptionValue?.length > 0
																? selectedResourceOptionValue
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={(event, value, reason) => {
															setPage(0);
															if (!value) {
																return;
															}
															const selectedIds = value
																?.filter(
																	(resource: any) => resource.id !== 'All'
																)
																.map((resource: any) => resource?.id);
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'select-option'
															) {
																setSelectedResourceOptionValues([
																	{ id: 'All', name: 'All' },
																	...reporte,
																]);
																const allResourceIds = reporte?.map(
																	(resource: any) => resource?.id
																);
																setSelectedResourceIdList(allResourceIds);
																return;
															}
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'remove-option' &&
																reporte?.length !== selectedIds?.length
															) {
																const currentOptions = value?.filter(
																	(resource: any) => resource?.id !== 'All'
																);
																setSelectedResourceOptionValues(currentOptions);
																const currentIds = currentOptions?.map(
																	(resource: any) => resource?.id
																);
																setSelectedResourceIdList(currentIds);
																return;
															}
															if (
																selectedIds?.length === reporte?.length &&
																reason === 'select-option'
															) {
																setSelectedResourceOptionValues([
																	{ id: 'All', name: 'All' },
																	...reporte,
																]);
																const reporteeIds = reporte?.map(
																	(resource: any) => resource?.id
																);
																setSelectedResourceIdList(reporteeIds);
																return;
															}
															if (
																selectedResourceOptionValue.find(
																	(option: any) => option?.id === 'All'
																) &&
																reason === 'remove-option'
															) {
																setSelectedResourceIdList([]);
																setSelectedResourceOptionValues([]);
																return;
															}
															const selectedId = value?.map(
																(resource) => resource?.id
															);
															setSelectedResourceIdList(selectedId);
															setSelectedResourceOptionValues(value);
														}}
														options={
															reporte && reporte?.length > 0
																? [{ id: 'All', name: 'All' }, ...reporte]
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getResourceFilterLimits}
														ChipProps={{ style: { display: 'none' } }}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<TextField {...params} label='' />
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => (
															<>
																<Checkbox
																	icon={
																		<CheckBoxOutlineBlankIcon fontSize='small' />
																	}
																	checkedIcon={
																		<CheckBoxIcon fontSize='small' />
																	}
																	style={{ marginRight: 8 }}
																	checked={selected}
																	color='primary'
																/>
																<Tooltip
																	title={`${option?.name || '- -'}`}
																	placement='right'
																>
																	<Typography
																		className={`${dropDownEllipsis.ellipsis}`}
																	>
																		{`${option?.name || '- -'}`}
																	</Typography>
																</Tooltip>
															</>
														)}
														getOptionLabel={(option) => `${option?.name}`}
														getOptionSelected={(option, value) =>
															option.id === value.id
														}
													/>
												</Box>

												<Box marginLeft={'10px'} marginTop='23px'>
													<ExportButtonWithLoader
														generateCSV={generateCSV}
														style={classes.exportButton}
														isDisabled={
															!optionalHolidayList
																?.user_optional_holiday_mapping?.length
														}
													/>
												</Box>
											</Box>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className={customStyles.largeContainer}>
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardContent>
							{isTableLoading || isOptionalHolidayLoading ? (
								<Box className={customStyles.noData}>
									<Box className={customStyles.loading}>Loading</Box>
									<CircularProgress />
								</Box>
							) : (
								<Box className={customStyles.tableContainer}>
									{isEmpty(holidayMappingTableData) ? (
										<Box className={customStyles.noData}>
											<Typography className={commonStyle.noDataLabel}>
												No data found
											</Typography>
										</Box>
									) : (
										<TableContainer>
											<Table stickyHeader>
												<TableHead>
													<TableRow>
														<CustomTableCell className={commonStyle.headerCell}>
															RESOURCE ID
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															RESOURCE NAME
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															RESOURCE EMAIL
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															HOLIDAY
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															DATE
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															LOCATION
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															REPORTING TO
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															REPORTING TO EMAIL
														</CustomTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{holidayMappingTableData &&
														holidayMappingTableData
															?.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															?.map((mapping: any) => (
																<TableRow hover key={mapping?.id}>
																	<TableCell>
																		<Tooltip title={mapping?.emp_id || '--'}>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.emp_id || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																	<TableCell>
																		<Tooltip title={mapping?.full_name || '--'}>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.full_name || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																	<TableCell>
																		<Tooltip
																			title={mapping?.user_email || '--'}
																		>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.user_email || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																	<TableCell>
																		<Tooltip title={mapping?.holiday || '--'}>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.holiday || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																	<TableCell>
																		<Tooltip title={mapping?.date || '--'}>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.date || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>

																	<TableCell>
																		<Tooltip title={mapping?.location || '--'}>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.location || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																	<TableCell>
																		<Tooltip
																			title={mapping?.reporting_to || '--'}
																		>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.reporting_to || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																	<TableCell>
																		<Tooltip
																			title={
																				mapping?.reporting_to_email || '--'
																			}
																		>
																			<Typography
																				className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																			>
																				{mapping?.reporting_to_email || '--'}
																			</Typography>
																		</Tooltip>
																	</TableCell>
																</TableRow>
															))}
												</TableBody>
											</Table>
											<Box display='flex' justifyContent='flex-end'>
												<TablePagination
													rowsPerPageOptions={[5, 10, 25]}
													component='div'
													count={holidayMappingTableData?.length || 0}
													rowsPerPage={rowsPerPage}
													page={page}
													onPageChange={handlePageChange}
													onRowsPerPageChange={handleRowsPerPageChange}
												/>
											</Box>
										</TableContainer>
									)}
								</Box>
							)}
						</CardContent>
					</Card>
				</ThemeProvider>
			</Box>
		</div>
	);
};

export default OptionalHolidayReport;
