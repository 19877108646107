import React, { useContext } from 'react';
import EmployeeAttendanceReport from './employeeAttendanceReport.component';
import { EmployeeLeaveReport } from './EmployeeLeaveReport.component';
import { Card } from '@material-ui/core';
import { AttendanceDetailedReport } from './AttendanceDetailedReport.component';
import { UserProfileContext } from '../../../App';
import { AbsenteeReport } from './AttendanceAbsenteeReport.component';
import { EmployeeAttendanceRequest } from './EmployeeAttendanceRequest';
import OptionalHolidayReport from './OptionalHolidayReport.component';

export const AttendanceInsights = () => {
	const { featureFlag, permissions, isAttendanceRegularizationEnabled } = useContext<any>(UserProfileContext);
	return (
		<>
			<Card style={{ minHeight: 200 }}>
				<EmployeeAttendanceReport />
			</Card>
			<Card style={{ marginTop: '10px' }}>
				<EmployeeLeaveReport />
			</Card>
			{featureFlag?.attendanceDetailedReport && (
				<Card style={{ marginTop: '10px' }}>
					<AttendanceDetailedReport />
				</Card>
			)}
			{featureFlag?.attendanceAbsenteeReport && (
				<Card style={{ marginTop: '10px' }}>
					<AbsenteeReport />
				</Card>
			)}
			{featureFlag?.optionalHolidayReport &&
				permissions?.appPermissions?.ui?.viewOptionalHolidayReport && (
					<Card style={{ marginTop: '10px' }}>
						<OptionalHolidayReport />
					</Card>
				)}
			{isAttendanceRegularizationEnabled && 
				permissions?.appPermissions?.ui?.viewAttendanceRequestReport &&(
				<Card style={{ marginTop: '10px' }}>
					<EmployeeAttendanceRequest />
				</Card>
			)}
		</>
	);
};
