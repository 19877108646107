export const APPRAISAL_CONTAINER = 'Appraisal';
export const VISION_CONTAINER = 'Vision';
export const GOAL_CONTAINER = 'Goal';

export const VISION_DELETE_SUCCESS_MSG = 'Vision deleted successfully';
export const VISION_DELETE_FAILURE_MSG = 'Failed to delete vision!';

export const DELETE_ALERT_MSG = 'Do you really want to delete this Goal?';

export const UNABLE_TO_DELETE_MSG =
	'Unable to delete as there is associated data';

export const GOAL_DELETE_ALERT_MSG = 'Do you really want to delete this Goal?';
export const GOAL_DELETE_SUCCESS_MSG = 'Goal deleted successfully';
export const GOAL_UNABLE_TO_DELETE_MSG =
	'Unable to delete as there is associated data';

export const GOAL_VALUE_CHANGE_CONFIRMATION_TEXT =
	'Are you sure you want to proceed?';
export const UNABLE_TO_CHANGE_GOAL_IS_ACTIVE_VALUE_MSG =
	'Unable to change as there is associated data';

export const UPDATE_GOAL_BUTTON_LABEL = 'Update';
export const CREATE_GOAL_BUTTON_LABEL = 'Create';

export const UPDATE_BUTTON_LABEL = 'Update';
export const CREATE_BUTTON_LABEL = 'Create';

export const regexNameValidation = /[a-zA-Z]{3}/;
export const REGEX_FAILED_ERROR_TEXT =
	'A minimum of three alphabet characters is required';

export const WHITE_CIRCULAR_PROGRESS_LOADER = '#FFFFFF';
export const BLUE_CIRCULAR_PROGRESS_LOADER = '#4285F4';

export const GOAL_EXPORT_COLUMNS = [
	'Name',
	'Is Active',
	'Category',
	'Description',
];
export const VISION_EXPORT_COLUMNS = ['NAME', 'DESCRIPTION'];
export const APPRAISAL_EXPORT_COLUMNS = [
	'NAME',
	'APPRAISAL START DATE',
	'APPRAISAL END DATE',
	'STATUS',
	'RESOURCE COUNT',
	'REVIEW START DATE',
	'REVIEW END DATE',
	'SELF REVIEW START DATE',
	'SELF REVIEW END DATE',
	'ENABLE SALARY HIKE',
	'ENABLE CHECK IN',
	'CHECK IN INTERVAL',
	'DESCRIPTION',
];

export const APPRAISAL_DETAILS_EXPORT_COLUMNS = [
	'NAME',
	'EMAIL',
	'REVIEWER',
	'REVIEW STATUS',
	'ALLOCATED WEIGHT(%)',
];

export const TEAM_PMS_DETAILS_EXPORT_COLUMNS = [
	'NAME',
	'EMAIL',
	'REVIEWER',
	'REVIEW STATUS',
	'ALLOCATED WEIGHT(%)',
];

export const APPRAISAL_CYCLE_INSIGHT_EXPORT_COLUMNS = [
	'ID',
	'NAME',
	'EMAIL',
	'DESIGNATION',
	'JOB LEVEL',
	'DEPARTMENT',
	'LOCATION',
	'REVIEWER',
	'REVIEW STATUS',
	'REPORTING MANAGER',
	'ALLOCATED WEIGHT(%)',
	'SELF REVIEW SCORE',
	'SELF RATING',
	'MANAGER REVIEW SCORE',
	'MANAGER RATING',
	'SELF OVERALL FEEDBACK',
	'MANAGER OVERALL FEEDBACK',
];

export const SEND_REMINDER_CHECKBOX_WARNING = 'Required';
export const SEND_REMINDER_SUCCESS = 'Reminder sent successfully';
export const SEND_REMINDER_FAILED = 'Failed to send the reminder';
export const SEND_REMINDER_STATUS_OPTIONS = [
	{ id: 'employees', name: 'Employees' },
	{
		id: 'reporting_managers',
		name: 'Reporting Managers',
	},
];

export const PMS_TARGET_ACHIEVED_VALIDATION_MESSAGE =
	'Please enter a number of 0 or higher.';
export const PMS_TARGET_ACHIEVED_VALIDATION_PERCENTAGE_MESSAGE =
	'Value must not exceed 100.';
