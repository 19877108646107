import { makeStyles } from '@material-ui/core';

export const formStyle = makeStyles({
	container: {
		width: '442px',
		minHeight: '300px',
		background: '#FFFFFF 0% 0 % no - repeat padding- box',
		borderRadius: '4px',
		padding: '20px',
	},
	headerContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	heading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
		textTransform: 'uppercase',
	},
	closeIcon: {
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	label: {
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingBottom: '30px',
	},
	floatingButtonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '32px',
	},
	saveButton: {
		minWidth: '126px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	deleteButton: {
		width: '126px',
		height: '36px',
		background: '#EA4335 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#EA4335',
		},
	},
	cancelButton: {
		width: '126px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#4285F4',
		marginRight: '10px',
		textTransform: 'none',
	},
	updateButton: {
		width: '126px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		marginRight: '10px',
		'&:hover': {
			background: '#4285F4',
		},
	},
	formContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '20px',
	},
	multipleInputContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	multipleInput: {
		width: '49%',
	},
	suggestionContainer: {
		zIndex: 4000,
	},
	disabledButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		minWidth: '126px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
	},
	customButtonContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	infoContentContainer: {
		marginTop: '20px',
	},
	infoContent: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#5C5C5C',
	},
	eligibleContainer: {
		width: '100%',
		height: '48px',
		background: '#ECF1F4 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '30px',
		padding: '0px 10px 0px 10px',
	},
	radioButtonLabel: {
		font: 'normal normal bold 12px/37px Manrope',
		color: '#292929',
	},
	warningContent: {
		fontFamily: 'Manrope-bold',
		fontSize: '12px',
		color: 'red',
	},
	disabledStartButton: {
		color: 'grey',
		backgroundColor: 'lightgrey',
		width: '126px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		marginRight: '10px',
	},
	dialogueContent: {
		padding: '0px 24px',
	},
	adonmentStyle: {
		marginBottom: '15px',
		width: '23px',
		height: '100%',
	},
	errorText: {
		fontFamily: 'Manrope-regular',
		fontSize: '11px !important',
		color: '#EA4335',
		marginTop: '2px',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},
	phoneNumberError: {
		fontFamily: 'Manrope-medium',
		fontSize: '10px !important',
		color: '#EA4335',
		marginTop: '-10px',
		marginBottom: '8px',
		marginLeft: '6px',
		height: '5px',
	},

	rejectButton: {
		width: '100px',
		height: '36px',
		background: '#ED3D3D 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#ED3D3D',
		},
	},
	approveButton: {
		width: '100px',
		height: '36px',
		background: '#05950a 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#05950a',
		},
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		// font: 'normal normal 12px/37px Manrope',
		font: 'normal normal bold 10px/24px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '10px !important',
	},
	description: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '200px',
		'&:hover': {
			cursor: 'pointer',
		},
	},

	updateStatus: {
		fontSize: '16px',
		fontFamily: 'Manrope-semibold',
		marginBottom: '10px',
		color: 'red',
	},

	errorContainer: {
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		padding: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope',
		color: 'red',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '200px',
		overflowY: 'scroll',
	},

	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
});

export const pmsDatagridStyle = makeStyles({
	heading: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '10px',
		color: '#292929',
		fontSize: '14px !important',
		letterSpacing: '0px',
		textTransform: 'uppercase',
	},
	listEditIconContainer: {
		width: '24px',
		height: '24px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	deleteIconButton: {
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	listDeleteIconContainer: {
		width: '24px',
		height: '24px',
	},
	listDeleteIconButton: {
		width: '24px',
		height: '24px',
		display: 'none',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#EA4335',
		'&:hover': {
			color: '#FFFFFF',
			background: '#EA4335',
		},
	},
	deleteIcon: {
		width: '12px',
		height: '12px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	statusChip: {
		width: '150px',
		padding: '10px',
		background: '#ebf3ff',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
	},
	statusLabel: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	toolbarContainer: {
		display: 'flex',
		margin: '10px 0px',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		padding: '6px 10px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
	},
	excludeButton: {
		minWidth: '60px',
		height: '26px',
		background: '#EA4335 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#EA4335',
		},
	},
	horizontalScrollWrapper: {
		width: '100vw',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		overflowX: 'auto',
		overflowY: 'hidden',
		whiteSpace: 'nowrap',
		scrollBehavior: 'smooth',
		scrollbarWidth: 'thin',
		scrollbarColor: '#888 #f1f1f1',
	},
	disabledExcludeButton: {
		minWidth: '60px',
		height: '26px',
		background: '#E0E0E0',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
	},
	buttonSm: {
		minWidth: '60px',
		height: '26px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
		marginRight: '10px',
	},
	buttonMd: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
		marginRight: '10px',
	},
	disabledButton: {
		opacity: 0.5,
		color: '#FFFFFF',
	},
	outlinedSmButton: {
		minWidth: '60px',
		height: '26px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#474444',
		border: '1px solid #5c5c5c',
		marginRight: '10px',
		textTransform: 'none',
		'&:hover': {
			background: '#FFFFFF',
		},
	},
	link: {
		fontFamily: 'Manrope-bold',
		color: '#4285F4',
		cursor: 'pointer',
	},
	disabledExportButton: {
		minWidth: '60px',
		height: '36px',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		textTransform: 'none',
		color: 'grey',
		backgroundColor: 'lightgrey',
	},
	text: {
		fontFamily: 'Manrope-medium',
		fontSize: '11px',
		cursor: 'pointer',
		fontWeight: 400,
	},
});
export const appraisalInsightsStyle = makeStyles({
	filterDropDown: {
		width: '180px',
		marginRight: '20px',
	},
	iconButton: {
		width: '36px',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		marginRight: '10px',
		color: '#888888',
	},
	activeIconButton: {
		color: '#4285F4',
		border: '1px solid #4285F4',
	},
	chartContainer: {
		width: '80%',
		height: '32%',
		display: 'flex',
	},
});

export const appraisalDetailsStyle = makeStyles({
	root: {
		width: '100%',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #0000000F',
		borderRadius: '4px',
		opacity: 1,
		border: '2px solid #E0E0E0',
		flexGrow: 1,
	},
	headerContainer: {
		height: '60px',
		width: '100%',
		borderBottom: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
	},
	headerLabel: {
		color: '#292929',
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
	},
	headerValue: {
		fontFamily: 'Manrope-medium',
		color: '#5C5C5C',
		fontSize: '10px',
		marginTop: '4px',
	},
	header: {
		color: '#292929',
		fontFamily: 'Manrope-extrabold',
		fontSize: '14px',
		marginLeft: '20px',
		width: '250px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	flex: {
		display: 'flex',
		alignItems: 'center',
	},
	listContainer: {
		padding: '0px 10px 0px 10px',
	},
	listHeading: {
		font: 'normal normal 800 14px/23px Manrope',
		letterSpacing: '0px',
		color: '#292929',
		fontSize: '14px !important',
		textTransform: 'uppercase',
	},
});

export const pmsViewDetailsStyle = makeStyles({
	container: {
		height: 'fit-content',
		padding: '10px',
		background: '#E2F4FF',
		borderRadius: '4px',
		marginBottom: '10px',
	},
	profileOuterContainer: {
		marginBottom: '20px',
	},
	profileContainer: {
		height: '160px',
		width: '300px',
		marginRight: '20px',
		borderRadius: '8px',
		border: '1px solid #E0E0E0',
		background: '#FFFFFF',
		boxShadow: '0px 4px 12px #4285f43b',
		padding: '4px 10px 0px 10px',
	},
	profilePic: {
		height: '80px',
		width: '80px',
		borderRadius: '8px',
		marginBottom: '10px',
	},
	profileName: {
		fontFamily: 'Manrope-bold',
		fontSize: '14px',
		width: '140px',
	},
	profileDetailsLeftContainer: {
		display: 'flex',
		height: '124px',
		flexDirection: 'column',
		justifyContent: 'space-around',
		borderRight: '1px solid #E0E0E0',
		width: '50%',
		marginRight: '12px',
	},
	profileDetailsRightContainer: {
		display: 'flex',
		height: '120px',
		flexDirection: 'column',
		justifyContent: 'space-around',
		width: '50%',
	},
	employeeDetailsContainer: {
		height: '150px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	profileDetailsLabel: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#5C5C5C',
		height: '15px',
	},
	profileDetailsValue: {
		fontSize: '12px',
		fontFamily: 'Manrope-semibold',
		color: '#292929',
	},
	previousRatingContainer: {
		height: '32px',
		width: '100%',
		borderTop: '1px solid #E0E0E0',
	},
	flex: {
		display: 'flex',
		alignItems: 'center',
	},
	justifyContentSpaceBetween: {
		justifyContent: 'space-between',
	},

	goal: {
		fontFamily: 'Manrope-extrabold',
		fontSize: '12px',
		width: '500px',
	},
	goalDescription: {
		fontFamily: 'Manrope-medium',
		fontSize: '12px',
		color: '#888888',
		width: '500px',
	},
	goalWeightage: {
		width: '50px',
		border: '1px solid #888888',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '8px',
		height: '30px',
		fontFamily: 'Manrope-extrabold',
	},
	ratingContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		height: '100%',
	},
	ratingLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		textTransform: 'uppercase',
	},
	ratingValue: {
		fontSize: '16px',
		fontFamily: 'Manrope-bold',
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	verticalIcon: {
		width: '16px',
		height: '20px',
		marginLeft: '10px',
		marginTop: '7px',
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	infoIcon: {
		width: '15px',
		height: '15px',
		marginLeft: '8px',
		cursor: 'pointer',
	},
	navigationTabContainer: {
		fontFamily: 'Manrope-semibold',
		color: '#888888',
		fontSize: '12px',
		width: '300px',
		textTransform: 'none',
		borderRadius: '0px',
		letterSpacing: '0px',
		borderRight: '1px solid #E0E0E0',
		marginBottom: '16px',
		background: '#ECF1F4',
		'&:first-child': {
			borderRight: '1px solid #E0E0E0',
			borderRadius: '4px 0px 0px 4px !important',
		},
		'&:last-child': {
			borderRadius: '0px 4px 4px 0px',
		},
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '1px solid #E0E0E0',
		padding: '8px 0px 8px 0px',
	},
	commentIcon: {
		color: '#4285F4',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	warningIcon: {
		color: '#ec0202',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	ratingContainerRow: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '10px',
		alignItems: 'center',
	},
	ratingContainerRowForReviewer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '-4px',
		marginBottom: '10px',
		alignItems: 'center',
	},
	appraisalStatusChip: {
		background: '#4285F4',
		padding: '5px',
		borderRadius: '4px',
		color: 'white',
		fontWeight: 'bold',
	},
	ratingContainerValues: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
	},
	warningComment: {
		animation: '$shake 1s',
		color: '#f01717',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	'@keyframes shake': {
		'0%': { transform: 'translateX(0)' },
		'25%': { transform: 'translateX(-2px)' },
		'50%': { transform: 'translateX(2px)' },
		'75%': { transform: 'translateX(-2px)' },
		'100%': { transform: 'translateX(0)' },
	},
	customScrollContainer: {
		height: '161px',
		overflow: 'auto',
	},
	documentUploadInfoIcon: {
		width: '15px',
		height: '15px',
		marginLeft: '4px',
		cursor: 'pointer',
		marginTop: '2px',
		color: 'grey',
	},
});

export const documentUploadStyles = makeStyles((theme) => ({
	fileUploadContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'pointer',
		color: '#4285F4',
	},
	disabedFileUploadContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '36px',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		cursor: 'pointer',
		color: '#b9b5b5',
	},
	heading: {
		fontSize: '13px !important',
		fontWeight: 600,
		fontFamily: 'Manrope-bold',
		marginBottom: '10px',
	},
	document: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		maxWidth: '100%',
	},
	fileName: {
		fontSize: '11px !important',
		fontWeight: 600,
		fontFamily: 'Manrope-medium',
		flexGrow: 1,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		paddingRight: '10px',
	},
	icon: {
		width: '18px',
		height: '18px',
		color: '#626262',
	},
	downloadIcon: {
		width: '18px',
		height: '18px',
		color: '#4285F4',
		marginTop: '1px',
	},
	deleteIcon: {
		width: '18px',
		height: '18px',
		color: '#ed0000',
	},
	actionContainer: {
		width: '55px',
		justifyContent: 'end',
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		color: '#626262',
	},
	descriptionIcon: {
		fontSize: 24,
		marginRight: '10px',
	},
	downloadIconContainer: {
		width: '24px',
		height: '24px',
		padding: '0px',
		color: '#626262',
		marginRight: '5px',
	},
}));
