import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	ThemeProvider,
	makeStyles,
	Theme,
	withStyles,
	createStyles,
	TableCell,
	Tooltip,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Card,
	CardContent,
	TextField,
	Typography,
	Checkbox,
	CircularProgress,
	InputAdornment,
	IconButton,
	createTheme,
} from '@material-ui/core';
import { SelectInput, setSidebarVisibility } from 'react-admin';
import {
	AutocompleteSearchStyles,
	dropDownEllipsisStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import {
	autoCompleteTheme,
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import { Form, Field } from 'react-final-form';
import dayjs from 'dayjs';
import {
	useGetActiveUsersQuery,
	useGetEmployeeAttendanceAndLeaveDataQuery,
	useGetLocationsByOrgIdQuery,
	useGetMyTeamViewInAttendanceQuery,
	useGetOrgLeaveTypesQuery,
} from '../../../generated/graphql';
import { head, isEmpty, orderBy, sortBy, uniq, uniqBy } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import {
	formatEmployeeId,
	exportToCsv,
	getPrefix,
} from '../../../Utils/string.util';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { leaveReportHeader, START_DATE_ERROR } from '../Constant';
import { UserProfileContext } from '../../../App';
import {
	getSaturdaysDates,
	getSundaysBetweenDates,
} from '../../../Utils/date-time.util';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';
import { DATE_RANGE_ERROR_FOR_ATTENDANCE_REPORT } from '../../Projects/constant';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import { dateTimePickerTheme } from '../Style';
import { SearchIcon } from '../../../assets/SvgIcons';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
	heading: {
		fontSize: '14px',
		fontFamily: 'Manrope-extrabold',
		fontWeight: 500,
		lineHeight: 1.6,
		letterSpacing: '0.0075em',
		marginTop: '10px',
		marginLeft: '30px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		marginTop: '23px',
		marginRight: '4px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		minWidth: '60px',
		height: '36px',
		marginTop: '23px',
		marginRight: '4px',
		background: 'lightgrey 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	tableContainer: {
		maxHeight: '415px',
		overflowY: 'auto',
		minHeight: '200px',
	},
	exportButtonMargin: {
		marginTop: '25px',
		marginLeft: '14px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	filterCotainer: {
		display: 'flex',
		marginLeft: '-30px',
	},
	noDataLabel: {
		fontSize: '12px',
		fontFamily: 'Manrope-semiBold',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	dateCell: {
		display: 'block',
		fontSize: '10px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		fontFamily: 'Manrope-semibold',
	},
	weekEndCell: {
		color: '#e63a2e',
	},
	headerCell: {
		minWidth: '120px',
		padding: '12px',
	},
	circle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
		visibility: 'hidden',
	},
	holidayCircle: {
		height: '12px',
		width: '12px',
		backgroundColor: '#bbb',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '9px',
		fontFamily: 'Manrope-bold',
	},
	monthCell: {
		width: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '17px',
		marginBottom: '5px',
	},
	absent: {
		color: '#EA4335',
		backgroundColor: '#FFF3F2',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	optonalHoliday: {
		color: '#c28d00',
		backgroundColor: '#fae4ac',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	weekend: {
		color: '#888888',
		backgroundColor: '#ECF1F4',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	present: {
		color: '#34a853',
		backgroundColor: '#E9F7EC',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	leave: {
		cursor: 'pointer',
		color: '#f98203',
		backgroundColor: '#FFEAC2',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
	},
	notMarked: {
		color: '#888888',
		backgroundColor: '#f4f6e9',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	noLeaveData: {
		color: '#000000',
		borderRadius: '10px',
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		padding: '4px 8px 4px 8px',
		width: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	holiday: {
		marginLeft: '15px',
	},
	dropdownLabel: {
		marginTop: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},

	multipleInputContainer: {
		width: '200px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	multipleInput: {
		width: '150',
		marginLeft: '15px',
	},
	countContainerWithoutGrey: {
		minWidth: '161px',
		height: '50px',
		borderRadius: '4px',
		padding: '6px 12px 6px 12px',
		marginRight: '16px',
		marginLeft: '5px',
	},
	reducedCountContainer: {
		height: '65px',
		background: '#FFFFFF',
		borderRadius: '4px',
		marginTop: '16px',
		display: 'flex',
	},
	largeContainer: {
		marginTop: '16px',
		display: 'grid',
		gap: '15px',
		minHeight: '200px',
	},
	loading: {
		fontSize: '17px',
		fontFamily: 'Manrope-medium',
		marginRight: '5px',
	},
	endDate: {
		width: '188px',
		marginTop: '14px',
	},
	startDate: {
		width: '188px',
		marginLeft: '20px',
		marginTop: '14px',
	},
}));

const CustomTableCell = withStyles(() =>
	createStyles({
		head: {
			backgroundColor: '#F7F9FA',
			color: '#5C5C5C',
			fontFamily: 'Manrope-extrabold',
			fontSize: '10px',
			padding: '12px 12px 0px 12px',
		},
		body: {
			backgroundColor: 'transparent',
			color: '#4E4E4E',
			fontFamily: 'Manrope-semibold',
			fontSize: '10px',
			padding: '12px',
		},
	})
)(TableCell);

export const tableTheme = createTheme({
	overrides: {
		MuiTable: {
			root: {
				width: 'auto',
			},
		},
	},
});
interface option {
	id: String;
	name?: String | null | undefined;
}

export const EmployeeAttendanceReport = () => {
	const styles = modalFormStyle();
	const {
		empIdLength,
		isFixedEmpIdLengthEnabled,
		noOfLocations,
		orgId: organizationId,
		dateFormat,
		attendanceConsiderableTimesheetStatus,
		permissions,
		accountEmployeeUserIds,
		id: userId,
		accountProjectsOptions,
	} = useContext<any>(UserProfileContext);

	const dispatch = useDispatch();
	const customStyles = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [projectIdFilter, setProjectIdFilter] = useState<{
		id: any;
		name: string;
	}>();
	const autoCompleteSearchStyles = AutocompleteSearchStyles();

	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<option[]>([]);

	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		String[]
	>([]);
	const [locationDropDown, setLocationDropDown] = useState<option[]>([]);

	const [selectedLeaveTypeOptionValue, setSelectedLeaveTypeOptionValue] =
		useState<option[]>([]);

	const [selectedLeaveTypeIdList, setSelectedLeaveTypeIdList] = useState<
		String[]
	>([]);
	const [leaveTypeDropDown, setLeaveTypeDropDown] = useState<option[]>([]);
	const [attendanceTableList, setAttendanceTableList] = useState<any[]>([]);
	const [monthList, setMonthList] = useState<any[]>([]);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<option[]>([]);

	const [selectedUserType, setSelectedUserType] = useState<string>('All');
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<any>([]);
	const [reporte, setReportee] = useState<any>();
	const ellipsis = ellipsisStyle();
	const dropDownEllipsis = dropDownEllipsisStyle();
	const [startDate, setStartDate] = useState<any>(
		dayjs().startOf('month').format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState<any>(
		dayjs().endOf('month').format('YYYY-MM-DD')
	);
	const [dateRangeError, setDateRangeError] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [orgSaturdayList, setOrgSaturdayList] = useState<string[] | null>([]);
	const [isOrgWeekendIncludesSunday, setIsOrgWeekendIncludesSunday] =
		useState<boolean>(false);
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const { data: orgLocations } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
	});

	const { data: myTeam } = useGetMyTeamViewInAttendanceQuery({
		variables: {
			userId: userId,
		},
		fetchPolicy: 'network-only',
		skip: !permissions?.appPermissions?.ui?.viewSubAccountDetails,
	});

	const getLeaveTypeFilter = () => {
		if (isEmpty(selectedLeaveTypeOptionValue)) {
			return {};
		}
		if (
			selectedLeaveTypeOptionValue &&
			selectedLeaveTypeOptionValue?.find((option) => option?.id === 'All')
		) {
			return {};
		}
		return {
			leave_type_id: { _in: selectedLeaveTypeIdList },
		};
	};

	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return [];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return [{}];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						contractors: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},

					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
		}
		return [{}];
	};

	const getProjectFilter = () => {
		if (!permissions?.appPermissions?.ui?.viewSubAccountDetails) {
			return {
				is_active: { _eq: true },
				id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? {
								_in: selectedResourceIdList,
						  }
						: { _in: [] },
				user_type: selectedUserType !== 'All' ? { _eq: selectedUserType } : {},
				_or: getLocationFilter(),
			};
		}

		if (projectIdFilter) {
			return {
				is_active: { _eq: true },
				project_resource_mappings: {
					project: {
						id: {
							_eq: projectIdFilter,
						},
					},
				},
				id:
					selectedResourceIdList && selectedResourceIdList?.length > 0
						? {
								_in: selectedResourceIdList,
						  }
						: { _in: [] },
				user_type: selectedUserType !== 'All' ? { _eq: selectedUserType } : {},
				_or: getLocationFilter(),
			};
		}

		return {
			is_active: { _eq: true },
			id:
				selectedResourceIdList && selectedResourceIdList?.length > 0
					? {
							_in: selectedResourceIdList,
					  }
					: { _in: [] },
			user_type: selectedUserType !== 'All' ? { _eq: selectedUserType } : {},
			_or: getLocationFilter(),
		};
	};

	const { data: userList, loading: isUserListLoading } =
		useGetEmployeeAttendanceAndLeaveDataQuery({
			variables: {
				startDate: startDate,
				endDate: endDate,
				orgId: organizationId,
				statusList: attendanceConsiderableTimesheetStatus,
				filter: {
					...getProjectFilter(),
				},
				leaveFilter: {
					_and: [
						{ employee_leave_status: { value: { _eq: 'approved' } } },
						{
							_or: [
								{ from_date: { _gte: startDate, _lte: endDate } },
								{ to_date: { _gte: startDate, _lte: endDate } },
								{
									_and: [
										{ from_date: { _lte: startDate } },
										{ to_date: { _gte: endDate } },
									],
								},
							],
						},
						getLeaveTypeFilter(),
					],
				},
			},
			skip: !startDate || !endDate,
		});

	const { data: resourceList } = useGetActiveUsersQuery({
		skip: !startDate || !endDate,
		variables: {
			filter: {
				is_active: { _eq: true },
				user_type: selectedUserType !== 'All' ? { _eq: selectedUserType } : {},
			},
		},
	});

	const { data: orgLeaveTypeList } = useGetOrgLeaveTypesQuery({});
	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	const checkHoliday = (date: string) => {
		if (!date && !userList) {
			return;
		}
		const holiday = userList?.org_holidays.filter(
			(holiday) => holiday?.date === date
		);
		const month = dayjs(date).format('dddd').slice(0, 1);
		const holidayTitle =
			holiday &&
			holiday?.length > 1 &&
			holiday?.map(
				(item) =>
					`${
						item?.org_location?.name
							? `${item?.org_location?.name}: ${item?.name}`
							: `${item?.name}`
					}`
			);
		return (
			<Box display='flex'>
				<Tooltip title={dayjs(date).format('dddd') || ''} placement='right'>
					<div className={customStyles.circle}>{month}</div>
				</Tooltip>
				{holiday && head(holiday)?.date && (
					<Tooltip
						title={
							holidayTitle
								? holidayTitle.join(',')
								: head(holiday)?.date
								? head(holiday)?.org_location?.name
									? `${head(holiday)?.org_location?.name}: ${
											head(holiday)?.name
									  }`
									: `Holiday: ${head(holiday)?.name}`
								: ''
						}
						placement='right'
					>
						<div className={customStyles.holidayCircle}>H</div>
					</Tooltip>
				)}
			</Box>
		);
	};
	useEffect(() => {
		if (!startDate && !endDate) {
			return;
		}
		setPage(0);
		const dateList = [];
		let currentDate = dayjs(startDate);
		const stopDate = dayjs(endDate);
		while (currentDate <= stopDate) {
			dateList.push({
				date: dayjs(currentDate).format('YYYY-MM-DD'),
				day: dayjs(currentDate).date(),
				month: dayjs(currentDate).format('MMM'),
			});
			currentDate = dayjs(currentDate).add(1, 'day');
		}
		setMonthList(dateList);
	}, [startDate, endDate]);

	useEffect(() => {
		if (!resourceList) {
			return;
		}

		if (!permissions?.appPermissions?.ui?.viewSubAccountDetails) {
			const filterUserList = resourceList?.user;
			const idList = filterUserList?.map((resource) => resource?.id);
			setReportee(filterUserList);
			setSelectedResourceIdList(idList);
			setSelectedResourceOptionValues([
				{ id: 'All', name: 'All' },
				...filterUserList,
			]);
			return;
		}
		const isAllUserTypeSelected = selectedUserType === 'All' ? true : false;

		// Get the reportees
		const reportees = isAllUserTypeSelected
			? myTeam?.user.map((usr) => ({
					id: usr.id,
					name: usr.full_name,
			  })) || []
			: myTeam?.user
					?.filter((usr) => usr.user_type === selectedUserType)
					.map((usr) => ({
						id: usr.id,
						name: usr.full_name,
					})) || [];
		const reporteesIds = reportees?.map((usr) => usr.id) || [];

		// Get sub account employees
		const filterUserList = resourceList?.user
			.filter((usr) => accountEmployeeUserIds.includes(usr.id))
			.filter((user) => user.id !== userId);
		const filteredUserListWithUserType = isAllUserTypeSelected
			? filterUserList
			: filterUserList?.filter((user) => user.user_type === selectedUserType);
		const idList = filterUserList?.map((resource) => resource?.id);

		const finalUserList = sortBy(
			uniqBy([...reportees, ...filteredUserListWithUserType], 'id'),
			(val) => val.name
		);
		setReportee(finalUserList);
		setSelectedResourceIdList([...idList, ...reporteesIds]);
		setSelectedResourceOptionValues([
			{ id: 'All', name: 'All' },
			...finalUserList,
		]);
	}, [
		resourceList,
		permissions,
		accountEmployeeUserIds,
		myTeam,
		userId,
		selectedUserType,
	]);

	useEffect(() => {
		if (!userList) {
			return;
		}
		const holidayList = userList?.org_holidays || [];

		const isSundayAvailable =
			head(userList?.organization)?.weekend?.includes('SUN') || false;
		setIsOrgWeekendIncludesSunday(isSundayAvailable);
		const sundayAsWeekend = getSundaysBetweenDates(startDate, endDate);
		const availableSaturdays = head(userList?.organization)
			?.weekend?.filter((weekendDay: string) => weekendDay !== 'SUN')
			?.map((sat: string) => sat?.charAt(sat.length - 1));
		const allSaturdaysBetweenDates = getSaturdaysDates(startDate, endDate);
		const saturdayAsWeekend =
			allSaturdaysBetweenDates?.map((day) =>
				availableSaturdays?.includes(`${day?.ordinal}`) ? day?.date : ''
			) || [];
		setOrgSaturdayList(saturdayAsWeekend);
		const resourceTableData = userList?.user?.map((user) => {
			if (!isEmpty(selectedLeaveTypeOptionValue)) {
				if (
					!user?.employee?.employee_leaving_mappings ||
					isEmpty(user?.employee?.employee_leaving_mappings)
				) {
					return {};
				}
			}
			const existingEmployeeId = user?.employee?.emp_id || '';
			const existingContractorId = head(user?.contractors)?.contractor_id || '';
			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);

			const employeeId =
				user?.user_type === 'employee'
					? user?.employee?.emp_id
						? `${
								getPrefix(
									user?.employee?.employee_prefix?.name,
									user?.employee?.employee_prefix?.value
								) || ''
						  }${formattedEmployeeId || ''}`
						: '--'
					: head(user?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(user?.contractors)?.contractor_prefix?.name,
								head(user?.contractors)?.contractor_prefix?.value
							) || ''
					  }${
							user?.contractors && head(user?.contractors)?.contractor_id
								? formattedContractorId
								: ''
					  }`
					: '--';
			const joinDate =
				user?.user_type === 'employee'
					? user?.employee?.join_date || null
					: head(user?.contractors)?.join_date || null;

			const leaveList = user?.employee?.employee_leaving_mappings
				?.map((leave) => {
					if (dayjs(leave?.to_date).diff(dayjs(leave?.from_date)) > 1) {
						const dateList = [];
						let currentDate = dayjs(leave?.from_date);
						const stopDate = dayjs(leave?.to_date);
						while (currentDate <= stopDate) {
							dateList.push({
								date: dayjs(currentDate).format('YYYY-MM-DD'),
								label: leave?.org_leave_type?.leave_type,
								isExcluded: leave?.org_leave_type?.is_leave_sandwich_type,
								isHalfDay: false,
							});
							currentDate = dayjs(currentDate).add(1, 'day');
						}
						return dateList;
					} else
						return {
							date: leave?.from_date,
							label: leave?.org_leave_type?.leave_type,
							isExcluded: leave?.org_leave_type?.is_leave_sandwich_type,
							isHalfDay: leave?.is_half_day,
						};
				})
				.flat();
			const userLocationId =
				user?.user_type === 'employee'
					? user?.employee?.location_id || null
					: head(user?.contractors)?.location_id;
			const userRegularHolidayList =
				noOfLocations && noOfLocations > 0
					? userLocationId
						? holidayList
								?.filter(
									(holiday) => holiday?.org_location?.id === userLocationId
								)
								.map((holiday) => {
									return {
										date: holiday?.date,
										name: holiday?.name,
										locationName: holiday?.org_location?.name || '',
										locationId: holiday?.org_location?.id || '',
									};
								})
						: []
					: holidayList?.map((holiday) => {
							return {
								date: holiday?.date,
								name: holiday?.name,
								locationName: holiday?.org_location?.name || '',
								locationId: holiday?.org_location?.id || '',
							};
					  }) || [];
			const userOptionalHolidayList =
				noOfLocations && noOfLocations > 0
					? userLocationId
						? user?.user_optional_holiday_mappings?.map((holiday) => {
								return {
									date: holiday?.org_holiday?.date,
									name: holiday?.org_holiday?.name,
									locationName: holiday?.org_holiday?.org_location?.name || '',
									locationId: holiday?.org_holiday?.org_location?.id || '',
								};
						  })
						: []
					: user?.user_optional_holiday_mappings?.map((holiday) => {
							return {
								date: holiday?.org_holiday?.date,
								name: holiday?.org_holiday?.name,
								locationName: holiday?.org_holiday?.org_location?.name || '',
								locationId: holiday?.org_holiday?.org_location?.id || '',
							};
					  }) || [];
			const userRegularAndOptionalHolidays = [
				...userRegularHolidayList,
				...userOptionalHolidayList,
			];
			const userHolidayDateList =
				userRegularAndOptionalHolidays?.map((holiday) => holiday?.date) || [];
			const leaveDatesArray =
				leaveList?.filter((leaveDate) => {
					const isExcludedType = leaveDate?.isExcluded;
					const date = leaveDate?.date;
					if (!isExcludedType) {
						return leaveDate;
					}
					const isHoliday = userHolidayDateList?.includes(date);
					const isSunday = sundayAsWeekend?.includes(date);
					const isSaturday = saturdayAsWeekend.includes(date);

					if (
						(isSundayAvailable && !isHoliday && !isSunday && !isSaturday) ||
						(!isSundayAvailable && !isHoliday && !isSaturday)
					) {
						return leaveDate;
					}
				}) || [];
			const timesheetlist =
				user?.timesheets?.map((timesheet) => timesheet?.date) || [];
			const attendanceList =
				user?.attendance
					?.filter((attendance) => attendance?.in_time && attendance?.out_time)
					?.map((e) => e.date) || [];
			const timesheetAndAttendanceDateArray = uniq([
				...timesheetlist,
				...attendanceList,
			]);
			const dateList = monthList.map((date) => {
				const leaveDate: {
					date: string;
					label: string;
					isExcluded: boolean;
					isHalfDay?: boolean | undefined;
				} | null =
					leaveDatesArray?.find((item) => item?.date === date?.date) || null;
				const leaveDateList =
					leaveDatesArray?.map((leave) => leave?.date) || [];
				const holiday =
					userRegularAndOptionalHolidays?.find(
						(holiday) => holiday?.date === date?.date || null
					) || null;

				if (!isEmpty(selectedLeaveTypeOptionValue)) {
					if (leaveDateList?.includes(date?.date)) {
						return {
							date: date?.date,
							type: 'Leave',
							label: leaveDate?.isHalfDay
								? `${leaveDate?.label}(Half a day)`
								: leaveDate?.label,
							isHalfDay: leaveDate?.isHalfDay || false,
						};
					} else {
						return { date: date?.date, type: '--', label: '--' };
					}
				}
				// Leave
				if (leaveDateList?.includes(date?.date)) {
					return {
						date: date?.date,
						type: 'Leave',
						label: leaveDate?.isHalfDay
							? `${leaveDate?.label}(Half a day)`
							: leaveDate?.label,
						isHalfDay: leaveDate?.isHalfDay || false,
					};
				}
				// Attendance without holiday
				if (
					!leaveDateList?.includes(date?.date) &&
					timesheetAndAttendanceDateArray?.includes(date?.date) &&
					!userHolidayDateList?.includes(date?.date) &&
					dayjs(date?.date).isSameOrBefore(dayjs())
				) {
					return { date: date?.date, type: 'Present', label: 'Present' };
				}
				// Attendance with holiday
				if (
					!leaveDateList?.includes(date?.date) &&
					timesheetAndAttendanceDateArray?.includes(date?.date) &&
					userHolidayDateList?.includes(date?.date) &&
					dayjs(date?.date).isSameOrBefore(dayjs())
				) {
					return {
						date: date?.date,
						type: 'Present',
						label: holiday?.locationId
							? `Present(${holiday?.locationName}: ${holiday?.name || ''})`
							: `Present(Holiday: ${holiday?.name || ''})`,
					};
				}
				// Holiday
				if (
					!leaveDateList?.includes(date?.date) &&
					!timesheetAndAttendanceDateArray?.includes(date?.date) &&
					userHolidayDateList?.includes(date?.date)
				) {
					return {
						date: date?.date,
						type: 'Holiday',
						label: holiday?.locationId
							? `${holiday?.locationName}: ${holiday?.name || ''}`
							: `Holiday: ${holiday?.name || ''}`,
					};
				}
				// Holiday with weekend
				if (
					!leaveDateList?.includes(date?.date) &&
					!timesheetAndAttendanceDateArray?.includes(date?.date) &&
					userHolidayDateList?.includes(date?.date) &&
					(dayjs(date?.date).day() === 0 || dayjs(date?.date).day() === 6)
				) {
					return {
						date: date?.date,
						type: 'Holiday',
						label: holiday?.locationId
							? `${holiday?.locationName}: ${holiday?.name || ''}`
							: `Holiday: ${holiday?.name || ''}`,
					};
				}
				// For Weekend
				if (
					!leaveDateList?.includes(date?.date) &&
					!timesheetAndAttendanceDateArray?.includes(date?.date) &&
					!userHolidayDateList?.includes(date?.date) &&
					(dayjs(date?.date).day() === 0 || dayjs(date?.date).day() === 6)
				) {
					if (dayjs(date?.date).day() === 0 && isSundayAvailable) {
						return { date: date?.date, type: 'Weekend', label: 'Weekend' };
					}
					if (
						dayjs(date?.date).day() === 6 &&
						saturdayAsWeekend?.includes(date?.date)
					) {
						return { date: date?.date, type: 'Weekend', label: 'Weekend' };
					}
				}
				// For future dates
				if (
					!leaveDateList?.includes(date?.date) &&
					timesheetAndAttendanceDateArray?.includes(date?.date) &&
					!userHolidayDateList?.includes(date?.date) &&
					dayjs(date?.date).isSameOrAfter(dayjs()) &&
					(dayjs(date?.date).day() === 0 || dayjs(date?.date).day() === 6)
				) {
					if (dayjs(date?.date).day() === 0 && isSundayAvailable) {
						return { date: date?.date, type: 'Weekend', label: 'Weekend' };
					}
					if (
						dayjs(date?.date).day() === 6 &&
						saturdayAsWeekend?.includes(date?.date)
					) {
						return { date: date?.date, type: 'Weekend', label: 'Weekend' };
					}
				}
				// Future dates with holiday
				if (
					!leaveDateList?.includes(date?.date) &&
					timesheetAndAttendanceDateArray?.includes(date?.date) &&
					userHolidayDateList?.includes(date?.date) &&
					dayjs(date?.date).isSameOrAfter(dayjs()) &&
					!(dayjs(date?.date).day() === 0 || dayjs(date?.date).day() === 6)
				) {
					return {
						date: date?.date,
						type: 'Holiday',
						label: holiday?.locationId
							? `${holiday?.locationName}: ${holiday?.name || ''}`
							: `Holiday: ${holiday?.name || ''}`,
					};
				}
				// Absent
				if (dayjs(date?.date).isSameOrBefore(dayjs())) {
					if (joinDate) {
						if (dayjs(date?.date).isSameOrAfter(dayjs(joinDate))) {
							return { date: date?.date, type: 'Absent', label: 'Absent' };
						} else {
							return { date: date?.date, type: 'N/A', label: 'N/A' };
						}
					}
					return { date: date?.date, type: 'Absent', label: 'Absent' };
				}

				return { date: date?.date, type: 'N/A', label: 'N/A' };
			});

			// if(!isEmpty(selectedLeaveTypeOptionValue)){

			// }
			return {
				id: user?.id,
				user: user?.full_name || '',
				email: user?.email || '',
				empId: employeeId,
				department:
					user?.user_type === 'employee'
						? user?.employee?.department?.name || ''
						: head(user?.contractors)?.department?.name || '',
				employeeType: user?.user_type
					? user?.user_type === 'employee'
						? 'Employee'
						: 'Consultant'
					: '--',
				reportingTo:
					user?.user_type === 'employee'
						? user?.employee?.reportsTo?.full_name || ''
						: head(user?.contractors)?.reportee?.full_name || '',
				reportingToEmailId:
					user?.user_type === 'employee'
						? user?.employee?.reportsTo?.email || ''
						: head(user?.contractors)?.reportee?.email || '',
				location:
					user?.user_type === 'employee'
						? user?.employee?.org_location?.name || ''
						: head(user?.contractors)?.org_location?.name || '',
				dateList,
			};
		});
		const cleanedData = resourceTableData.filter(
			(item) => Object.keys(item).length > 0
		);
		setAttendanceTableList(cleanedData);
	}, [
		monthList,
		userList,
		isFixedEmpIdLengthEnabled,
		empIdLength,
		startDate,
		endDate,
		noOfLocations,
		selectedLeaveTypeOptionValue,
	]);

	const onExportData = () => {
		if (attendanceTableList?.length === 0) {
			return;
		}
		const month = monthList?.map(
			(date) => `${dayjs(date?.date).format('ddd')},${date?.month} ${date?.day}`
		);
		const tableHeaders = [
			...leaveReportHeader,
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			...month,
		];
		const attendanceRows: any = attendanceTableList.map((user) => {
			const dateArrayList = user?.dateList.map((date: any) => {
				return date?.label;
			});
			const reportingTo = user?.reportingTo;
			const reportingToEmailId = user?.reportingToEmailId;

			const location = user?.location;

			return [
				user?.empId,
				user?.user,
				user?.email,
				user?.employeeType || '--',
				reportingTo || '--',
				reportingToEmailId || '--',
				user?.department || '--',
				...(noOfLocations && noOfLocations > 0 ? [location || '--'] : []),
				...dateArrayList,
			];
		});
		const timeSheetExportData = [tableHeaders, ...attendanceRows];
		exportToCsv(
			'Employee_Attendance_And_Leave_Report.csv',
			timeSheetExportData
		);
	};

	useEffect(() => {
		if (isEmpty(orgLocations?.org_location)) {
			return;
		}
		const locationOptionList = orgLocations?.org_location?.map((value) => {
			return {
				id: value?.id || '--',
				name: value?.name || '--',
			};
		});
		const defaultLocationIdList = locationOptionList?.map(
			(resource: { id: string; name: string }) => resource?.id
		);
		const sortedLocationOption = orderBy(locationOptionList, ['name'], ['asc']);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{
				id: 'Unassigned',
				name: 'Unassigned',
			},
		]);
		setSelectedLocationIdList(defaultLocationIdList || []);
		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(locationOptionList || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [orgLocations]);

	useEffect(() => {
		if (isEmpty(orgLeaveTypeList?.org_leave_types)) {
			return;
		}
		const optionList = orgLeaveTypeList?.org_leave_types?.map((value) => {
			return {
				id: value?.id || '--',
				name: `${value?.leave_type} (${value?.org_leave_group?.label})` || '--',
			};
		});
		const sortedLocationOption = orderBy(optionList, ['name'], ['asc']);
		setLeaveTypeDropDown([...(sortedLocationOption || [])]);
	}, [orgLeaveTypeList]);
	const getProjectResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<Box
				className={customStyles.dropdownLabel}
				marginTop={'6px'}
				marginLeft={'4px'}
			>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? head(currentSelectedOptions)?.name
					: `Selected ${currentSelectedOptions?.length} items`}
			</Box>
		);
	};

	const getLocationFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${customStyles.dropdownLabel} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const getLeaveTypeFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLeaveTypeOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${customStyles.dropdownLabel} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};
	const checkFilterError = () => {
		return !startDate || !endDate || startDateError || dateRangeError
			? false
			: true;
	};
	const filterContainer = () => (
		<Box className={customStyles.countContainerWithoutGrey} marginTop='5px'>
			<Box display='flex'>
				<Box marginTop='-15px' marginRight='15px' width='max-content'>
					<Form onSubmit={() => {}} initialValues={{ type: 'All' }}>
						{() => (
							<ThemeProvider theme={modalFormTheme}>
								<form>
									<Box className={customStyles.multipleInputContainer}>
										<ThemeProvider theme={dateTimePickerTheme}>
											<Box display='contents'>
												<Box>
													<ErrorTooltip
														title={
															startDateError
																? START_DATE_ERROR
																: dateRangeError
																? DATE_RANGE_ERROR_FOR_ATTENDANCE_REPORT
																: ''
														}
														placement='top'
														arrow={true}
														open={startDateError || dateRangeError}
													>
														<Box className={customStyles.startDate}>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	variant='inline'
																	onChange={(date: any) => {
																		setPage(0);
																		setStartDateError(false);
																		setDateRangeError(false);
																		if (dayjs(date).isAfter(dayjs(endDate))) {
																			setStartDateError(true);
																		}
																		if (
																			dayjs(endDate).diff(dayjs(date), 'day') >
																			60
																		) {
																			setDateRangeError(true);
																		}
																		setStartDate(
																			dayjs(date).format('YYYY-MM-DD')
																		);
																	}}
																	autoOk={true}
																	value={startDate}
																	format={dateFormat}
																	label='Start date'
																	error={startDateError || dateRangeError}
																	InputLabelProps={{ shrink: true }}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position='start'>
																				<IconButton>
																					<EventIcon />
																				</IconButton>
																			</InputAdornment>
																		),
																	}}
																/>
															</MuiPickersUtilsProvider>
														</Box>
													</ErrorTooltip>
												</Box>
												<Box>
													<Box className={customStyles.endDate}>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
															<KeyboardDatePicker
																variant='inline'
																onChange={(date: any) => {
																	setPage(0);
																	setStartDateError(false);
																	setDateRangeError(false);
																	if (dayjs(date).isBefore(dayjs(startDate))) {
																		setStartDateError(true);
																	}
																	if (
																		dayjs(date).diff(dayjs(startDate), 'day') >
																		60
																	) {
																		setDateRangeError(true);
																	}
																	setEndDate(dayjs(date).format('YYYY-MM-DD'));
																}}
																value={endDate}
																autoOk={true}
																format={dateFormat}
																label='End date'
																InputLabelProps={{ shrink: true }}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position='end'>
																			<IconButton>
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	),
																}}
															/>
														</MuiPickersUtilsProvider>
													</Box>
												</Box>
											</Box>
										</ThemeProvider>
										<Box className={customStyles.filterCotainer}>
											<Box className={customStyles.multipleInput}>
												<Box className={styles.label}>Employee Type</Box>
												<SelectInput
													placeholder='Choose type'
													source='type'
													label=''
													choices={[
														{ id: 'All', name: 'All' },
														{ id: 'contract_employee', name: 'Consultant' },
														{ id: 'employee', name: 'Employee' },
													]}
													onChange={(event: any) => {
														setPage(0);
														setSelectedUserType(event?.target?.value);
													}}
												/>
											</Box>
											<Box
												className={customStyles.multipleInput}
												minWidth='200px'
											>
												<Box className={styles.label}>Resource</Box>
												<Autocomplete
													multiple
													limitTags={0}
													value={
														selectedResourceOptionValue &&
														selectedResourceOptionValue?.length > 0
															? selectedResourceOptionValue
															: []
													}
													selectOnFocus={true}
													disablePortal
													fullWidth={false}
													onChange={(event, value, reason) => {
														setPage(0);
														if (!value) {
															return;
														}
														const selectedIds = value
															?.filter((resource: any) => resource.id !== 'All')
															.map((resource: any) => resource?.id);
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'select-option'
														) {
															setSelectedResourceOptionValues([
																{ id: 'All', name: 'All' },
																...reporte,
															]);
															const allResourceIds = reporte?.map(
																(resource: any) => resource?.id
															);
															setSelectedResourceIdList(allResourceIds);
															return;
														}
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'remove-option' &&
															reporte?.length !== selectedIds?.length
														) {
															const currentOptions = value?.filter(
																(resource: any) => resource?.id !== 'All'
															);
															setSelectedResourceOptionValues(currentOptions);
															const currentIds = currentOptions?.map(
																(resource: any) => resource?.id
															);
															setSelectedResourceIdList(currentIds);
															return;
														}
														if (
															selectedIds?.length === reporte?.length &&
															reason === 'select-option'
														) {
															setSelectedResourceOptionValues([
																{ id: 'All', name: 'All' },
																...reporte,
															]);
															const reporteeIds = reporte?.map(
																(resource: any) => resource?.id
															);
															setSelectedResourceIdList(reporteeIds);
															return;
														}
														if (
															selectedResourceOptionValue.find(
																(option: any) => option?.id === 'All'
															) &&
															reason === 'remove-option'
														) {
															setSelectedResourceIdList([]);
															setSelectedResourceOptionValues([]);
															return;
														}
														const selectedId = value?.map(
															(resource) => resource?.id
														);
														setSelectedResourceIdList(selectedId);
														setSelectedResourceOptionValues(value);
													}}
													options={
														reporte && reporte?.length > 0
															? [{ id: 'All', name: 'All' }, ...reporte]
															: []
													}
													disableCloseOnSelect={true}
													getLimitTagsText={getProjectResourceFilterLimits}
													ChipProps={{ style: { display: 'none' } }}
													renderInput={(params) => (
														<ThemeProvider
															theme={customAutoCompleteSearchBarTheme}
														>
															<TextField {...params} label='' />
														</ThemeProvider>
													)}
													renderOption={(option, { selected }) => (
														<>
															<Checkbox
																icon={
																	<CheckBoxOutlineBlankIcon fontSize='small' />
																}
																checkedIcon={<CheckBoxIcon fontSize='small' />}
																style={{ marginRight: 8 }}
																checked={selected}
																color='primary'
															/>
															<Tooltip
																title={`${option?.name || '- -'}`}
																placement='right'
															>
																<Typography
																	className={`${dropDownEllipsis.ellipsis}`}
																>
																	{`${option?.name || '- -'}`}
																</Typography>
															</Tooltip>
														</>
													)}
													getOptionLabel={(option) => `${option?.name}`}
													getOptionSelected={(option, value) =>
														option.id === value.id
													}
												/>
											</Box>
											{noOfLocations && noOfLocations > 0 && (
												<Box
													className={customStyles.multipleInput}
													minWidth='200px'
												>
													<Box className={styles.label}>Location</Box>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															selectedLocationOptionValue &&
															selectedLocationOptionValue?.length > 0
																? selectedLocationOptionValue
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={(event, value, reason) => {
															setPage(0);
															if (!value) {
																return;
															}
															const selectedIds = value
																?.filter((status) => status.id !== 'All')
																.map((status) => status?.id);
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'select-option'
															) {
																setSelectedLocationOptionValue([
																	{ id: 'All', name: 'All' },
																	...locationDropDown,
																]);
																const allStatusIds = locationDropDown?.map(
																	(status) => status?.id
																);
																setSelectedLocationIdList(allStatusIds);

																return;
															}
															if (
																value.find((option) => option.id === 'All') &&
																reason === 'remove-option' &&
																locationDropDown?.length !== selectedIds?.length
															) {
																const currentOptions = value?.filter(
																	(status) => status?.id !== 'All'
																);
																setSelectedLocationOptionValue(currentOptions);
																const currentIds = currentOptions?.map(
																	(resource) => resource?.id
																);
																setSelectedLocationIdList(currentIds);

																return;
															}
															if (
																selectedIds?.length ===
																	locationDropDown?.length &&
																reason === 'select-option'
															) {
																setSelectedLocationOptionValue([
																	{ id: 'All', name: 'All' },
																	...locationDropDown,
																]);
																const responseIds = locationDropDown?.map(
																	(status) => status?.id
																);
																setSelectedLocationIdList(responseIds);

																return;
															}
															if (
																selectedLocationOptionValue.find(
																	(option) => option?.id === 'All'
																) &&
																reason === 'remove-option'
															) {
																setSelectedLocationIdList([]);
																setSelectedLocationOptionValue([]);

																return;
															}
															const selectedId = value?.map(
																(status) => status?.id
															);
															setSelectedLocationIdList(selectedId);
															setSelectedLocationOptionValue(value);
														}}
														options={
															locationDropDown && locationDropDown?.length > 0
																? [
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																  ]
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={getLocationFilterLimits}
														ChipProps={{ style: { display: 'none' } }}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<TextField
																	{...params}
																	label=''
																	placeholder={''}
																/>
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => (
															<>
																<Checkbox
																	icon={
																		<CheckBoxOutlineBlankIcon fontSize='small' />
																	}
																	checkedIcon={
																		<CheckBoxIcon fontSize='small' />
																	}
																	style={{ marginRight: 8 }}
																	checked={selected}
																	color='primary'
																/>
																<Tooltip
																	title={`${option?.name || '- -'}`}
																	placement='right'
																>
																	<Typography
																		className={`${dropDownEllipsis.ellipsis}`}
																	>
																		{`${option?.name || '- -'}`}
																	</Typography>
																</Tooltip>
															</>
														)}
														getOptionLabel={(option) => `${option?.name}`}
														getOptionSelected={(option, value) =>
															option.id === value.id
														}
													/>
												</Box>
											)}
											<Box className={customStyles.multipleInput} width='200px'>
												<Box className={styles.label}>Leave Type</Box>
												<Autocomplete
													multiple
													limitTags={0}
													value={
														selectedLeaveTypeOptionValue &&
														selectedLeaveTypeOptionValue?.length > 0
															? selectedLeaveTypeOptionValue
															: []
													}
													selectOnFocus={true}
													disablePortal
													fullWidth={false}
													onChange={(event, value, reason) => {
														setPage(0);
														if (!value) {
															return;
														}
														const selectedIds = value
															?.filter((status) => status.id !== 'All')
															.map((status) => status?.id);
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'select-option'
														) {
															setSelectedLeaveTypeOptionValue([
																{ id: 'All', name: 'All' },
																...leaveTypeDropDown,
															]);
															const allStatusIds = leaveTypeDropDown?.map(
																(status) => status?.id
															);
															setSelectedLeaveTypeIdList(allStatusIds);

															return;
														}
														if (
															value.find((option) => option.id === 'All') &&
															reason === 'remove-option' &&
															leaveTypeDropDown?.length !== selectedIds?.length
														) {
															const currentOptions = value?.filter(
																(status) => status?.id !== 'All'
															);
															setSelectedLeaveTypeOptionValue(currentOptions);
															const currentIds = currentOptions?.map(
																(resource) => resource?.id
															);
															setSelectedLeaveTypeIdList(currentIds);

															return;
														}
														if (
															selectedIds?.length ===
																leaveTypeDropDown?.length &&
															reason === 'select-option'
														) {
															setSelectedLocationOptionValue([
																{ id: 'All', name: 'All' },
																...leaveTypeDropDown,
															]);
															const responseIds = leaveTypeDropDown?.map(
																(status) => status?.id
															);
															setSelectedLeaveTypeIdList(responseIds);

															return;
														}
														if (
															selectedLeaveTypeOptionValue.find(
																(option) => option?.id === 'All'
															) &&
															reason === 'remove-option'
														) {
															setSelectedLeaveTypeIdList([]);
															setSelectedLeaveTypeOptionValue([]);

															return;
														}
														const selectedId = value?.map(
															(status) => status?.id
														);
														setSelectedLeaveTypeIdList(selectedId);
														setSelectedLeaveTypeOptionValue(value);
													}}
													options={
														leaveTypeDropDown && leaveTypeDropDown?.length > 0
															? [
																	{ id: 'All', name: 'All' },
																	...leaveTypeDropDown,
															  ]
															: []
													}
													disableCloseOnSelect={true}
													getLimitTagsText={getLeaveTypeFilterLimits}
													ChipProps={{ style: { display: 'none' } }}
													renderInput={(params) => (
														<ThemeProvider
															theme={customAutoCompleteSearchBarTheme}
														>
															<TextField
																{...params}
																label=''
																placeholder={''}
															/>
														</ThemeProvider>
													)}
													renderOption={(option, { selected }) => (
														<>
															<Checkbox
																icon={
																	<CheckBoxOutlineBlankIcon fontSize='small' />
																}
																checkedIcon={<CheckBoxIcon fontSize='small' />}
																style={{ marginRight: 8 }}
																checked={selected}
																color='primary'
															/>
															<Tooltip
																title={`${option?.name || '- -'}`}
																placement='right'
															>
																<Typography
																	className={`${dropDownEllipsis.ellipsis}`}
																>
																	{`${option?.name || '- -'}`}
																</Typography>
															</Tooltip>
														</>
													)}
													getOptionLabel={(option) => `${option?.name}`}
													getOptionSelected={(option, value) =>
														option.id === value.id
													}
												/>
											</Box>

											{/* Project */}
											{permissions?.appPermissions?.ui
												?.viewSubAccountDetails && (
												<Box
													className={customStyles.multipleInput}
													minWidth='200px'
												>
													<p className={styles.label}>Project</p>
													<Field name='project_id'>
														{(props: any) => (
															<ThemeProvider theme={autoCompleteTheme}>
																<div>
																	<Autocomplete
																		id='project_id'
																		classes={{
																			option: autoCompleteSearchStyles.options,
																			groupLabel:
																				autoCompleteSearchStyles.groupLabel,
																			inputRoot:
																				autoCompleteSearchStyles.inputRoot,
																			noOptions:
																				autoCompleteSearchStyles.noOptions,
																			focused: autoCompleteSearchStyles.focused,
																			input: autoCompleteSearchStyles.input,
																			endAdornment:
																				autoCompleteSearchStyles.endAdornment,
																		}}
																		getOptionSelected={(option, value) =>
																			option.id === props.input.value
																		}
																		onChange={(event, newValue) => {
																			setPage(0)
																			props.input.onChange(newValue?.id);
																			setProjectIdFilter(newValue?.id);
																		}}
																		value={projectIdFilter}
																		options={accountProjectsOptions || []}
																		getOptionLabel={(option) =>
																			option?.name || ''
																		}
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label=''
																				placeholder='Search Project'
																				InputProps={{
																					...params.InputProps,
																					startAdornment: (
																						<InputAdornment
																							position='start'
																							classes={{
																								root: autoCompleteSearchStyles.inputAdronment,
																								positionStart:
																									autoCompleteSearchStyles.inputAdronmentPositionStart,
																							}}
																						>
																							<SearchIcon
																								className={
																									autoCompleteSearchStyles.searchIconPositionStart
																								}
																							/>
																						</InputAdornment>
																					),
																				}}
																			/>
																		)}
																	/>
																</div>
															</ThemeProvider>
														)}
													</Field>
												</Box>
											)}

											<Box className={customStyles.exportButtonMargin}>
												<ExportButtonWithLoader
													generateCSV={onExportData}
													isDisabled={isEmpty(attendanceTableList)}
												/>
											</Box>
										</Box>
									</Box>
								</form>
							</ThemeProvider>
						)}
					</Form>
				</Box>
			</Box>
		</Box>
	);
	return (
		<div>
			<Box>
				<Typography className={customStyles.heading}>
					Attendance: Month wise report
				</Typography>
			</Box>
			<Box className={customStyles.reducedCountContainer} display='flex'>
				{filterContainer()}
			</Box>
			{checkFilterError() ? (
				<Box className={customStyles.largeContainer}>
					<ThemeProvider theme={insightChartTheme}>
						<Card>
							<CardContent>
								{!isUserListLoading ? (
									<Box className={customStyles.tableContainer}>
										{attendanceTableList && attendanceTableList?.length > 0 ? (
											<TableContainer>
												<ThemeProvider theme={tableTheme}>
													<Table stickyHeader>
														<TableHead>
															<TableRow>
																<CustomTableCell
																	className={customStyles.headerCell}
																>
																	RESOURCE NAME
																</CustomTableCell>
																{monthList &&
																	monthList?.map((month) => (
																		<TableCell
																			key={month?.date}
																			className={customStyles.monthCell}
																		>
																			<Tooltip
																				title={
																					dayjs(month?.date).format('dddd') ||
																					'--'
																				}
																				placement='top'
																			>
																				<Box className={customStyles.dateCell}>
																					<Box
																						display='flex'
																						marginLeft='10px'
																						className={
																							(isOrgWeekendIncludesSunday &&
																								Number(
																									dayjs(month?.date).format('d')
																								) === 0) ||
																							(orgSaturdayList?.includes(
																								month?.date
																							) &&
																								Number(
																									dayjs(month?.date).format('d')
																								) === 6)
																								? customStyles.weekEndCell
																								: ''
																						}
																					>
																						<span>{month?.month}</span>
																						<span>{`-${month?.day}`}</span>
																					</Box>
																					<span>
																						{checkHoliday(month?.date)}
																					</span>
																				</Box>
																			</Tooltip>
																		</TableCell>
																	))}
															</TableRow>
														</TableHead>

														<TableBody>
															{attendanceTableList &&
																attendanceTableList?.length > 0 &&
																attendanceTableList
																	?.slice(
																		page * rowsPerPage,
																		page * rowsPerPage + rowsPerPage
																	)
																	.map(
																		(timeSheet) =>
																			attendanceTableList?.length > 0 && (
																				<TableRow
																					hover={true}
																					key={timeSheet?.id}
																				>
																					<CustomTableCell>
																						<Tooltip
																							title={`${timeSheet?.user}`}
																						>
																							<Typography
																								className={`${customStyles.dataCell} ${ellipsis.ellipsis}`}
																							>
																								{timeSheet?.user}
																							</Typography>
																						</Tooltip>
																					</CustomTableCell>
																					{timeSheet?.dateList &&
																						timeSheet?.dateList?.map(
																							(month: {
																								date: string;
																								type: string;
																								label: string;
																								isHalfDay?: boolean;
																								holidayName?: string | null;
																							}) => {
																								return (
																									<TableCell>
																										<Tooltip
																											title={month?.label || ''}
																										>
																											<div
																												className={`${customStyles.dataCell}`}
																											>
																												{month?.type ===
																												'Leave' ? (
																													<Box
																														className={`${customStyles.leave} ${ellipsis.ellipsis}`}
																													>
																														{month.label}
																													</Box>
																												) : month?.type ===
																												  'Holiday' ? (
																													<Box
																														className={`${customStyles.optonalHoliday} ${ellipsis.ellipsis}`}
																													>
																														{'Holiday'}
																													</Box>
																												) : month?.type ===
																												  'Weekend' ? (
																													<Box
																														className={`${customStyles.weekend} ${ellipsis.ellipsis}`}
																													>
																														{'Weekend'}
																													</Box>
																												) : month?.type ===
																												  '--' ? (
																													<Box
																														className={`${customStyles.noLeaveData} ${ellipsis.ellipsis}`}
																													>
																														{'--'}
																													</Box>
																												) : (
																													<Box
																														className={
																															month?.type ===
																															'Absent'
																																? customStyles.absent
																																: month?.type ===
																																  'Present'
																																? customStyles.present
																																: customStyles.notMarked
																														}
																													>
																														{month.type}
																													</Box>
																												)}
																											</div>
																										</Tooltip>
																									</TableCell>
																								);
																							}
																						)}
																				</TableRow>
																			)
																	)}
														</TableBody>
													</Table>
												</ThemeProvider>
												<Box display='flex' justifyContent='flex-end'>
													<TablePagination
														rowsPerPageOptions={[5, 10, 25]}
														count={attendanceTableList?.length || 0}
														rowsPerPage={rowsPerPage}
														page={page}
														onPageChange={handleChangePage}
														onChangeRowsPerPage={handleChangeRowsPerPage}
													/>
												</Box>
											</TableContainer>
										) : (
											<Box className={customStyles.noData}>
												<Typography className={customStyles.noDataLabel}>
													No data found
												</Typography>
											</Box>
										)}
									</Box>
								) : (
									<Box className={customStyles.noData}>
										<Box className={customStyles.loading}>Loading</Box>
										<CircularProgress />
									</Box>
								)}
							</CardContent>
						</Card>
					</ThemeProvider>
				</Box>
			) : (
				<Box className={customStyles.noData}>
					<Typography className={customStyles.noDataLabel}>
						No data found
					</Typography>
				</Box>
			)}
		</div>
	);
};
export default EmployeeAttendanceReport;
