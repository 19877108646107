import React, { useContext, useState } from 'react';
import { InsightsIcon } from '../../assets/SvgIcons';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { globalStyles } from '../../Layout/styles';
import { UserProfileContext } from '../../App';
import { Box, makeStyles, Button } from '@material-ui/core';
import ProjectInsightContainer from './Insight/ProjectInsightContainer.component';
import { PROJECT_CONTAINER } from './constant';
import { MilestoneKanbanContainer } from './Milestones/MilestonesKanbanContainer.component';
import { TaskList } from '../ProjectTask/TaskList.component';
import RequestResourceContainer from './RequestResourceContainer.component';
import ProjectDataGridList from './ProjectDataGridList.component';
const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	navigationTabContainer: {
		width: '610px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
	prefixName: {
		fontSize: '12px',
		width: 'auto',
		fontFamily: 'Manrope-medium',
		fontWeight: 'bold',
		color: 'grey',
		opacity: '0.8',
	},
	projectName: {
		fontSize: '12px',
		maxWidth: '100px',
		fontFamily: 'Manrope-medium',
		opacity: '0.8',
		color: '#292929',
	},
	resourceCount: {
		fontSize: '12px',
		width: '50px',
		textAlign: 'center',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},

	companyDropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	resourceCountContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '125px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
		whiteSpace: 'nowrap',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-72px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	companyDropListCount: {
		marginTop: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
			maxWidth: '150px',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
			maxWidth: '150px',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
			maxWidth: '150px',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		fontSize: '10px !important',
		color: '#888888',
	},
	dateEndProject: {
		color: '#EA4335',
		fontSize: '12px !important',
		fontFamily: 'Manrope',
	},
	leftProjectDate: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
	},
	days: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
		color: '#292929',
		paddingLeft: '2px',
	},
	expiryFilterStatus: {
		background: '#ffffff',
		height: '36px',
		alignItems: 'left',
		justifyContent: 'flex-start',
	},
	employeesContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	clearIcon: {
		width: '18px',
		margin: '0 4px 0 0',
		cursor: 'pointer',
	},
	menuIcon: {
		height: '18px',
		width: '18px',
	},
	copyContainer: {
		'&:hover': {
			'& > svg': {
				opacity: '1',
			},
		},
	},
	copyIcon: {
		opacity: '0',
		height: '16px',
		width: '16px',
		color: '#1d82f5',
		marginLeft: '10px',
	},
	taskButton: {
		borderRight: '1px solid #E0E0E0',
	},
	projectStatus: {
		marginBottom: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '-2px',
	},
}));
const timesheetButtonTheme = createTheme({
	overrides: {
		MuiButton: {
			startIcon: {
				marginRight: '1px',
			},
		},
	},
});
export const ProjectList = () => {
	const classes = useStyles();
	const commonStyles = globalStyles();
	const { permissions, featureFlag } = useContext(UserProfileContext);
	const [container, setContainer] = useState(PROJECT_CONTAINER.projects);
	const [projectListCount, setProjectListCount] = useState(0);

	function handleProjectClick() {
		setContainer(PROJECT_CONTAINER.projects);
	}
	function handleTaskClick() {
		setContainer(PROJECT_CONTAINER.tasks);
	}
	function handleMilestoneClick() {
		setContainer(PROJECT_CONTAINER.milestones);
	}
	function handleTimesheetClick() {
		setContainer(PROJECT_CONTAINER.timesheet);
	}
	function handleResourceRequestClick() {
		setContainer(PROJECT_CONTAINER.resourceRequest);
	}
	return (
		<>
			<>
				<Box className={classes.navigationTabContainer}>
					<Button
						className={[
							commonStyles.navigationTabButtons,
							container === PROJECT_CONTAINER.projects
								? commonStyles.activeNavigationTabButton
								: '',
						].join(' ')}
						onClick={() => {
							handleProjectClick();
						}}
					>
						Projects({projectListCount || 0})
					</Button>
					{featureFlag?.projectTaskDatagrid &&
						permissions?.appPermissions?.ui?.viewProjectTaskList && (
							<Button
								className={[
									commonStyles.navigationTabButtons,
									classes.taskButton,
									container === PROJECT_CONTAINER.tasks
										? commonStyles.activeNavigationTabButton
										: '',
								].join(' ')}
								onClick={() => {
									handleTaskClick();
								}}
							>
								Tasks
							</Button>
						)}
					{featureFlag?.mileStoneKanban &&
						permissions?.appPermissions?.ui?.viewProjectMilestones && (
							<Button
								className={[
									commonStyles.navigationTabButtons,
									classes.taskButton,
									container === PROJECT_CONTAINER.milestones
										? commonStyles.activeNavigationTabButton
										: '',
								].join(' ')}
								onClick={() => {
									handleMilestoneClick();
								}}
							>
								Milestones
							</Button>
						)}
					{permissions?.appPermissions?.ui?.viewEmployeeInsights && (
						<ThemeProvider theme={timesheetButtonTheme}>
							<Button
								className={[
									commonStyles.navigationTabButtons,
									classes.taskButton,
									container === PROJECT_CONTAINER.timesheet
										? commonStyles.activeNavigationTabButton
										: '',
								].join(' ')}
								onClick={() => {
									handleTimesheetClick();
								}}
								startIcon={<InsightsIcon />}
							>
								Timesheet
							</Button>
						</ThemeProvider>
					)}
					{featureFlag?.resourceRequest &&
						permissions?.appPermissions?.ui?.viewProjectResourceRequest && (
							<Button
								className={[
									commonStyles.navigationTabButtons,
									container === PROJECT_CONTAINER.resourceRequest
										? commonStyles.activeNavigationTabButton
										: '',
								].join(' ')}
								onClick={() => {
									handleResourceRequestClick();
								}}
							>
								Resource Request
							</Button>
						)}
				</Box>
				{container === PROJECT_CONTAINER.projects && (
					<ProjectDataGridList setProjectListCount={setProjectListCount} />
				)}
				{container === PROJECT_CONTAINER.tasks && <TaskList />}
				{container === PROJECT_CONTAINER.milestones && (
					<MilestoneKanbanContainer />
				)}
				{container === PROJECT_CONTAINER.timesheet && (
					<ProjectInsightContainer />
				)}
				{container === PROJECT_CONTAINER.resourceRequest && (
					<RequestResourceContainer />
				)}
			</>
		</>
	);
};
