import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Theme,
	ThemeProvider,
	Tooltip,
	Typography,
	makeStyles,
	TextField as TextComponent,
	CardHeader,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ErrorTooltip, dateTimePickerTheme } from '../Style';

import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	useGetActiveUsersByLimitQuery,
	useGetEmployeeAttendanceDetailQuery,
	useGetLocationsByOrgIdQuery,
	useGetMyTeamViewInAttendanceQuery,
} from '../../../generated/graphql';
import dayjs from 'dayjs';
import { head, isEmpty, orderBy, sortBy, uniqBy } from 'lodash';
import { Form } from 'react-final-form';
import {
	InsightReportTableGlobalStyles,
	autocompleteStyle,
	ellipsisStyle,
	modalFormStyle,
} from '../../../Layout/styles';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import { UserProfileContext } from '../../../App';
import {
	exportToCsv,
	formatEmployeeId,
	getPrefix,
} from '../../../Utils/string.util';
import isBetween from 'dayjs/plugin/isBetween';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { LEAVE_STATUS_INFO, START_DATE_ERROR } from '../Constant';
import InfoIcon from '@material-ui/icons/Info';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const useStyles = makeStyles((theme: Theme) => ({
	reducedValueContainer: {
		width: '95px',
		height: '48px',
		background: '#F7F9FA',
		borderRadius: '4px',
		padding: '6px 0px 6px 6px',
		marginRight: '16px',
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	autoCompleteInputContainer: {
		minWidth: '170px',
		marginRight: '8px',
	},
	exportButtonDiv: {
		marginTop: '25px',
		marginLeft: '8px',
	},
	exportButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: 'lightgrey 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	nameCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '100px',
	},
	startDate: {
		width: '188px',
		marginLeft: '20px',
		marginTop: '14px',
	},
	endDate: {
		width: '188px',
		marginTop: '14px',
	},
	iconContainer: {
		width: '24px',
		height: '24px',
		marginTop: '-2px',
	},
	editIcon: {
		width: '12px',
		height: '12px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
		minHeight: '200px',
	},
}));
interface option {
	id: any;
	name?: String | null | undefined;
}
interface Attendance {
	empId: string;
	resourceName: string | null | undefined;
	email: string;
	location: string;
	type: string;
	date: any;
	hours: string;
	leaveStatus: string | undefined;
	leaveReason: string | undefined;
	userType: string | undefined;
}
export const AttendanceDetailedReport = () => {
	dayjs.extend(isBetween);
	const {
		orgId: organizationId,
		dateFormat,
		noOfLocations,
		empIdLength,
		isFixedEmpIdLengthEnabled,
		accountEmployeeUserIds,
		id: userId,
		permissions,
	} = useContext<any>(UserProfileContext);

	const [filterStartDate, setFilterStartDate] = useState<string>(
		dayjs().subtract(1, 'day').format('YYYY-MM-DD')
	);
	const [filterEndDate, setFilterEndDate] = useState<string>(
		dayjs().subtract(1, 'day').format('YYYY-MM-DD')
	);
	const [attendanceTableList, setAttendanceTableList] = useState<
		Attendance[] | undefined
	>();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<option[]>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		String[]
	>([]);
	const [resourceList, setresourceList] = useState<option[]>([]);
	const [startDateError, setStartDateError] = useState(false);
	const [selectedLocationOptionValue, setSelectedLocationOptionValue] =
		useState<option[]>([]);
	const [selectedLocationIdList, setSelectedLocationIdList] = useState<
		String[]
	>([]);
	const [locationDropDown, setLocationDropDown] = useState<option[]>([]);

	const ellipsis = ellipsisStyle();
	const classes = useStyles();
	const styles = modalFormStyle();
	const dropDownStyle = autocompleteStyle();
	const commonStyle = InsightReportTableGlobalStyles();

	const { data: resource } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: orgLocations } = useGetLocationsByOrgIdQuery({
		variables: {
			orgId: organizationId,
		},
		fetchPolicy: 'network-only',
	});

	const { data: myTeam } = useGetMyTeamViewInAttendanceQuery({
		variables: {
			userId: userId,
		},
		fetchPolicy: 'network-only',
		skip: !permissions?.appPermissions?.ui?.viewSubAccountDetails,
	});

	// Function to create the location filter for the query based on the filters
	const getLocationFilter = () => {
		if (noOfLocations && noOfLocations > 0) {
			if (isEmpty(selectedLocationOptionValue)) {
				return [];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue?.find((option) => option?.id === 'All')
			) {
				return [{}];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length === 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						contractors: {
							location_id: {
								_in: selectedLocationIdList?.filter(
									(location) => location !== 'Unassigned'
								),
							},
						},
					},
					{
						employee: {
							location_id: { _is_null: true },
						},
					},
					{
						contractors: {
							location_id: { _is_null: true },
						},
					},
				];
			}
			if (
				selectedLocationOptionValue &&
				selectedLocationOptionValue.length > 1 &&
				!selectedLocationOptionValue?.find(
					(option) => option?.id === 'Unassigned'
				)
			) {
				return [
					{
						employee: {
							location_id: { _in: selectedLocationIdList },
						},
					},
					{
						contractors: {
							location_id: { _in: selectedLocationIdList },
						},
					},
				];
			}
		}
		return [{}];
	};
	const { data: employeeAttendanceData } = useGetEmployeeAttendanceDetailQuery({
		variables: {
			where: {
				date: { _gte: filterStartDate, _lte: filterEndDate },
				user: {
					id:
						selectedResourceIdList && selectedResourceIdList?.length > 0
							? { _in: selectedResourceIdList }
							: { _in: [] },
					is_active: { _eq: true },
					_or: getLocationFilter(),
				},
			},
			startDate: filterStartDate,
			endDate: filterEndDate,
		},
		fetchPolicy: 'network-only',
	});

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedLocationOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	useEffect(() => {
		if (!resource) {
			return;
		}
		if (!permissions?.appPermissions?.ui?.viewSubAccountDetails) {
			const response = resource?.user?.map((user) => {
				return {
					id: user?.id,
					name: user?.name,
				};
			});
			const defaultResourceIdList = response?.map(
				(resource: { id: any; name: string | null | undefined }) => resource?.id
			);
			setresourceList(response);
			setSelectedResourceIdList(defaultResourceIdList);
			setSelectedResourceOptionValues([
				{ id: 'All', name: 'All' },
				...response,
			]);

			return;
		}
		// Get the reportees
		const reportees =
			myTeam?.user.map((usr) => ({
				id: usr.id,
				name: usr.full_name,
			})) || [];
		const reporteesIds = reportees?.map((usr) => usr.id) || [];

		// Get sub account employees
		const filterUserList = resource?.user
			.filter((usr) => accountEmployeeUserIds.includes(usr.id))
			.filter((user) => user.id !== userId);
		const idList = filterUserList?.map((resource) => resource?.id);

		const finalUserList = sortBy(
			uniqBy([...reportees, ...filterUserList], 'id'),
			(val) => val.name
		);

		setresourceList(finalUserList);
		setSelectedResourceIdList([...idList, ...reporteesIds]);
		setSelectedResourceOptionValues([
			{ id: 'All', name: 'All' },
			...finalUserList,
		]);
	}, [resource, permissions, accountEmployeeUserIds, myTeam, userId]);

	useEffect(() => {
		if (isEmpty(orgLocations?.org_location)) {
			return;
		}
		const locationOptionList = orgLocations?.org_location?.map((value) => {
			return {
				id: value?.id || '--',
				name: value?.name || '--',
			};
		});
		const defaultLocationIdList = locationOptionList?.map(
			(resource: { id: string; name: string }) => resource?.id
		);
		const sortedLocationOption = orderBy(locationOptionList, ['name'], ['asc']);

		setSelectedLocationIdList(defaultLocationIdList || []);
		setSelectedLocationOptionValue([
			{ id: 'All', name: 'All' },
			...(locationOptionList || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
		setLocationDropDown([
			...(sortedLocationOption || []),
			{ id: 'Unassigned', name: 'Unassigned' },
		]);
	}, [orgLocations]);

	useEffect(() => {
		if (!employeeAttendanceData) {
			return;
		}
		const tableData = employeeAttendanceData?.attendance?.map((attendance) => {
			const existingEmployeeId = attendance?.user?.employee?.emp_id || '';
			const existingContractorId =
				head(attendance?.user?.contractors)?.contractor_id || '';
			const formattedEmployeeId = formatEmployeeId(
				existingEmployeeId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);
			const formattedContractorId = formatEmployeeId(
				existingContractorId,
				empIdLength,
				isFixedEmpIdLengthEnabled
			);
			const employeeId =
				attendance?.user?.user_type === 'contract_employee'
					? head(attendance?.user?.contractors)?.contractor_id
						? `${
								getPrefix(
									head(attendance?.user?.contractors)?.contractor_prefix?.name,
									head(attendance?.user?.contractors)?.contractor_prefix?.value
								) || ''
						  }${formattedContractorId}`
						: '--'
					: attendance?.user?.employee?.emp_id
					? `${
							getPrefix(
								attendance?.user?.employee?.employee_prefix?.name,
								attendance?.user?.employee?.employee_prefix?.value
							) || ''
					  }${formattedEmployeeId}`
					: '--';
			const userLocation =
				attendance?.user?.user_type === 'employee'
					? attendance?.user?.employee?.org_location?.name || '--'
					: head(attendance?.user?.contractors)?.org_location?.name || '--';
			const userType =
				attendance?.user?.user_type === 'employee' ? 'Employee' : 'Consultant';
			const appliedLeaveList =
				attendance?.user?.user_type === 'employee'
					? attendance?.user?.employee?.employee_leaving_mappings || []
					: [];
			const leaveDates =
				appliedLeaveList &&
				appliedLeaveList?.map((appliedLeave) => {
					if (
						dayjs(attendance?.date).isBetween(
							appliedLeave?.from_date,
							appliedLeave?.to_date,
							'date',
							'[]'
						)
					) {
						return {
							date: attendance?.date,
							type: appliedLeave?.org_leave_type?.leave_type || '',
							reason: appliedLeave?.reason || '',
						};
					}
					return {};
				});
			const appliedLeaveStatus = leaveDates?.filter(
				(item) => item?.date === attendance?.date
			);
			return [
				{
					empId: employeeId,
					resourceName: attendance?.user?.full_name,
					email: attendance?.user?.email,
					location: userLocation,
					userType: userType || '--',
					type: 'I',
					date: attendance?.date
						? dayjs(attendance?.date).format(dateFormat)
						: '--',
					hours: attendance?.in_time
						? dayjs(`${attendance.date} ${attendance.in_time}`).format(
								'HH:mm:ss'
						  )
						: '',
					leaveStatus:
						appliedLeaveStatus && appliedLeaveStatus?.length > 0
							? head(appliedLeaveStatus)?.type
							: '--',
					leaveReason:
						appliedLeaveStatus && appliedLeaveStatus?.length > 0
							? head(appliedLeaveStatus)?.reason
							: '--',
				},
				{
					empId: employeeId,
					resourceName: attendance?.user?.full_name,
					email: attendance?.user?.email,
					location: userLocation,
					userType: userType || '--',
					type: 'O',
					date: attendance?.date
						? dayjs(attendance?.date).format(dateFormat)
						: '--',
					hours: attendance?.out_time
						? dayjs(`${attendance.date} ${attendance.out_time}`).format(
								'HH:mm:ss'
						  )
						: '',
					leaveStatus:
						appliedLeaveStatus && appliedLeaveStatus?.length > 0
							? head(appliedLeaveStatus)?.type
							: '--',
					leaveReason:
						appliedLeaveStatus && appliedLeaveStatus?.length > 0
							? head(appliedLeaveStatus)?.reason
							: '--',
				},
			];
		});
		setAttendanceTableList(tableData.flat());
	}, [
		dateFormat,
		employeeAttendanceData,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	]);

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateExport = () => {
		if (isEmpty(attendanceTableList)) {
			return;
		}
		const columnHeadings = [
			'RESOURCE ID',
			'RESOURCE NAME',
			'EMAIL',
			'RESOURCE TYPE',
			...(noOfLocations && noOfLocations > 0 ? ['LOCATION'] : []),
			'TYPE',
			'DATE',
			'HOURS',
			'LEAVE STATUS',
			'REASON',
		];
		const exportRows: any =
			attendanceTableList &&
			attendanceTableList.map((attendance) => {
				return [
					attendance?.empId || '--',
					attendance?.resourceName || '--',
					attendance?.email || '--',
					attendance?.userType || '--',
					...(noOfLocations && noOfLocations > 0
						? [attendance?.location || '--']
						: []),
					attendance?.type || '--',
					attendance?.date || '--',
					attendance?.hours || '--',
					attendance?.leaveStatus || '--',
					attendance?.leaveReason || '--',
				];
			});
		const attendanceExportData = [columnHeadings, ...exportRows];
		exportToCsv(
			`Employee_Attendance_Report_From_${dayjs(filterStartDate).format(
				dateFormat
			)}_To_${dayjs(filterEndDate).format(dateFormat)}.csv`,
			attendanceExportData
		);
	};
	return (
		<ThemeProvider theme={insightChartTheme}>
			<Card>
				<CardHeader
					style={{ marginLeft: '20px' }}
					title='Attendance: Detailed report'
					titleTypographyProps={{ variant: 'h6' }}
				/>

				<Box display='flex'>
					<Box marginTop={'4px'}>
						<Form onSubmit={() => {}}>
							{() => (
								<ThemeProvider theme={modalFormTheme}>
									<form>
										<Box className={classes.header}>
											<Box marginTop={'20px'}>
												<ThemeProvider theme={dateTimePickerTheme}>
													<Box display='flex'>
														<ErrorTooltip
															title={startDateError ? START_DATE_ERROR : ''}
															placement='top'
															arrow={true}
														>
															<Box width='188px' marginLeft='20px'>
																<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDatePicker
																		variant='inline'
																		onChange={(date: any) => {
																			setPage(0);
																			setStartDateError(false);
																			if (
																				dayjs(date).isAfter(
																					dayjs(filterEndDate)
																				)
																			) {
																				setStartDateError(true);
																			} else {
																				setStartDateError(false);
																			}
																			setFilterStartDate(
																				dayjs(date).format('YYYY-MM-DD')
																			);
																		}}
																		autoOk={true}
																		value={filterStartDate}
																		format={dateFormat}
																		label='Start date'
																		error={startDateError}
																		InputLabelProps={{ shrink: true }}
																		InputProps={{
																			endAdornment: (
																				<InputAdornment position='start'>
																					<IconButton>
																						<EventIcon />
																					</IconButton>
																				</InputAdornment>
																			),
																		}}
																	/>
																</MuiPickersUtilsProvider>
															</Box>
														</ErrorTooltip>
														<Box width='188px'>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																<KeyboardDatePicker
																	variant='inline'
																	onChange={(date: any) => {
																		setPage(0);
																		setStartDateError(false);
																		if (
																			dayjs(date).isBefore(
																				dayjs(filterStartDate)
																			)
																		) {
																			setStartDateError(true);
																		} else {
																			setStartDateError(false);
																		}
																		setFilterEndDate(
																			dayjs(date).format('YYYY-MM-DD')
																		);
																	}}
																	value={filterEndDate}
																	autoOk={true}
																	format={dateFormat}
																	label='End date'
																	InputLabelProps={{ shrink: true }}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position='end'>
																				<IconButton>
																					<EventIcon />
																				</IconButton>
																			</InputAdornment>
																		),
																	}}
																/>
															</MuiPickersUtilsProvider>
														</Box>
													</Box>
												</ThemeProvider>
											</Box>
											<Box display='flex'>
												<Box display='block' width='170px'>
													<Box className={styles.label}>Resource</Box>
													<Box className={classes.autoCompleteInputContainer}>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedResourceOptionValue &&
																selectedResourceOptionValue?.length > 0
																	? selectedResourceOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter((resource) => resource.id !== 'All')
																	.map((resource) => resource?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...resourceList,
																	]);
																	const allResourceIds = resourceList?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(allResourceIds);

																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	resourceList?.length !== selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(resource) => resource?.id !== 'All'
																	);
																	setSelectedResourceOptionValues(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(currentIds);

																	return;
																}
																if (
																	selectedIds?.length ===
																		resourceList?.length &&
																	reason === 'select-option'
																) {
																	setSelectedResourceOptionValues([
																		{ id: 'All', name: 'All' },
																		...resourceList,
																	]);
																	const responseIds = resourceList?.map(
																		(resource) => resource?.id
																	);
																	setSelectedResourceIdList(responseIds);

																	return;
																}
																if (
																	selectedResourceOptionValue.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedResourceIdList([]);
																	setSelectedResourceOptionValues([]);

																	return;
																}
																const selectedId = value?.map(
																	(resource) => resource?.id
																);
																setSelectedResourceIdList(selectedId);

																setSelectedResourceOptionValues(value);
															}}
															options={
																resourceList && resourceList?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...resourceList,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getResourceFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent {...params} label='' />
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																			style={{ fontSize: '12px' }}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
											</Box>
											{noOfLocations && noOfLocations > 0 && (
												<Box display='block' width='170px' marginLeft={'10px'}>
													<Box className={styles.label}> Location</Box>
													<Box className={classes.autoCompleteInputContainer}>
														<Autocomplete
															multiple
															limitTags={0}
															value={
																selectedLocationOptionValue &&
																selectedLocationOptionValue?.length > 0
																	? selectedLocationOptionValue
																	: []
															}
															selectOnFocus={true}
															disablePortal
															fullWidth={false}
															onChange={(event, value, reason) => {
																setPage(0);
																if (!value) {
																	return;
																}
																const selectedIds = value
																	?.filter(
																		(location) =>
																			location?.id !== 'All' &&
																			location?.id !== 'Unassigned'
																	)
																	.map((location) => location?.id);
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const allLocationIds = locationDropDown?.map(
																		(location) => location?.id
																	);
																	setSelectedLocationIdList(allLocationIds);
																	return;
																}
																if (
																	value.find((option) => option.id === 'All') &&
																	reason === 'remove-option' &&
																	locationDropDown?.length !==
																		selectedIds?.length
																) {
																	const currentOptions = value?.filter(
																		(location) => location?.id !== 'All'
																	);
																	setSelectedLocationOptionValue(
																		currentOptions
																	);
																	const currentIds = currentOptions?.map(
																		(resource) => resource?.id
																	);
																	setSelectedLocationIdList(currentIds);

																	return;
																}
																const selectedIdsWithoutUnassigned =
																	selectedLocationIdList?.filter(
																		(location) => location !== 'Unassigned'
																	);
																if (
																	selectedIds?.length === 0 &&
																	selectedIdsWithoutUnassigned.length === 0 &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'Unassigned', name: 'Unassigned' },
																	]);
																	const locationIds: any =
																		locationDropDown?.map(
																			(location) =>
																				location?.id === 'Unassigned'
																		);
																	setSelectedLocationIdList(locationIds);
																	return;
																}
																const selectedAllIds = value?.map(
																	(location) => location?.id
																);
																if (
																	selectedAllIds?.length ===
																		locationDropDown?.length &&
																	reason === 'select-option'
																) {
																	setSelectedLocationOptionValue([
																		{ id: 'All', name: 'All' },
																		...locationDropDown,
																	]);
																	const responseIds = locationDropDown?.map(
																		(location) => location?.id
																	);
																	setSelectedLocationIdList(responseIds);

																	return;
																}
																if (
																	selectedLocationOptionValue.find(
																		(option) => option?.id === 'All'
																	) &&
																	reason === 'remove-option'
																) {
																	setSelectedLocationIdList([]);
																	setSelectedLocationOptionValue([]);

																	return;
																}
																const selectedId = value?.map(
																	(location) => location?.id
																);
																setSelectedLocationIdList(selectedId);
																setSelectedLocationOptionValue(value);
															}}
															options={
																locationDropDown && locationDropDown?.length > 0
																	? [
																			{ id: 'All', name: 'All' },
																			...locationDropDown,
																	  ]
																	: []
															}
															disableCloseOnSelect={true}
															getLimitTagsText={getStatusFilterLimits}
															ChipProps={{ style: { display: 'none' } }}
															renderInput={(params) => (
																<ThemeProvider
																	theme={customAutoCompleteSearchBarTheme}
																>
																	<TextComponent
																		{...params}
																		label=''
																		placeholder={''}
																	/>
																</ThemeProvider>
															)}
															renderOption={(option, { selected }) => (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected}
																		color='primary'
																	/>
																	<Tooltip
																		title={`${option?.name || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																		>
																			{`${option?.name || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															)}
															getOptionLabel={(option) => `${option?.name}`}
															getOptionSelected={(option, value) =>
																option.id === value.id
															}
														/>
													</Box>
												</Box>
											)}
											<Box className={classes.exportButtonDiv}>
												<ExportButtonWithLoader
													generateCSV={generateExport}
													isDisabled={isEmpty(attendanceTableList)}
												/>
											</Box>
										</Box>
									</form>
								</ThemeProvider>
							)}
						</Form>
					</Box>
				</Box>
				<CardContent>
					<Box className={classes.tableContainer} minHeight=''>
						{attendanceTableList && attendanceTableList?.length > 0 ? (
							<TableContainer>
								<Table stickyHeader>
									<TableHead>
										<TableRow>
											<CustomTableCell className={commonStyle.headerCell}>
												RESOURCE ID
											</CustomTableCell>
											<CustomTableCell className={commonStyle.headerCell}>
												RESOURCE NAME
											</CustomTableCell>
											{noOfLocations && noOfLocations > 0 && (
												<CustomTableCell className={commonStyle.headerCell}>
													LOCATION
												</CustomTableCell>
											)}
											<CustomTableCell className={commonStyle.headerCell}>
												TYPE
											</CustomTableCell>
											<CustomTableCell className={commonStyle.headerCell}>
												DATE
											</CustomTableCell>
											<CustomTableCell className={commonStyle.headerCell}>
												HOURS
											</CustomTableCell>
											<CustomTableCell className={commonStyle.headerCell}>
												<Box display='flex'>
													<Box>LEAVE STATUS</Box>
													<Box>
														<Tooltip
															title={LEAVE_STATUS_INFO}
															placement='right'
														>
															<IconButton className={classes.iconContainer}>
																<InfoIcon className={classes.editIcon} />
															</IconButton>
														</Tooltip>
													</Box>
												</Box>
											</CustomTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{attendanceTableList &&
											attendanceTableList?.length > 0 &&
											attendanceTableList
												?.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
												)
												.map((data) => {
													return (
														<TableRow hover={true}>
															<CustomTableCell>
																<Tooltip title={data?.empId || '--'}>
																	<Typography
																		className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																	>
																		{data?.empId || '--'}
																	</Typography>
																</Tooltip>
															</CustomTableCell>

															<CustomTableCell>
																<Tooltip
																	title={
																		data?.resourceName
																			? data?.resourceName
																			: '--'
																	}
																>
																	<Typography
																		className={`${classes.nameCell} ${ellipsis.ellipsis}`}
																	>
																		{data?.resourceName
																			? data?.resourceName
																			: '--'}
																	</Typography>
																</Tooltip>
															</CustomTableCell>
															{noOfLocations && noOfLocations > 0 && (
																<CustomTableCell>
																	<Tooltip title={data?.location || '--'}>
																		<Typography
																			className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																		>
																			{data?.location || '--'}
																		</Typography>
																	</Tooltip>
																</CustomTableCell>
															)}
															<CustomTableCell>
																<Tooltip
																	title={
																		data?.type
																			? data?.type === 'I'
																				? 'In Time'
																				: 'Out Time'
																			: '--'
																	}
																>
																	<Typography
																		className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																	>
																		{data?.type || '--'}
																	</Typography>
																</Tooltip>
															</CustomTableCell>
															<CustomTableCell>
																<Tooltip title={data?.date || '--'}>
																	<Typography
																		className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																	>
																		{data?.date || '--'}
																	</Typography>
																</Tooltip>
															</CustomTableCell>
															<CustomTableCell>
																<Tooltip title={data?.hours || '--'}>
																	<Typography
																		className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																	>
																		{data?.hours || '--'}
																	</Typography>
																</Tooltip>
															</CustomTableCell>
															<CustomTableCell>
																<Tooltip title={data?.leaveStatus || '--'}>
																	<Typography
																		className={`${classes.dataCell} ${ellipsis.ellipsis}`}
																	>
																		{data?.leaveStatus || '--'}
																	</Typography>
																</Tooltip>
															</CustomTableCell>
														</TableRow>
													);
												})}
									</TableBody>
								</Table>
								<Box display='flex' justifyContent='flex-end'>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25]}
										count={attendanceTableList?.length || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</Box>
							</TableContainer>
						) : (
							<Box className={classes.noData}>
								<Typography className={commonStyle.noDataLabel}>
									No data found
								</Typography>
							</Box>
						)}
					</Box>
				</CardContent>
			</Card>
		</ThemeProvider>
	);
};
