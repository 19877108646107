import React, { useContext, useState, useEffect, useRef } from 'react';
import { SelectInput, setSidebarVisibility } from 'react-admin';
import { SearchIcon } from '../../assets/SvgIcons';

import EditIcon from '@material-ui/icons/Edit';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
	ellipsisStyle,
	datagridStyle,
	autocompleteStyle,
	dropDownEllipsisStyle,
} from '../../Layout/styles';
import {
	searchBarTheme,
	modalFormTheme,
	customAutoCompleteSearchBarTheme,
} from '../../Layout/Theme.component';
import { UserProfileContext } from '../../App';
import { useHistory } from 'react-router-dom';
import {
	Box,
	Typography,
	Tooltip,
	makeStyles,
	TextField as TextComponent,
	Button,
	IconButton,
	Checkbox,
	CircularProgress,
} from '@material-ui/core';
import { useQuery as useApolloQuery } from '@apollo/client';
import {
	GET_PROJECT_EXPORT_FILTER,
	GET_PROJECT_RESOURCES,
} from './Projects.gql';
import {
	GET_PROJECT,
	GET_USER_BY_TEAMS,
	GET_USER_TEAMS,
} from '../ResourcePlanner/gqlQueries';
import ProjectForm from '../ResourcePlanner/ProjectForm.component';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import DeleteModal from '../../SharedComponents/DeleteModal.component';
import {
	useGetProjectDataGridListsQuery,
	useGetProjectStatusInProjectListQuery,
	useGetProjectTimesheetEntryCountQuery,
} from '../../generated/graphql';

import { Form } from 'react-final-form';
import { isEmpty, orderBy, sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { FILTER_STATE_ACTIONS } from '../../globalState';
import { AutocompleteSearch } from '../../SharedComponents/Autocompletesearch.component';
import MUIDataTable from 'mui-datatables';
import ResourceDateForm from './ResourceDateExtension.component';
import ProjectEndDateForm from './ProjectEndDateExtension.component';
import Loader from '../PMS/SharedComponents/Loader.component';
import {
	ALL_PROJECTS,
	EXPIRED_PROJECTS,
	projectFilterOptions,
} from './constant';
import dayjs from 'dayjs';

const customizedDataGridTheme = createTheme({
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MuiPaper: {
			elevation4: {
				boxShadow: 'none',
				background: '#F7F9FA',
				backgroundColor: '#F7F9FA',
			},
			root: {
				backgroundColor: '#F7F9FA',
			},
		},
		MuiTablePagination: {
			caption: {
				fontSize: '12px',
				fontFamily: 'Manrope-medium',
			},
		},
		MuiToolbar: {
			gutters: {
				'@media (min-width: 600px)': {
					paddingLeft: '0px',
				},
				paddingLeft: '0px',
			},
		},
		MUIDataTable: {
			tableRoot: {
				background: '#F7F9FA',
			},
		},
		MuiChip: {
			label: {
				color: '#4285F4',
				fontFamily: 'Manrope-semibold',
				fontSize: '10px',
			},
			deleteIcon: {
				color: '#4285F4',
				width: '16px',
				height: '16px',
			},
			deletable: {
				minWidth: '65px',
				height: '20px',
				border: '1px solid #E0E0E0',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				borderRadius: '4px',
			},
		},
		MUIDataTableToolbar: {
			titleText: {
				display: 'none',
			},
			actions: {
				marginRight: '200px',
			},
			icon: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
			iconActive: {
				font: 'normal normal bold 12px/37px Manrope',
				color: '#4285F4',
				width: ' 36px',
				border: '1px solid #E0E0E0',
				height: '36px',
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				marginRight: '10px',
				borderRadius: '4px',
			},
		},
		MuiTableHead: {
			root: {
				height: '50px',
				'& > thead': {
					borderRadius: '2px',
				},
			},
		},
		MUIDataTableHead: {
			responsiveStacked: {
				height: '50px',
			},
			main: {
				height: '50px',
			},
		},
		MUIDataTableHeadCell: {
			fixedHeader: {
				backgroundColor: '#F7F9FA',
			},
			data: {
				fontSize: '9px',
				fontFamily: 'Manrope-extrabold',
				textTransform: 'uppercase',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
		},
		MuiTableBody: {
			root: {
				'& > tr': {
					height: '30px',
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: '10px',
				fontFamily: 'Manrope-medium',
				padding: '8px',
			},

			head: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: '#F7F9FA',
			},
		},
		MUIDataTableBodyCell: {
			root: {
				width: '113px',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				fontFamily: 'Manrope-medium',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				height: '50px',
			},
		},
		MUIDataTableFilter: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Manrope-medium',
				width: '545px',
				height: '600px',
			},
		},
		MuiTableRow: {
			root: {
				backgroundColor: '#ffffff',
				'&:focus-within': {
					backgroundColor: '#E1EEFF',
				},
			},
		},
		MUIDataTableFilterList: {
			chip: {
				margin: '0px 5px 0px 0px',
			},
		},
	},
});
const dataGridStyle = makeStyles(() => ({
	heading: {
		fontSize: '14px',
		marginTop: '20px',
		fontFamily: 'Manrope-extrabold',
		textTransform: 'uppercase',
	},
	statusSelect: {
		background: '#ebf3ff',
	},
	tableContainer: {
		marginLeft: '20px',
		maxHeight: '200px',
	},
	table: {
		minWidth: '650',
	},
	tr: {
		background: '#FFFFFF',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	exportButton: {
		minWidth: '60px',
		height: '36px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	toolbarContainer: {
		display: 'flex',
		margin: '10px 0px',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid #E0E0E0',
		padding: '6px 10px',
		borderRadius: '4px',
		fontSize: '14px',
		fontFamily: 'Manrope-semibold',
	},
}));
const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '90px',
	},
	navigationTabContainer: {
		width: '610px',
		height: '36px',
		display: 'flex',
		marginTop: '10px',
	},
	prefixName: {
		fontSize: '12px',
		width: 'auto',
		fontFamily: 'Manrope-medium',
		fontWeight: 'bold',
		color: 'grey',
		opacity: '0.8',
	},
	projectName: {
		fontSize: '12px',
		maxWidth: '100px',
		fontFamily: 'Manrope-medium',
		opacity: '0.8',
		color: '#292929',
	},
	resourceCount: {
		fontSize: '12px',
		width: '50px',
		textAlign: 'center',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},

	companyDropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '4px',
	},
	resourceCountContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	budget: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
	},
	startDate: {
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		color: '#292929',
		width: '80px',
	},
	filterButton: {
		marginRight: '11px',
		width: '89px',
		height: '36px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		opacity: 1,
		fontSize: '12px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		textTransform: 'none',
	},
	addButton: {
		'&:hover': {
			background: '#4285F4',
		},
		width: '125px',
		height: '36px',
		fontSize: '12px',
		background: ' #4285F4 0% 0% no-repeat padding-box',
		color: '#FFFFFF',
		textTransform: 'capitalize',
		fontFamily: 'Manrope-Bold',
		marginRight: '10px',
	},
	exportIconContainer: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		color: '#4285F4',
		width: '36px',
		height: '36px',
		marginRight: '10px',
	},
	exportIcon: {
		width: '20px',
		height: '20px',
	},
	pageTitle: {
		fontFamily: 'Manrope-extrabold',
		marginTop: '20px',
		textTransform: 'uppercase',
		fontSize: '14px',
		marginBottom: '12px',
	},
	listStyle: {
		marginTop: '-50px',
		whiteSpace: 'nowrap',
	},
	statusContainer: {
		background: '#EBF3FF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		borderRadius: '4px',
		paddingLeft: '10px',
		fontSize: '12px',
		fontFamily: 'Manrope-medium',
		width: '80px',
	},
	filterStyle: {
		marginTop: '-72px',
		marginBottom: '5px',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
	},
	percentage: {
		marginLeft: '10px',
	},
	companyDropListCount: {
		marginTop: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
	},
	completed: {
		backgroundColor: '#E9F7EC',
		'& span': {
			color: '#34A853',
			maxWidth: '150px',
		},
	},
	lost: {
		backgroundColor: '#9b99995c',
		'& span': {
			color: '#5e5e5e',
			maxWidth: '150px',
		},
	},
	inProgress: {
		backgroundColor: '#EBF3FF',
		'& span': {
			color: '#4285F4',
			maxWidth: '150px',
		},
	},
	pending: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	notStarted: {
		backgroundColor: '#FFF3F2',
		'& span': {
			color: '#EA4335',
			maxWidth: '150px',
		},
	},
	riskGreen: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#388E3C',
		borderRadius: '50%',
	},
	riskRed: {
		display: 'block',
		width: '20px',
		height: '20px',
		backgroundColor: '#ef5350',
		borderRadius: '50%',
	},
	riskAmber: {
		backgroundColor: '#ffbf006e',
		'& span': {
			color: '#ff8f00',
		},
	},
	editButtonContainer: {
		position: 'absolute',
		right: '6px',
		top: '6px',
		opacity: 1,
		borderRadius: '100px',
	},
	editButton: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #E0E0E0',
		opacity: 1,
		width: '24px',
		height: '24px',
		borderRadius: '100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover ': {
			background: '#4285F4',
			'& $editIcon': {
				color: '#FFFFFF',
			},
		},
	},
	editIcon: {
		width: '12px',
		height: '12px',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
		},
	},
	editText: {
		fontFamily: 'Manrope-SemiBold',
		opacity: 1,
		color: 'black',
		letterSpacing: '0px',
		fontSize: '10px',
		paddingLeft: '5px',
	},
	deleteIcon: {
		color: 'rgb(220, 0, 78)',
		width: '10px',
		height: '10px',
	},
	projectFilterStatus: {
		background: '#ffffff',
		height: '36px',
		marginLeft: '10px',
		alignItems: 'left',
	},
	warranty: {
		fontFamily: 'Manrope-Medium',
		fontSize: '10px !important',
		color: '#888888',
	},
	dateEndProject: {
		color: '#EA4335',
		fontSize: '12px !important',
		fontFamily: 'Manrope',
	},
	leftProjectDate: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
	},
	days: {
		fontFamily: 'Manrope',
		fontSize: '12px !important',
		color: '#292929',
		paddingLeft: '2px',
	},
	expiryFilterStatus: {
		background: '#ffffff',
		height: '36px',
		alignItems: 'left',
		justifyContent: 'flex-start',
	},
	employeesContainer: {
		border: '1px solid #E0E0E0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px 4px',
		width: '76px',
		height: '32px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF',
			boxShadow: '0px 8px 19px #0000000F',
		},
	},
	peoplesIcon: {
		color: '#5C5C5C',
		width: '20px',
		height: '20px',
	},
	clearIcon: {
		width: '18px',
		margin: '0 4px 0 0',
		cursor: 'pointer',
	},
	menuIcon: {
		height: '18px',
		width: '18px',
	},
	copyContainer: {
		'&:hover': {
			'& > svg': {
				opacity: '1',
			},
		},
	},
	copyIcon: {
		opacity: '0',
		height: '16px',
		width: '16px',
		color: '#1d82f5',
		marginLeft: '10px',
	},
	taskButton: {
		borderRight: '1px solid #E0E0E0',
	},
	projectStatus: {
		marginBottom: '4px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	dropDownFilterLabel: {
		fontSize: '9px',
		marginLeft: '12px',
		marginTop: '-12px',
		marginBottom: '-2px',
	},
	listEditIconButton: {
		width: '24px',
		height: '24px',
		padding: '0px',
		background: '#FFFFFF',
		border: '1px solid #E0E0E0',
		color: '#4285F4',
		'&:hover': {
			color: '#FFFFFF',
			background: '#4285F4',
		},
	},
}));

export const ProjectPlanningList = () => {
	const classes = useStyles();
	const styles = ellipsisStyle();
	const dropDownStyles = autocompleteStyle();
	const datagridStyles = datagridStyle();
	const { permissions, filterState, dispatchFilter, featureFlag } =
		useContext(UserProfileContext);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const [isDataLoadiing, setIsDataLoading] = useState(false);
	const [projectId, setProjectId] = useState(null);
	const [isProjectFormModalShown, setIsProjectFormModalShown] = useState(false);
	const [projectDetails, setProjectDetails] = useState({});
	const [projectPlanningStatusOption, setProjectPlanningStatusOPtion] =
		useState([]);
	const [projectStatusId, setProjectStatusId] = useState('');
	const history = useHistory();
	const ellipsis = dropDownEllipsisStyle();
	const formatUrl = window?.location?.href?.replace(/[?]/g, '/').split('/');
	const path = formatUrl[4];
	const [projectListIdForPlanning, setProjectListIdForPlanning] = useState([]);
	const dispatch = useDispatch();
	const [projectPlanningCount, setProjectPlanningCount] = useState(0);
	const [projectPlanningResource, setProjectPlanningResource] = useState([]);
	const [data, setData] = useState([]);
	const dataGridStyles = dataGridStyle();
	const [isBulkProjectFormShown, setIsBulkProjectFormShown] = useState(false);
	const [isBulkAllocationFormShown, setIsBulkAllocationFormShown] =
		useState(false);
	const [selectedRowIds, setSelectedRowIds] = useState([[]]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [selectedRowData, setSelectedRowData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [minProjectStartDate, setMinProjectStartDate] = useState(null);

	const getProjectId = (projectId) => {
		setProjectId(projectId);
		setIsProjectFormModalShown(true);
	};

	const prevPageRef = useRef(null);
	const columns = [
		{
			name: 'id',
			label: 'id',
			options: {
				display: false,
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'name',
			label: 'Name',
			options: {
				filter: true,
				viewColumns: false,
			},
		},
		{
			name: 'startDate',
			label: 'Start Date',
			options: {
				filter: true,
				viewColumns: false,
			},
		},
		{
			name: 'endDate',
			label: 'End Date',
			options: {
				filter: true,
				viewColumns: false,
			},
		},
		{
			name: 'projectOwner',
			label: 'Project Owner',
			sort: false,
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'status',
			label: 'Status',
			sort: false,
			options: {
				filter: false,
				viewColumns: false,
			},
		},
		{
			name: 'id',
			label: 'Actions',
			sort: false,
			options: {
				filter: false,
				customBodyRender: (value) => {
					return (
						selectedRowIds[page] &&
						selectedRowIds[page].length === 0 && (
							<Box
								onClick={(e) => {
									e.stopPropagation();
									getProjectId(value);
								}}
								className={datagridStyles.listEditIconContainer}
							>
								<IconButton className={classes.listEditIconButton}>
									<EditIcon className={datagridStyles.editIcon} />
								</IconButton>
							</Box>
						)
					);
				},
			},
		},
	];
	const getProjectExpiryFilter = () => {
		if (filterState?.projectPlanningExpiryFilter) {
			if (filterState?.projectPlanningExpiryFilter === EXPIRED_PROJECTS) {
				return { _lt: 'today()' };
			}
			if (filterState?.projectPlanningExpiryFilter === ALL_PROJECTS) {
				return {};
			}
			return {
				_gte: 'today()',
				_lte: dayjs().add(10, 'day').format('YYYY-MM-DD'),
			};
		}
		return {};
	};

	const allProjectPlanningResourceSelected =
		projectPlanningResource?.length ===
			filterState?.projectPlanningResourceFilter?.length || 0
			? true
			: false;

	const projectFilterWithResourceMappingForProjectPlanning = {
		project_resource_mappings: {
			employee_id:
				filterState?.projectPlanningResourceFilter &&
				filterState?.projectPlanningResourceFilter?.length > 0
					? {
							_in: filterState?.projectPlanningResourceFilter.map(
								(resource) => resource.value
							),
					  }
					: {},
		},
		_or: [
			{
				name: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
			{
				prefix: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
		],
		project_status_id:
			filterState?.projectPlanningStatusFilter || projectStatusId
				? {
						_eq: filterState?.projectPlanningStatusFilter || projectStatusId,
				  }
				: {},
		end_date: getProjectExpiryFilter(),
	};

	const projectFilterWithoutResourceMappingForProjectPlanning = {
		_or: [
			{
				name: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
			{
				prefix: {
					_ilike: `%${filterState?.projectPlanningSearchText || ''}%`,
				},
			},
		],
		project_status_id:
			filterState?.projectPlanningStatusFilter || projectStatusId
				? {
						_eq: filterState?.projectPlanningStatusFilter || projectStatusId,
				  }
				: {},
		end_date: getProjectExpiryFilter(),
	};

	const {
		data: filteredProjectsForProjectPlanning,
		loading: isfilteredProjectsForProjectPlanningLoading,
		refetch: refetchFilteredProjectsForProjectPlanning,
	} = useApolloQuery(GET_PROJECT_EXPORT_FILTER, {
		variables: {
			project:
				filterState?.projectPlanningResourceFilter &&
				filterState?.projectPlanningResourceFilter?.length > 0
					? projectFilterWithResourceMappingForProjectPlanning
					: projectFilterWithoutResourceMappingForProjectPlanning,
		},
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (filteredProjectsForProjectPlanning?.project?.length === 0) {
			setProjectListIdForPlanning([]);
			return;
		}

		const response = filteredProjectsForProjectPlanning?.project?.map(
			(projectList) => {
				return projectList.id;
			}
		);

		setProjectListIdForPlanning(response);
	}, [filteredProjectsForProjectPlanning]);

	const { data: project, refetch: refetchProject } = useApolloQuery(
		GET_PROJECT,
		{
			variables: {
				projectId: projectId || null,
			},
		}
	);
	const { data: projectTimesheetEntries } =
		useGetProjectTimesheetEntryCountQuery({
			variables: {
				projectId: projectId || null,
			},
		});
	const { data: projectStatusList, refetch: refetchProjectStatus } =
		useGetProjectStatusInProjectListQuery({
			fetchPolicy: 'network-only',
		});

	const { data: projectPlanningresourceList } = useApolloQuery(
		GET_PROJECT_RESOURCES,
		{
			skip: path === 'project',
		}
	);

	const { data: userTeams } = useApolloQuery(GET_USER_TEAMS, {
		fetchPolicy: 'network-only',
	});

	const { data: projectPlanningUsersByTeam } = useApolloQuery(
		GET_USER_BY_TEAMS,
		{
			variables: {
				teamId: !filterState?.projectPlanningUserTeamFilter
					? {}
					: {
							_eq: filterState?.projectPlanningUserTeamFilter?.id,
					  },
			},
			skip: !filterState?.projectPlanningUserTeamFilter,
			fetchPolicy: 'network-only',
		}
	);
	const updateSelectedRowsData = (rowData) => {
		if (!rowData) {
			return;
		}
		const data = rowData?.project.map((project) => {
			return {
				id: project?.id,
				name: project?.name,
				startDate: project?.start_date,
				endDate: project?.end_date,
				projectOwner: project?.user?.full_name || '--',
				status: 'INPROGRESS',
			};
		});
		if (page !== prevPageRef.current) {
			const displayData = data;
			let selectedRows =
				selectedRowIds[page] &&
				selectedRowIds[page].length > 0 &&
				displayData.length > 0
					? selectedRowIds[page].map((rowIndex) => {
							return displayData[rowIndex];
					  })
					: [];
			setSelectedRowData(selectedRows);
			prevPageRef.current = page;
		}
	};
	// project table query
	const {
		data: filteredProjects,
		loading: isFilteredProjectLoading,
		refetch: refetchFilteredProjects,
	} = useGetProjectDataGridListsQuery({
		variables: {
			project: {
				id: { _in: projectListIdForPlanning },
			},
			limit: rowsPerPage,
			offset: page * rowsPerPage,
			sortFilter: { name: 'asc' },
		},
		onCompleted: (projectData) => updateSelectedRowsData(projectData),
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (!projectPlanningUsersByTeam) {
			return;
		}
		const useTeamAssignees = projectPlanningUsersByTeam?.user_team_mapping?.map(
			(user) => {
				return {
					value: user?.user?.id,
					label: user?.user?.full_name,
				};
			}
		);
		const sortedAssigneeList = orderBy(
			useTeamAssignees,
			[(user) => user.name?.toUpperCase()],
			['asc']
		);
		setProjectPlanningResource(sortedAssigneeList);
		if (filterState?.projectPlanningUserTeamFilter) {
			const availableResourceIdList = sortedAssigneeList.map(
				(option) => option.value
			);
			const selectedResources =
				filterState?.projectPlanningResourceFilter?.filter((option) =>
					availableResourceIdList.includes(option.value)
				);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: selectedResources,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: [],
			});
		}
		// eslint-disable-next-line
	}, [
		dispatchFilter,
		filterState.projectPlanningUserTeamFilter,
		projectPlanningUsersByTeam,
	]);

	// Handle the user team dropdown logic in project planning when filters are retained
	useEffect(() => {
		if (!userTeams) {
			return;
		}
		// check if retained team is available in the option / deleted
		if (filterState?.projectPlanningUserTeamFilter) {
			const availableUserTeamList = userTeams?.user_team?.map(
				(team) => team?.id
			);
			if (
				availableUserTeamList?.includes(
					filterState?.projectPlanningUserTeamFilter?.id
				)
			) {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
					payload: filterState?.projectPlanningUserTeamFilter,
				});
			} else {
				// If not available clear the retained team values and resource values
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
					payload: null,
				});
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
					payload: [],
				});
			}
		} else {
			// If not available clear the retained team values and resource values
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
				payload: null,
			});
		}
		// eslint-disable-next-line
	}, [dispatchFilter, userTeams]);

	useEffect(() => {
		if (
			!projectPlanningresourceList ||
			isEmpty(projectPlanningresourceList?.project_resource_mapping)
		) {
			setProjectPlanningResource([]);
			return;
		}
		if (filterState?.projectPlanningUserTeamFilter) {
			const useTeamAssignees =
				projectPlanningUsersByTeam?.user_team_mapping?.map((user) => {
					return {
						value: user?.user?.id,
						label: user?.user?.full_name,
					};
				});
			const sortedAssigneeList = orderBy(useTeamAssignees, ['label'], ['asc']);
			setProjectPlanningResource(sortedAssigneeList);
			return;
		}
		const response = projectPlanningresourceList?.project_resource_mapping?.map(
			(userList) => {
				return {
					value: userList?.user?.id,
					label: `${userList?.user?.full_name} `,
					projectId: userList?.project_id,
				};
			}
		);
		const sortedResponse = orderBy(
			response,
			[(user) => user.label?.toUpperCase()],
			['asc']
		);
		setProjectPlanningResource(sortedResponse);
	}, [filterState, projectPlanningUsersByTeam, projectPlanningresourceList]);

	useEffect(() => {
		const statusListInfo = projectStatusList?.project_list?.map((value) => {
			if (value?.ProjectStatus?.value === 'in_progress') {
				setProjectStatusId(value?.ProjectStatus?.id);
			}
			return {
				id: value?.ProjectStatus?.id,
				name: value?.ProjectStatus?.label,
			};
		});
		const sortedStatusListInfo = orderBy(
			statusListInfo,
			[(status) => status.name.toUpperCase()],
			['asc']
		);
		setProjectPlanningStatusOPtion(sortedStatusListInfo);
	}, [projectStatusList, dispatchFilter]);

	useEffect(() => {
		if (!project || !project?.project_by_pk) {
			return;
		}
		setProjectDetails({
			name: project.project_by_pk?.name,
			revenue: project.project_by_pk?.revenue,
			project_type: project.project_by_pk?.project_type,
			start_date: project.project_by_pk?.start_date,
			end_date: project.project_by_pk?.end_date,
			project_owner_id: project.project_by_pk?.project_owner_id,
			pre_sales_owner_id: project.project_by_pk?.pre_sales_owner_id,
			is_strict: project.project_by_pk?.is_strict,
			kanban_id: project.project_by_pk?.kanban_id,
			is_billable: project.project_by_pk?.is_billable,
			currency_id: project.project_by_pk?.currency_id,
			total_cost: project.project_by_pk?.total_cost,
			effort_estimate: project.project_by_pk?.effort_estimate,
			risk_status: project.project_by_pk?.risk_status,
			project_execution_type: project.project_by_pk?.project_execution_type,
			company_id: project.project_by_pk?.company_id,
			geo_id: project.project_by_pk?.geo_id,
			id: project.project_by_pk?.id,
			prefix: project.project_by_pk?.prefix,
			project_status_id: project.project_by_pk?.project_status_id,
			description: project.project_by_pk?.description,
			isCardsPresent:
				!!project?.project_by_pk?.kanban_board?.kanban_columns.find(
					(elem) => elem.project_kanban_cards_aggregate.aggregate.count > 0
				),
			tag_id: project.project_by_pk?.tag_id,
			category_id: project.project_by_pk?.category_id,
		});
	}, [project]);

	const onEditRowClick = (projectId) => {
		if (path === 'resource-planner') {
			if (permissions?.project?.update_permissions)
				history.push(`resource-planner/${projectId}`);
		} else return 'edit';
	};
	const getProjectPlanningResourceFilterLimits = (value) => (
		<div className={`${dropDownStyles.dropdownValue} ${styles.ellipsis}`}>
			{filterState?.projectPlanningResourceFilter?.length === 1
				? filterState?.projectPlanningResourceFilter[0].label
				: `Selected ${
						filterState?.projectPlanningResourceFilter?.length || 0
				  } items`}
		</div>
	);
	useEffect(() => {
		dispatch(setSidebarVisibility(false));
	}, [dispatch]);

	const filter = createFilterOptions();

	const handleSelectProjectPlanningResourceAll = (isSelected) => {
		if (isSelected) {
			if (filterState?.projectPlanningUserTeamFilter) {
				const useTeamAssignees =
					projectPlanningUsersByTeam?.user_team_mapping?.map((user) => {
						return {
							value: user?.user?.id,
							label: user?.user?.full_name,
						};
					});
				const sortedAssigneeList = orderBy(
					useTeamAssignees,
					[(user) => user.name?.toUpperCase()],
					['asc']
				);
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
					payload: sortedAssigneeList,
				});
				return;
			}
			const response =
				projectPlanningresourceList?.project_resource_mapping?.map(
					(userList) => {
						return {
							value: userList?.user?.id,
							label: `${userList?.user?.full_name} `,
							projectId: userList?.project_id,
						};
					}
				);
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: response,
			});
		} else {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: [],
			});
		}
	};
	const handleToggleProjectPlanningResource = () => {
		handleSelectProjectPlanningResourceAll(!allProjectPlanningResourceSelected);
	};

	const onProjectPlanningResourceChange = (event, value, reason) => {
		if (!value) {
			return;
		}
		setPage(0);
		clearSelectedRowsAndData();
		if (reason === 'select-option' || reason === 'remove-option') {
			if (value.find((option) => option.value === 'All')) {
				handleToggleProjectPlanningResource();
				return;
			} else {
				dispatchFilter({
					type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
					payload: value,
				});
				return;
			}
		} else if (reason === 'clear') {
			dispatchFilter({
				type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
				payload: [],
			});
			return;
		}
	};
	const resetPlanningTeamResourceFilter = () => {
		if (
			!projectPlanningresourceList ||
			isEmpty(projectPlanningresourceList?.project_resource_mapping)
		) {
			setProjectPlanningResource([]);
			return;
		}
		const response = projectPlanningresourceList?.project_resource_mapping?.map(
			(userList) => {
				return {
					value: userList?.user?.id,
					label: `${userList?.user?.full_name} `,
					projectId: userList?.project_id,
				};
			}
		);
		let sortedResponse = sortBy(response, ['label']);
		setProjectPlanningResource(sortedResponse);
	};

	const clearSelectedRowsAndData = () => {
		setSelectedRowData([]);
		setSelectedRowIds([[]]);
	};
	useEffect(() => {
		const datesArray = selectedRowData.map((project) => project?.startDate);
		const sortedDatesAsc = orderBy(
			datesArray,
			[(date) => new Date(date)],
			['asc']
		);
		setMinProjectStartDate(sortedDatesAsc[0] || null);
	}, [selectedRowData]);

	useEffect(() => {
		if (!filteredProjects?.project) return;
		setIsDataLoading(true);
		const data = filteredProjects?.project.map((project) => {
			return {
				id: project?.id,
				name: project?.name || '--',
				startDate: project?.start_date || '--',
				endDate: project?.end_date || '--',
				projectOwner: project?.user?.full_name || '--',
				status: project?.ProjectStatus?.label || '--',
			};
		});
		setProjectPlanningCount(
			filteredProjects?.project_aggregate?.aggregate?.count || 0
		);
		setData(data);
		setIsDataLoading(false);
	}, [filteredProjects]);

	const getProjects = () => {
		if (!filteredProjects?.project) return;

		const data = filteredProjects?.project.map((project) => {
			return {
				id: project?.id,
				name: project?.name || '--',
				startDate: project?.start_date || '--',
				endDate: project?.end_date || '--',
				projectOwner: project?.user?.full_name || '--',
				status: project?.ProjectStatus?.label || '--',
			};
		});
		return data;
	};
	const updateSelectedRowIds = (
		currentlySelectedRowIds,
		pageNumber,
		selectedRowIdsForCurrentPage
	) => {
		const newSelectedRowIds = currentlySelectedRowIds.slice();
		while (newSelectedRowIds.length <= pageNumber) {
			newSelectedRowIds.push([]);
		}
		newSelectedRowIds[pageNumber] = selectedRowIdsForCurrentPage;

		return newSelectedRowIds;
	};
	const handleRowSelect = (
		currentRowsSelected,
		allRowsSelected,
		rowsSelectedData
	) => {
		const selected = allRowsSelected.map((item) => item.index);
		setSelectedRowIds((prev) => {
			const result = updateSelectedRowIds(prev, page, selected);
			return result;
		});
		const displayData = getProjects();
		if (displayData) {
			let selectedRows = rowsSelectedData.map((dataIndex) => {
				return displayData[dataIndex];
			});
			setSelectedRowData(selectedRows);
		}
	};
	const options = {
		filter: false,
		search: false,
		selectableRowsHeader: true,
		selectableRowsHideCheckboxes:
			isfilteredProjectsForProjectPlanningLoading ||
			isFilteredProjectLoading ||
			isDataLoadiing
				? true
				: false,
		rowsSelected:
			selectedRowIds[page] && selectedRowIds[page].length > 0
				? selectedRowIds[page]
				: [],
		print: false,
		rowsPerPage: rowsPerPage,
		rowsPerPageOptions: [10, 20, 30],
		page: page,
		serverSide: true,
		viewColumns: false,
		download: false,
		count: filteredProjects?.project_aggregate?.aggregate?.count || 0,

		textLabels: {
			body: {
				noMatch:
					isFilteredProjectLoading ||
					isDataLoadiing ||
					isfilteredProjectsForProjectPlanningLoading ? (
						<Loader />
					) : (
						'No data found'
					),
			},
		},
		onChangeRowsPerPage: (numberOfRows) => {
			setPage(0);
			setRowsPerPage(numberOfRows);
			clearSelectedRowsAndData();
		},
		onRowClick: (row, rowMeta) => {
			if (row[0]) {
				onEditRowClick(row[0]);
			}
		},
		onRowSelectionChange: (
			currentRowsSelected,
			allRowsSelected,
			rowsSelected
		) => {
			handleRowSelect(currentRowsSelected, allRowsSelected, rowsSelected);
		},
		onTableChange: (action, tableState) => {
			switch (action) {
				case 'changePage': {
					setPage(tableState.page);
					break;
				}
				case 'sort': {
					clearSelectedRowsAndData();
					break;
				}
				default:
					return;
			}
		},
	};
	function customToolbar() {
		return (
			<div className={dataGridStyles.toolbarContainer}>
				<>
					<p>{`${selectedRowIds[page].length} Row(s) selected`}</p>
				</>
				<Button
					className={dataGridStyles.exportButton}
					onClick={() => {
						setIsBulkProjectFormShown(true);
					}}
				>
					Edit
				</Button>
			</div>
		);
	}
	return (
		<>
			<>
				<Box display='flex'>
					<Typography className={classes.pageTitle}>
						Projects({projectPlanningCount || 0})
					</Typography>
					{(isfilteredProjectsForProjectPlanningLoading ||
						isFilteredProjectLoading ||
						isDataLoadiing) && (
						<Box marginLeft='10px' marginTop='20px'>
							<CircularProgress size={20} />
						</Box>
					)}
				</Box>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					marginTop='10px'
				>
					<Box display='flex' alignItems='center'>
						<form onSubmit={(event) => event.preventDefault()}>
							<ThemeProvider theme={searchBarTheme}>
								<Box width='344px'>
									<TextComponent
										placeholder='Search Projects'
										label={false}
										fullWidth
										InputLabelProps={{ style: { fontSize: 0 } }}
										InputProps={{
											startAdornment: <SearchIcon />,
										}}
										onChange={(e) => {
											setPage(0);
											clearSelectedRowsAndData();
											dispatchFilter({
												type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_SEARCH_TEXT,
												payload: e?.target?.value,
											});
										}}
										value={filterState?.projectPlanningSearchText || ''}
									/>
								</Box>
							</ThemeProvider>
						</form>
						<Box className={classes.projectFilterStatus}>
							<Form
								onSubmit={() => {}}
								initialValues={{
									project_status:
										filterState?.projectPlanningStatusFilter || projectStatusId,
								}}
							>
								{() => (
									<ThemeProvider theme={modalFormTheme}>
										<form>
											<SelectInput
												source={'project_status'}
												label={''}
												onChange={(event) => {
													setPage(0);
													clearSelectedRowsAndData();
													dispatchFilter({
														type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_STATUS_FILTER,
														payload: event?.target?.value,
													});
												}}
												choices={projectPlanningStatusOption}
											/>
										</form>
									</ThemeProvider>
								)}
							</Form>
						</Box>
						{featureFlag?.userTeams && (
							<Box className={classes.projectFilterStatus}>
								<Form onSubmit={() => {}}>
									{() => (
										<Box display='flex'>
											<Box width='200px' marginRight='5px'>
												<Box className={classes.dropDownFilterLabel}>
													User Team
												</Box>

												<form>
													<AutocompleteSearch
														placeholder={'Search Team'}
														option={
															userTeams && userTeams?.user_team
																? userTeams?.user_team?.map((userTeam) => {
																		return {
																			id: userTeam?.id,
																			name: userTeam?.name,
																		};
																  }) || []
																: []
														}
														onChange={(id) => {
															if (!id) {
																resetPlanningTeamResourceFilter();
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
																	payload: null,
																});
																dispatchFilter({
																	type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
																	payload: [],
																});
																return;
															}
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_RESOURCE_FILTER,
																payload: [],
															});
															const currentTeam = userTeams?.user_team?.find(
																(team) => team?.id === id
															);
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_USER_TEAM_FILTER,
																payload: currentTeam,
															});
														}}
														name={'team_id'}
														value={
															filterState?.projectPlanningUserTeamFilter
																? filterState?.projectPlanningUserTeamFilter
																: []
														}
													/>
												</form>
											</Box>
											<Box width='200px' marginLeft='10px'>
												<Box className={classes.dropDownFilterLabel}>
													Resource
												</Box>
												<form>
													<Autocomplete
														multiple
														limitTags={0}
														value={
															filterState?.projectPlanningResourceFilter
																? filterState?.projectPlanningResourceFilter
																: []
														}
														selectOnFocus={true}
														disablePortal
														fullWidth={false}
														onChange={onProjectPlanningResourceChange}
														options={
															projectPlanningResource.length > 0
																? projectPlanningResource
																: []
														}
														disableCloseOnSelect={true}
														getLimitTagsText={
															getProjectPlanningResourceFilterLimits
														}
														ChipProps={{ style: { display: 'none' } }}
														filterOptions={(options, params) => {
															const filtered = filter(options, params);
															return [
																{ label: 'All', value: 'All' },
																...filtered,
															];
														}}
														renderInput={(params) => (
															<ThemeProvider
																theme={customAutoCompleteSearchBarTheme}
															>
																<TextComponent
																	{...params}
																	label=''
																	placeholder={
																		filterState?.projectPlanningResourceFilter &&
																		filterState?.projectPlanningResourceFilter
																			?.length > 0
																			? ''
																			: 'Search Resource'
																	}
																/>
															</ThemeProvider>
														)}
														renderOption={(option, { selected }) => {
															const selectAllProps =
																option.value === 'All' // To control the state of 'select-all' checkbox
																	? {
																			checked:
																				allProjectPlanningResourceSelected,
																	  }
																	: {};
															return (
																<>
																	<Checkbox
																		icon={
																			<CheckBoxOutlineBlankIcon fontSize='small' />
																		}
																		checkedIcon={
																			<CheckBoxIcon fontSize='small' />
																		}
																		style={{ marginRight: 8 }}
																		checked={selected || false}
																		color='primary'
																		{...selectAllProps}
																	/>
																	<Tooltip
																		title={`${option?.label || '- -'}`}
																		placement='right'
																	>
																		<Typography
																			className={`${ellipsis.ellipsis}`}
																		>
																			{`${option?.label || '- -'}`}
																		</Typography>
																	</Tooltip>
																</>
															);
														}}
														getOptionLabel={(option) => `${option?.label}`}
														getOptionSelected={(option, value) =>
															option.value === value.value
														}
													/>
												</form>
											</Box>
										</Box>
									)}
								</Form>
							</Box>
						)}
						<Box width='200px' marginLeft='5px'>
							<Box className={classes.dropDownFilterLabel}>Expiry</Box>
							<ThemeProvider theme={searchBarTheme}>
								<Box className={classes.expiryFilterStatus}>
									<Form
										onSubmit={() => {}}
										initialValues={{
											expiry: filterState?.projectPlanningExpiryFilter
												? filterState?.projectPlanningExpiryFilter
												: ALL_PROJECTS,
										}}
									>
										{() => (
											<ThemeProvider theme={modalFormTheme}>
												<form>
													<SelectInput
														label={''}
														source='expiry'
														onChange={(event) => {
															setPage(0);
															clearSelectedRowsAndData();
															dispatchFilter({
																type: FILTER_STATE_ACTIONS.UPDATE_PROJECT_PLANNING_EXPIRY_FILTER,
																payload: event?.target?.value,
															});
														}}
														choices={projectFilterOptions}
													/>
												</form>
											</ThemeProvider>
										)}
									</Form>
								</Box>
							</ThemeProvider>
						</Box>
					</Box>
					{/* Todo: Need to implement filters */}
					{/* <Box>
						<Button
							variant='outlined'
							className={classes.filterButton}
							startIcon={<FilterListIcon />}
						>
							Filters
						</Button>
						{permissions?.project?.insert_permissions && (
							<Button
								startIcon={<AddIcon />}
								className={classes.addButton}
								onClick={() => {
									setProjectDetails({});
									setIsProjectFormModalShown(true);
									setProjectId('');
								}}
							>
								Add Project
							</Button>
						)}
					</Box> */}
				</Box>
				<>{selectedRowIds[page]?.length > 0 && customToolbar()}</>
				<ThemeProvider theme={customizedDataGridTheme}>
					<>
						<MUIDataTable
							title={'Project'}
							data={data}
							columns={columns}
							options={options}
						/>
					</>
				</ThemeProvider>
				<ProjectForm
					open={isProjectFormModalShown}
					onClose={() => setIsProjectFormModalShown(false)}
					project={projectDetails}
					refetch={() => {
						refetchProject();
						refetchProjectStatus();
						refetchFilteredProjectsForProjectPlanning();
					}}
					projectId={projectId}
					isBillableDisabled={
						projectDetails.id &&
						projectTimesheetEntries?.project_by_pk?.timesheets_aggregate
							?.aggregate?.count > 0
					}
				/>
				<DeleteModal
					open={isDeleteModalShown}
					onClose={() => setIsDeleteModalShown(false)}
					confirmationMessage='You are about to permanently delete the Project and all of its associated data including tasks, notes and documents.Please confirm deletion'
				/>
				<ProjectEndDateForm
					open={isBulkProjectFormShown}
					onClose={() => {
						setIsBulkProjectFormShown(false);
					}}
					selectedProjectDetails={selectedRowData}
					projectIds={selectedRowData.map((project) => project.id) || []}
					minProjectStartDate={minProjectStartDate}
					onSuccess={async () => {
						setIsBulkProjectFormShown(false);
						setIsBulkAllocationFormShown(true);
						refetchFilteredProjects();
					}}
					onDateChange={(value) => setSelectedDate(value)}
				/>
				<ResourceDateForm
					open={isBulkAllocationFormShown}
					onClose={() => {
						setIsBulkAllocationFormShown(false);
					}}
					projectIds={selectedRowData.map((project) => project.id) || []}
					onSuccess={async () => {
						refetchFilteredProjects();
						clearSelectedRowsAndData();
					}}
					selectedDate={selectedDate}
				/>
			</>
		</>
	);
};
