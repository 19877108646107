import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	withStyles,
	IconButton,
	InputAdornment,
	ThemeProvider,
	Tooltip,
	makeStyles,
	Checkbox,
	Card,
	CardContent,
	TableContainer,
	Table,
	TableHead,
	CircularProgress,
	TableRow,
	Typography,
	TableBody,
	TextField as TextComponent,
	TablePagination,
	CardHeader,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { UserProfileContext } from '../../../App';
import {
	customAutoCompleteSearchBarTheme,
	insightChartTheme,
	modalFormTheme,
} from '../../../Layout/Theme.component';
import {
	modalFormStyle,
	InsightReportTableGlobalStyles,
	dropDownEllipsisStyle,
	autocompleteStyle,
} from '../../../Layout/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DateFnsUtils from '@date-io/dayjs';
import EventIcon from '@material-ui/icons/Event';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { head, isEmpty, orderBy } from 'lodash';
import { CustomTableCell } from '../../../SharedComponents/CustomTableCell.component';
import {
	useGetAttendanceStatusQuery,
	useGetActiveUsersByLimitQuery,
	useGetAttendanceRequestForApprovalQuery,
} from '../../../generated/graphql';
import dayjs from 'dayjs';
import {
	formatEmployeeId,
	exportToCsv,
	getPrefix,
} from '../../../Utils/string.util';
import {
	START_DATE_ERROR,
	APPLIED_ATTENDANCE_REQUEST_REPORT_EXPORT,
} from '../Constant';
import { dateTimePickerTheme } from '../Style';
import ExportButtonWithLoader from '../../../SharedComponents/ExportButton/ExportButtonWithLoader';

const ErrorTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#EA4335',
		color: '#FFFFFF',
		fontFamily: 'Manrope-medium',
		fontSize: 11,
	},
	arrow: {
		color: '#EA4335',
	},
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
	cardHeader: {
		marginLeft: '20px',
	},
	dropDownValue: {
		marginBottom: '7px',
		color: '#4e4e4e',
		marginLeft: '10px',
		width: '120px',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		marginLeft: '17px',
		marginTop: '10px',
	},
	autoCompleteInputContainer: {
		minWidth: '170px',
		marginRight: '8px',
	},
	exportButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: '#4285F4 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	disabledButton: {
		marginTop: '24px',
		marginRight: '10px',
		marginLeft: '10px',
		minWidth: '60px',
		height: '34px',
		background: 'lightgrey 0% 0% no-repeat padding-box',
		boxShadow: '0px 4px 12px #4285F43B',
		borderRadius: '4px',
		font: 'normal normal bold 12px/37px Manrope',
		letterSpacing: '0px',
		color: '#FFFFFF',
		textTransform: 'none',
		'&:hover': {
			background: '#4285F4',
		},
	},
	exportButtonDiv: {
		marginTop: '25px',
		marginLeft: '8px',
	},
	noData: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '50px',
	},
	dataCell: {
		fontSize: '10px',
		fontFamily: 'Manrope-semibold',
		color: '#4E4E4E',
		width: '75px',
	},
	loading: {
		fontSize: '17px',
		fontFamily: 'Manrope-medium',
		marginRight: '5px',
	},
	startDate: {
		width: '188px',
		marginLeft: '20px',
		marginTop: '14px',
	},
	endDate: {
		width: '188px',
		marginTop: '14px',
	},
	tableContainer: {
		maxHeight: '400px',
		overflowY: 'auto',
		minHeight: '200px',
	},
}));
interface Attendance {
	empId: string;
	resourceName: string | null | undefined;
	date: any;
	userType: string | undefined;
	reason: string;
	status: string | undefined;
	inTime: string | null;
	outTime: string | null;
	email: string;
	userLocation: string;
	approver: string;
	approverEmail: string;
}
interface option {
	id: any;
	name?: String | null | undefined;
}

export const EmployeeAttendanceRequest = () => {
	const style = useStyles();
	const classes = useStyles();
	const commonStyle = InsightReportTableGlobalStyles();
	const styles = modalFormStyle();
	const ellipsis = dropDownEllipsisStyle();
	const dropDownStyle = autocompleteStyle();

	const { dateFormat, empIdLength, isFixedEmpIdLengthEnabled } =
		useContext<any>(UserProfileContext);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(7, 'days').day(0).format('YYYY-MM-DD')
	);
	const [endDate, setEndDate] = useState(
		dayjs().subtract(7, 'days').day(6).format('YYYY-MM-DD')
	);
	const [attendanceTableList, setAttendanceTableList] = useState<
		Attendance[] | undefined
	>();
	const [startDateError, setStartDateError] = useState(false);
	const [selectedResourceOptionValue, setSelectedResourceOptionValues] =
		useState<option[]>([]);
	const [selectedStatusOptionValue, setSelectedStatusOptionValues] = useState<
		option[]
	>([]);
	const [selectedResourceIdList, setSelectedResourceIdList] = useState<
		String[]
	>([]);
	const [selectedAttendanceStatusIdList, setSelectedAttendanceStatusIdList] =
		useState<String[]>([]);
	const [resourceList, setresourceList] = useState<option[]>([]);
	const [AttendanceStatusDropDown, setAttendanceStatusDropDown] = useState<
		option[]
	>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const { data: resource } = useGetActiveUsersByLimitQuery({
		fetchPolicy: 'network-only',
	});

	const { data: AttendanceStatus } = useGetAttendanceStatusQuery({
		fetchPolicy: 'network-only',
	});

	const {
		data: attendanceApprovalRequests,
		loading: isAttendanceRequestLoading,
	} = useGetAttendanceRequestForApprovalQuery({
		variables: {
			where: {
				_and: [
					{
						date:
							startDate && endDate ? { _gte: startDate, _lte: endDate } : {},
					},
				],
				submittedBy: {
					id: {
						_in:
							selectedResourceIdList && selectedResourceIdList.length > 0
								? selectedResourceIdList
								: [],
					},
				},
				attendanceStatus: {
					id: {
						_in:
							selectedAttendanceStatusIdList &&
							selectedAttendanceStatusIdList.length > 0
								? selectedAttendanceStatusIdList
								: [],
					},
				},
			},
		},
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!attendanceApprovalRequests) {
			return;
		}

		const tableData = attendanceApprovalRequests?.user_attendance_request?.map(
			(attendance) => {
				const isEmployee =
					attendance?.submittedBy?.user_type === 'employee' ? true : false;
				const existingEmployeeId =
					attendance?.submittedBy?.employee?.emp_id || '';
				const existingContractorId =
					head(attendance?.submittedBy?.contractors)?.contractor_id || '';
				const formattedContractorId = formatEmployeeId(
					existingContractorId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);

				const formattedEmployeeId = formatEmployeeId(
					existingEmployeeId,
					empIdLength,
					isFixedEmpIdLengthEnabled
				);
				const employeeId = isEmployee
					? attendance?.submittedBy?.employee?.emp_id
						? `${
								getPrefix(
									attendance?.submittedBy?.employee?.employee_prefix?.name,
									attendance?.submittedBy?.employee?.employee_prefix?.name
								) || ''
						  }${formattedEmployeeId || ''}`
						: '--'
					: head(attendance?.submittedBy?.contractors)?.contractor_id
					? `${
							getPrefix(
								head(attendance?.submittedBy?.contractors)?.contractor_prefix
									?.name,
								head(attendance?.submittedBy?.contractors)?.contractor_prefix
									?.name
							) || ''
					  }${
							attendance?.submittedBy?.contractors &&
							head(attendance?.submittedBy?.contractors)?.contractor_id
								? formattedContractorId
								: ''
					  }`
					: '--';
				const userType = isEmployee ? 'Employee' : 'Consultant';
				const reason = attendance?.reason || '--';
				const status = attendance?.attendanceStatus?.label;

				const inTime = attendance?.in_time
					? dayjs(`${attendance.date} ${attendance.in_time}`).format('HH:mm')
					: '--';
				const outTime = attendance?.out_time
					? dayjs(`${attendance.date} ${attendance.out_time}`).format('HH:mm')
					: '--';
				const userLocation = isEmployee
					? attendance?.submittedBy?.employee?.org_location?.name || '--'
					: head(attendance?.submittedBy?.contractors)?.org_location?.name ||
					  '--';

				return {
					empId: employeeId,
					resourceName: attendance?.submittedBy?.full_name,
					email: attendance?.submittedBy?.email,
					userType: userType || '--',
					date: attendance?.date
						? dayjs(attendance?.date).format(dateFormat)
						: '--',
					inTime: inTime,
					outTime: outTime,
					reason: reason || '--',
					status: status,
					approverEmail: attendance?.approvedBy?.email || '--',
					approver: attendance?.approvedBy?.full_name || '--',
					userLocation: userLocation,
				};
			}
		);

		setAttendanceTableList(tableData);
	}, [
		dateFormat,
		attendanceApprovalRequests,
		empIdLength,
		isFixedEmpIdLengthEnabled,
	]);

	useEffect(() => {
		if (!resource) {
			return;
		}
		const response = resource?.user?.map((user) => {
			return {
				id: user?.id,
				name: user?.name,
			};
		});
		const defaultResourceIdList = response?.map(
			(resource: { id: string; name: string | null | undefined }) =>
				resource?.id
		);
		setresourceList(response);
		setSelectedResourceIdList(defaultResourceIdList);
		setSelectedResourceOptionValues([{ id: 'All', name: 'All' }, ...response]);
	}, [resource]);

	useEffect(() => {
		if (isEmpty(AttendanceStatus?.attendance_status)) {
			return;
		}
		const statusOption = AttendanceStatus?.attendance_status
			.filter((element) => element?.value !== 'draft')
			.map((value) => {
				return {
					id: value?.id,
					name: value?.value === 'submitted' ? 'Pending' : value?.label,
				};
			});

		const sortedStatusOption = orderBy(statusOption, ['name'], ['asc']);
		const defaultResourceIdList = sortedStatusOption?.map(
			(status: { id: any; name: string }) => status?.id
		);
		setAttendanceStatusDropDown(sortedStatusOption);
		setSelectedAttendanceStatusIdList(defaultResourceIdList);
		setSelectedStatusOptionValues([
			{ id: 'All', name: 'All' },
			...sortedStatusOption,
		]);
	}, [AttendanceStatus]);

	const getResourceFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedResourceOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const getStatusFilterLimits = (value: number) => {
		if (!value) {
			return;
		}
		const currentSelectedOptions = selectedStatusOptionValue?.filter(
			(option) => option?.id !== 'All'
		);
		return (
			<div className={`${dropDownStyle.dropdownValue} ${ellipsis.ellipsis}`}>
				{currentSelectedOptions && currentSelectedOptions?.length === 1
					? currentSelectedOptions[0].name
					: `Selected ${currentSelectedOptions?.length} items`}
			</div>
		);
	};

	const handleChangePage = (
		event: React.ChangeEvent<{}> | null,
		newPage: number
	) => {
		if (!event) {
			return;
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const generateCSV = () => {
		if (!attendanceTableList) {
			return;
		}

		const exportLeaveData =
			attendanceTableList &&
			attendanceTableList.map((attendanceExport) => {
				return [
					attendanceExport.empId || '--',
					attendanceExport.resourceName || '--',
					attendanceExport.email,
					attendanceExport.userType || '--',
					attendanceExport?.userLocation || '--',
					attendanceExport.date || '--',
					attendanceExport.inTime || '--',
					attendanceExport.outTime || '--',
					attendanceExport.status || '--',
					attendanceExport.reason || '--',
					attendanceExport?.approver || '--',
					attendanceExport?.approverEmail || '--',
				];
			});

		const attendanceRequestReportData = [
			APPLIED_ATTENDANCE_REQUEST_REPORT_EXPORT,
			...exportLeaveData,
		];

		exportToCsv(
			`Employee_Attendance_Report_From_${dayjs(startDate).format(
				dateFormat
			)}_To_${dayjs(endDate).format(dateFormat)}.csv`,
			attendanceRequestReportData
		);
	};

	return (
		<>
			<Box>
				<ThemeProvider theme={insightChartTheme}>
					<Card>
						<CardHeader
							className={classes.cardHeader}
							title='Attendance: Employee Attendance Request'
							titleTypographyProps={{ variant: 'h6' }}
						/>
						<Box display='flex'>
							<Box marginTop={'4px'}>
								<Form onSubmit={() => {}}>
									{() => (
										<ThemeProvider theme={modalFormTheme}>
											<form>
												<Box className={style.header}>
													<Box marginTop={'5px'}>
														<ThemeProvider theme={dateTimePickerTheme}>
															<Box display='flex'>
																<ErrorTooltip
																	title={startDateError ? START_DATE_ERROR : ''}
																	placement='top'
																	arrow={true}
																>
																	<Box className={style.startDate}>
																		<MuiPickersUtilsProvider
																			utils={DateFnsUtils}
																		>
																			<KeyboardDatePicker
																				variant='inline'
																				onChange={(date: any) => {
																					setPage(0);
																					setStartDateError(false);
																					if (
																						dayjs(date).isAfter(dayjs(endDate))
																					) {
																						setStartDateError(true);
																					} else {
																						setStartDateError(false);
																					}
																					setStartDate(
																						dayjs(date).format('YYYY-MM-DD')
																					);
																				}}
																				autoOk={true}
																				value={startDate}
																				format={dateFormat}
																				label='Start date'
																				error={startDateError}
																				InputLabelProps={{ shrink: true }}
																				InputProps={{
																					endAdornment: (
																						<InputAdornment position='start'>
																							<IconButton>
																								<EventIcon />
																							</IconButton>
																						</InputAdornment>
																					),
																				}}
																			/>
																		</MuiPickersUtilsProvider>
																	</Box>
																</ErrorTooltip>
																<Box className={style.endDate}>
																	<MuiPickersUtilsProvider utils={DateFnsUtils}>
																		<KeyboardDatePicker
																			variant='inline'
																			onChange={(date: any) => {
																				setPage(0);
																				setStartDateError(false);
																				if (
																					dayjs(date).isBefore(dayjs(startDate))
																				) {
																					setStartDateError(true);
																				} else {
																					setStartDateError(false);
																				}
																				setEndDate(
																					dayjs(date).format('YYYY-MM-DD')
																				);
																			}}
																			value={endDate}
																			autoOk={true}
																			format={dateFormat}
																			label='End date'
																			InputLabelProps={{ shrink: true }}
																			InputProps={{
																				endAdornment: (
																					<InputAdornment position='end'>
																						<IconButton>
																							<EventIcon />
																						</IconButton>
																					</InputAdornment>
																				),
																			}}
																		/>
																	</MuiPickersUtilsProvider>
																</Box>
															</Box>
														</ThemeProvider>
													</Box>

													<Box display='flex'>
														<Box display='block' width='170px'>
															<Box className={styles.label}>Resource</Box>
															<Box className={style.autoCompleteInputContainer}>
																<Autocomplete
																	multiple
																	limitTags={0}
																	value={
																		selectedResourceOptionValue &&
																		selectedResourceOptionValue?.length > 0
																			? selectedResourceOptionValue
																			: []
																	}
																	selectOnFocus={true}
																	disablePortal
																	fullWidth={false}
																	onChange={(event, value, reason) => {
																		setPage(0);
																		if (!value) {
																			return;
																		}
																		const selectedIds = value
																			?.filter(
																				(resource) => resource.id !== 'All'
																			)
																			.map((resource) => resource?.id);
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'select-option'
																		) {
																			setSelectedResourceOptionValues([
																				{ id: 'All', name: 'All' },
																				...resourceList,
																			]);
																			const allResourceIds = resourceList?.map(
																				(resource) => resource?.id
																			);
																			setSelectedResourceIdList(allResourceIds);
																			return;
																		}
																		if (
																			value.find(
																				(option) => option.id === 'All'
																			) &&
																			reason === 'remove-option' &&
																			resourceList?.length !==
																				selectedIds?.length
																		) {
																			const currentOptions = value?.filter(
																				(resource) => resource?.id !== 'All'
																			);
																			setSelectedResourceOptionValues(
																				currentOptions
																			);
																			const currentIds = currentOptions?.map(
																				(resource) => resource?.id
																			);
																			setSelectedResourceIdList(currentIds);
																			return;
																		}
																		if (
																			selectedIds?.length ===
																				resourceList?.length &&
																			reason === 'select-option'
																		) {
																			setSelectedResourceOptionValues([
																				{ id: 'All', name: 'All' },
																				...resourceList,
																			]);
																			const responseIds = resourceList?.map(
																				(resource) => resource?.id
																			);
																			setSelectedResourceIdList(responseIds);
																			return;
																		}
																		if (
																			selectedResourceOptionValue.find(
																				(option) => option?.id === 'All'
																			) &&
																			reason === 'remove-option'
																		) {
																			setSelectedResourceIdList([]);
																			setSelectedResourceOptionValues([]);

																			return;
																		}
																		const selectedId = value?.map(
																			(resource) => resource?.id
																		);
																		setSelectedResourceIdList(selectedId);
																		setSelectedResourceOptionValues(value);
																	}}
																	options={
																		resourceList && resourceList?.length > 0
																			? [
																					{ id: 'All', name: 'All' },
																					...resourceList,
																			  ]
																			: []
																	}
																	disableCloseOnSelect={true}
																	getLimitTagsText={getResourceFilterLimits}
																	ChipProps={{ style: { display: 'none' } }}
																	renderInput={(params) => (
																		<ThemeProvider
																			theme={customAutoCompleteSearchBarTheme}
																		>
																			<TextComponent {...params} label='' />
																		</ThemeProvider>
																	)}
																	renderOption={(option, { selected }) => (
																		<>
																			<Checkbox
																				icon={
																					<CheckBoxOutlineBlankIcon fontSize='small' />
																				}
																				checkedIcon={
																					<CheckBoxIcon fontSize='small' />
																				}
																				style={{ marginRight: 8 }}
																				checked={selected}
																				color='primary'
																			/>
																			<Tooltip
																				title={`${option?.name || '- -'}`}
																				placement='right'
																			>
																				<Typography
																					className={`${ellipsis.ellipsis}`}
																					style={{ fontSize: '12px' }}
																				>
																					{`${option?.name || '- -'}`}
																				</Typography>
																			</Tooltip>
																		</>
																	)}
																	getOptionLabel={(option) => `${option?.name}`}
																	getOptionSelected={(option, value) =>
																		option.id === value.id
																	}
																/>
															</Box>
														</Box>
													</Box>

													<Box
														display='block'
														width='170px'
														marginLeft={'10px'}
													>
														<Box className={styles.label}> Status</Box>
														<Box className={style.autoCompleteInputContainer}>
															<Autocomplete
																multiple
																limitTags={0}
																value={
																	selectedStatusOptionValue &&
																	selectedStatusOptionValue?.length > 0
																		? selectedStatusOptionValue
																		: []
																}
																selectOnFocus={true}
																disablePortal
																fullWidth={false}
																onChange={(event, value, reason) => {
																	setPage(0);
																	if (!value) {
																		return;
																	}
																	const selectedIds = value
																		?.filter((status) => status.id !== 'All')
																		.map((status) => status?.id);
																	if (
																		value.find(
																			(option) => option.id === 'All'
																		) &&
																		reason === 'select-option'
																	) {
																		setSelectedStatusOptionValues([
																			{ id: 'All', name: 'All' },
																			...AttendanceStatusDropDown,
																		]);
																		const allStatusIds =
																			AttendanceStatusDropDown?.map(
																				(status) => status?.id
																			);
																		setSelectedAttendanceStatusIdList(
																			allStatusIds
																		);
																		return;
																	}
																	if (
																		value.find(
																			(option) => option.id === 'All'
																		) &&
																		reason === 'remove-option' &&
																		AttendanceStatusDropDown?.length !==
																			selectedIds?.length
																	) {
																		const currentOptions = value?.filter(
																			(status) => status?.id !== 'All'
																		);
																		setSelectedStatusOptionValues(
																			currentOptions
																		);
																		const currentIds = currentOptions?.map(
																			(resource) => resource?.id
																		);
																		setSelectedAttendanceStatusIdList(
																			currentIds
																		);
																		return;
																	}
																	if (
																		selectedIds?.length ===
																			AttendanceStatusDropDown?.length &&
																		reason === 'select-option'
																	) {
																		setSelectedStatusOptionValues([
																			{ id: 'All', name: 'All' },
																			...AttendanceStatusDropDown,
																		]);
																		const responseIds =
																			AttendanceStatusDropDown?.map(
																				(status) => status?.id
																			);
																		setSelectedAttendanceStatusIdList(
																			responseIds
																		);
																		return;
																	}
																	if (
																		selectedStatusOptionValue.find(
																			(option) => option?.id === 'All'
																		) &&
																		reason === 'remove-option'
																	) {
																		setSelectedAttendanceStatusIdList([]);
																		setSelectedStatusOptionValues([]);

																		return;
																	}
																	const selectedId = value?.map(
																		(status) => status?.id
																	);
																	setSelectedAttendanceStatusIdList(selectedId);
																	setSelectedStatusOptionValues(value);
																}}
																options={
																	AttendanceStatusDropDown &&
																	AttendanceStatusDropDown?.length > 0
																		? [
																				{ id: 'All', name: 'All' },
																				...AttendanceStatusDropDown,
																		  ]
																		: []
																}
																disableCloseOnSelect={true}
																getLimitTagsText={getStatusFilterLimits}
																ChipProps={{ style: { display: 'none' } }}
																renderInput={(params) => (
																	<ThemeProvider
																		theme={customAutoCompleteSearchBarTheme}
																	>
																		<TextComponent
																			{...params}
																			label=''
																			placeholder={''}
																		/>
																	</ThemeProvider>
																)}
																renderOption={(option, { selected }) => (
																	<>
																		<Checkbox
																			icon={
																				<CheckBoxOutlineBlankIcon fontSize='small' />
																			}
																			checkedIcon={
																				<CheckBoxIcon fontSize='small' />
																			}
																			style={{ marginRight: 8 }}
																			checked={selected}
																			color='primary'
																		/>
																		<Tooltip
																			title={`${option?.name || '- -'}`}
																			placement='right'
																		>
																			<Typography
																				className={`${ellipsis.ellipsis}`}
																			>
																				{`${option?.name || '- -'}`}
																			</Typography>
																		</Tooltip>
																	</>
																)}
																getOptionLabel={(option) => `${option?.name}`}
																getOptionSelected={(option, value) =>
																	option.id === value.id
																}
															/>
														</Box>
													</Box>

													<Box className={style.exportButtonDiv}>
														<ExportButtonWithLoader
															generateCSV={generateCSV}
															isDisabled={isEmpty(
																attendanceApprovalRequests?.user_attendance_request ||
																	[]
															)}
														/>
													</Box>
												</Box>
											</form>
										</ThemeProvider>
									)}
								</Form>
							</Box>
						</Box>

						<CardContent>
							{!isAttendanceRequestLoading ? (
								<Box className={style.tableContainer}>
									{attendanceTableList && attendanceTableList?.length > 0 ? (
										<TableContainer>
											<Table stickyHeader>
												<TableHead>
													<TableRow>
														<CustomTableCell className={commonStyle.headerCell}>
															RESOURCE ID
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															RESOURCE NAME
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															USER TYPE
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															DATE
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															IN TIME
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															OUT TIME
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															ATTENDANCE STATUS
														</CustomTableCell>
														<CustomTableCell className={commonStyle.headerCell}>
															REASON
														</CustomTableCell>
													</TableRow>
												</TableHead>

												<TableBody>
													{attendanceTableList &&
														attendanceTableList?.length > 0 &&
														attendanceTableList
															?.slice(
																page * rowsPerPage,
																page * rowsPerPage + rowsPerPage
															)
															.map((data) => {
																return (
																	<TableRow hover={true}>
																		<CustomTableCell>
																			<Tooltip title={data?.empId || '--'}>
																				<Typography
																					className={`${style.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{data?.empId || '--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={data?.resourceName || '--'}
																			>
																				<Typography
																					className={`${style.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{data?.resourceName || '--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			{data?.userType || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{data.date || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			{data.inTime || '--'}
																		</CustomTableCell>

																		<CustomTableCell>
																			{data.outTime || '--'}
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip
																				title={
																					data?.status === 'Submitted'
																						? 'Pending'
																						: data?.status || '--'
																				}
																			>
																				<Typography
																					className={`${style.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{data?.status === 'Submitted'
																						? 'Pending'
																						: data?.status || '--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																		<CustomTableCell>
																			<Tooltip title={data?.reason || '--'}>
																				<Typography
																					className={`${style.dataCell} ${ellipsis.ellipsis}`}
																				>
																					{data?.reason || '--'}
																				</Typography>
																			</Tooltip>
																		</CustomTableCell>
																	</TableRow>
																);
															})}
												</TableBody>
											</Table>
											<Box display='flex' justifyContent='flex-end'>
												<TablePagination
													rowsPerPageOptions={[5, 10, 25]}
													count={
														attendanceApprovalRequests?.user_attendance_request
															?.length || 0
													}
													rowsPerPage={rowsPerPage}
													page={page}
													onPageChange={handleChangePage}
													onChangeRowsPerPage={handleChangeRowsPerPage}
												/>
											</Box>
										</TableContainer>
									) : (
										<Box className={style.noData}>
											<Typography className={commonStyle.noDataLabel}>
												No data found
											</Typography>
										</Box>
									)}
								</Box>
							) : (
								<Box className={style.noData}>
									<Box className={style.loading}>Loading</Box>
									<CircularProgress />
								</Box>
							)}
						</CardContent>
					</Card>
				</ThemeProvider>
			</Box>
		</>
	);
};
