import React, { useContext, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';

import {
	Box,
	Dialog,
	ThemeProvider,
	Typography,
	Button,
	Avatar,
	CircularProgress,
	CircularProgressProps,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { modalFormTheme } from '../../Layout/Theme.component';
import { ellipsisStyle, modalFormStyle } from '../../Layout/styles';
import CloseIcon from '@material-ui/icons/Close';
import { SpeakerNotes } from '@material-ui/icons';
import {
	SubmittedTimesheet,
	TimesheetDetails,
	HolidayProps,
} from './Timesheet.model';
import { TimesheetStyles } from './TimesheetStyles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
	useGetTimesheetEntriesForWeekQuery,
	useGetTimesheetStatusQuery,
	useGetTimesheetTotalHoursQuery,
	useApproveTimesheetMutation,
	useUpdateTimesheetStatusMutation,
	useGetLocationIdByUserIdQuery,
	useGetOrgHolidaysByUserQuery,
	useInsertTimesheetSubmissionNotesMutation,
} from '../../generated/graphql';
import { Loading } from 'ra-ui-materialui';
import dayjs from 'dayjs';
import ConfirmationModal from '../../SharedComponents/ConfirmationModal.component';
import {
	TIME_SHEET_APPROVAL_CONFIRMATION_TEXT,
	TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL,
	TIME_SHEET_APPROVAL_SUCCESS_TEXT,
	TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT,
	TIME_SHEET_REJECT_CONFIRMATION_TEXT,
	TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT,
	TIME_SHEET_REJECT_MODAL_BUTTON_LABEL,
	TIME_SHEET_REJECT_SUCCESS_TEXT,
	TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL,
} from './constant';
import { TimesheetDetailModalStyle } from './TimesheetStyles';
import { UserProfileContext } from '../../App';
import { getSubmittedTimesheet } from './Timesheet.utils';
import { head } from 'lodash';
import { ExcludedLeaveProps } from '../../SharedComponents/model';
import holiday from '../../assets/holiday.png';
import TimesheetCommentCapture from './TimesheetCommentCapture.component';
import useGetExcludedLeaves from './useGetExcluedLeaves';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

interface TimesheetPendingApprovalDetailsModalProps {
	onClose: () => void;
	open: boolean;
	timesheet?: TimesheetDetails;
	onSuccess: () => void;
}

const CircularProgressWithLabel = (
	props: CircularProgressProps & { value: number }
) => {
	return (
		<Box position='relative' display='inline-flex'>
			<CircularProgress variant='determinate' {...props} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position='absolute'
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<Typography
					className={TimesheetDetailModalStyle().billablePercentageLabel}
				>{`${props.value}%`}</Typography>
			</Box>
		</Box>
	);
};

export const TimesheetPendingApprovalDetailsModal = (
	props: TimesheetPendingApprovalDetailsModalProps
) => {
	const {
		id: userId,
		noOfLocations,
		dateFormat,
	} = useContext<any>(UserProfileContext);
	const [isConfirmationModalShown, setIsConfirmationModalShown] =
		useState(false);
	const [isRejectTimesheet, setIsRejectTimesheet] = useState(false);
	const [isMoveToDraft, setIsMoveToDraft] = useState(false);
	const { open, onClose, timesheet, onSuccess } = props;
	const formStyles = modalFormStyle();
	const pendingApprovaDetailsStyles = TimesheetDetailModalStyle();
	const [submittedTimesheet, setSubmittedTimesheet] =
		useState<SubmittedTimesheet[]>();
	const [organizationHoliday, setOrganizationHoliday] = useState<
		HolidayProps[] | undefined
	>();
	const [isCommentCaptureShown, setIsCommentCaptureShown] = useState(false);
	const timesheetStyle = TimesheetStyles();
	const ellipsisStyles = ellipsisStyle();
	const notify = useNotify();
	const [selectedUserLocationId, setSelectedUserLocationId] = useState();
	const { data: orgHoliday } = useGetOrgHolidaysByUserQuery({
		variables: {
			filter:
				selectedUserLocationId && noOfLocations && noOfLocations > 0
					? {
							date: {
								_gte: timesheet?.startDate,
								_lte: timesheet?.endDate,
							},
							is_restricted: { _eq: false },
							location_id: { _eq: selectedUserLocationId },
					  }
					: {
							date: {
								_gte: timesheet?.startDate,
								_lte: timesheet?.endDate,
							},
							is_restricted: { _eq: false },
					  },
			optionalHolidayFilter:
				selectedUserLocationId && noOfLocations && noOfLocations > 0
					? {
							user_id: { _eq: timesheet?.user?.id },
							org_holiday: {
								date: {
									_gte: timesheet?.startDate,
									_lte: timesheet?.endDate,
								},
								location_id: { _eq: selectedUserLocationId },
							},
					  }
					: {
							user_id: { _eq: timesheet?.user?.id },
							org_holiday: {
								date: {
									_gte: timesheet?.startDate,
									_lte: timesheet?.endDate,
								},
							},
					  },
		},
		fetchPolicy: 'network-only',
		skip: !selectedUserLocationId && noOfLocations && noOfLocations > 0,
	});

	const { data: timesheetEntries, loading: isTimesheetEntriesLoading } =
		useGetTimesheetEntriesForWeekQuery({
			variables: {
				userId: timesheet?.user?.id,
				startDate: timesheet?.startDate,
				endDate: timesheet?.endDate,
			},
			fetchPolicy: 'network-only',
		});
	const { data: weekHoursByday } = useGetTimesheetTotalHoursQuery({
		variables: {
			userId: timesheet?.user?.id,
			startDate: timesheet?.startDate,
			endDate: timesheet?.endDate,
		},
		fetchPolicy: 'network-only',
	});

	const [excludedLeaves] = useGetExcludedLeaves(
		timesheet?.startDate,
		timesheet?.endDate,
		timesheet?.user?.id,
		selectedUserLocationId
	);

	const [insertTimesheetSubmissionNotes] =
		useInsertTimesheetSubmissionNotesMutation();

	const { data: locationDetails } = useGetLocationIdByUserIdQuery({
		variables: { userId: timesheet?.user?.id },
		fetchPolicy: 'network-only',
	});
	const { data: timesheetStatus } = useGetTimesheetStatusQuery();
	const [approveTimeSheet, { loading: isApproveTimesheetLoading }] =
		useApproveTimesheetMutation();
	const [updateTimeSheetStatus, { loading: isRejectTimesheetLoading }] =
		useUpdateTimesheetStatusMutation();

	useEffect(() => {
		if (!locationDetails) {
			return;
		}
		const locationId =
			locationDetails && locationDetails?.user_by_pk
				? locationDetails?.user_by_pk?.user_type === 'employee'
					? locationDetails?.user_by_pk?.employee?.location_id
					: head(locationDetails?.user_by_pk?.contractors)?.location_id
				: null;
		setSelectedUserLocationId(locationId);
	}, [locationDetails]);

	useEffect(() => {
		if (!orgHoliday) {
			setOrganizationHoliday([]);
			return;
		}
		const regularHolidays =
			orgHoliday?.org_holidays?.map((value) => {
				return {
					name: value?.name,
					date: value?.date,
					locationName: value?.org_location?.name || '',
				};
			}) || [];
		const optionalHolidays =
			orgHoliday?.user_optional_holiday_mapping?.map((value) => {
				return {
					name: value?.org_holiday?.name,
					date: value?.org_holiday?.date,
					locationName: value?.org_holiday?.org_location?.name || '',
				};
			}) || [];
		setOrganizationHoliday([...regularHolidays, ...optionalHolidays]);
	}, [noOfLocations, orgHoliday, selectedUserLocationId]);

	useEffect(() => {
		if (!timesheetEntries) {
			return;
		}
		const reporteeSubmittedTimesheet = getSubmittedTimesheet(timesheetEntries);
		if (reporteeSubmittedTimesheet) {
			setSubmittedTimesheet(reporteeSubmittedTimesheet);
		}
	}, [timesheetEntries]);

	const getTotalHoursByDay = (date: string) => {
		if (!date || !weekHoursByday) {
			return;
		}
		const timesheetEntryDate = weekHoursByday.timesheet_daily_hours.find(
			(hoursByday) => hoursByday?.date === date
		);
		const workingHoursByDay = timesheetEntryDate?.sum.split(':');
		return workingHoursByDay
			? `${workingHoursByDay[0]}:${workingHoursByDay[1]}`
			: '00:00';
	};

	const getTaskTotalHours = (taskWorkingHours: string[]) => {
		if (!taskWorkingHours) {
			return;
		}
		const taskHoursAndMinutes = taskWorkingHours
			.map((hours) => hours.split(':'))
			.map((hoursAndMinutes) =>
				dayjs.duration({
					hours: Number(hoursAndMinutes[0]),
					minutes: Number(hoursAndMinutes[1]),
					seconds: Number(hoursAndMinutes[2]),
				})
			)
			.reduce(
				(total, durations) => total.add(durations),
				dayjs.duration({
					hours: 0,
					minutes: 0,
					seconds: 0,
				})
			);

		return `${Math.floor(
			taskHoursAndMinutes.asHours()
		)}: ${taskHoursAndMinutes.format('mm')}`;
	};

	const handleMoveTimesheetToDraft = async () => {
		if (!timesheetStatus) {
			return;
		}
		const draftTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'draft'
		);
		const moveToDraftRes = await updateTimeSheetStatus({
			variables: {
				timesheetSubmissionId: timesheet?.timesheetSubmissionId,
				timesheetStatusId: draftTimesheetStatus?.id,
			},
		});
		if (!moveToDraftRes.errors) {
			onSuccess();
			setIsRejectTimesheet(false);
			setIsMoveToDraft(false);
			setIsConfirmationModalShown(false);
			notify(TIME_SHEET_MOVE_DRAFT_SUCCESS_TEXT);
			onClose();
		}
	};

	const handleApproveTimesheet = async () => {
		if (!timesheetStatus) {
			return;
		}
		const approvedTimesheetStatus = timesheetStatus.timesheet_status.find(
			(status) => status.value === 'approved'
		);
		const timesheetApproval = await approveTimeSheet({
			variables: {
				submissionIds: timesheet?.timesheetSubmissionId,
				approvedBy: userId,
				timesheetStatusId: approvedTimesheetStatus?.id,
			},
		});
		if (!timesheetApproval.errors) {
			onSuccess();
			setIsRejectTimesheet(false);
			setIsMoveToDraft(false);
			setIsConfirmationModalShown(false);
			notify(TIME_SHEET_APPROVAL_SUCCESS_TEXT);
			onClose();
		}
	};

	const handleRejectTimesheet = async () => {
		if (!timesheetStatus) {
			return;
		}
		setIsCommentCaptureShown(true);
	};

	const rejectTimesheets = async (notes: string) => {
		try {
			if (!timesheetStatus) {
				return;
			}
			const rejectedTimesheetStatus = timesheetStatus.timesheet_status.find(
				(status) => status.value === 'rejected'
			);
			const timesheetRejectNotesMutationRes =
				await insertTimesheetSubmissionNotes({
					variables: {
						data: {
							notes: notes,
							type: 'rejected',
							timesheet_submission_id: timesheet?.timesheetSubmissionId,
						},
					},
				});

			if (timesheetRejectNotesMutationRes.errors) {
				notify(
					head(timesheetRejectNotesMutationRes.errors)?.message ||
						'Rejection failed'
				);
				return;
			}
			setIsCommentCaptureShown(false);
			const timesheetReject = await updateTimeSheetStatus({
				variables: {
					timesheetSubmissionId: timesheet?.timesheetSubmissionId,
					timesheetStatusId: rejectedTimesheetStatus?.id,
				},
			});
			if (!timesheetReject.errors) {
				onSuccess();
				setIsRejectTimesheet(false);
				setIsMoveToDraft(false);
				setIsConfirmationModalShown(false);
				notify(TIME_SHEET_REJECT_SUCCESS_TEXT);
				onClose();
			}
		} catch (error) {
			console.log(error);
		}
	};
	const checkHoliday = (date: string) => {
		if (!date && !organizationHoliday) {
			return;
		}
		const holidayList = organizationHoliday?.filter(
			(holiday) => holiday?.date === date
		);
		const holidayTitle =
			holidayList &&
			holidayList?.length > 1 &&
			holidayList?.map((item) =>
				item?.locationName
					? `${item?.locationName}:${item?.name ? item?.name : ''}`
					: `${item?.name ? item?.name : ''}`
			);
		return holidayList && holidayList?.length > 0 ? (
			<Box width='1px' position='relative' top='-4px'>
				<Tooltip
					title={
						holidayTitle
							? holidayTitle.join(',')
							: head(holidayList)?.date
							? head(holidayList)?.locationName
								? `${head(holidayList)?.locationName}:${
										head(holidayList)?.name
								  }`
								: `${head(holidayList)?.name}`
							: ''
					}
					placement='right'
				>
					<img src={holiday} alt='Holiday' width='24px' height='18px' />
				</Tooltip>
			</Box>
		) : (
			<></>
		);
	};

	const getConfirmationMessage = () => {
		if (isRejectTimesheet) {
			return TIME_SHEET_REJECT_CONFIRMATION_TEXT;
		}
		if (isMoveToDraft) {
			return TIME_SHEET_MOVE_TO_DRAFT_CONFIRMATION_TEXT;
		}
		return TIME_SHEET_APPROVAL_CONFIRMATION_TEXT;
	};

	const getSubmitButtonLabel = () => {
		if (isRejectTimesheet) {
			return TIME_SHEET_REJECT_MODAL_BUTTON_LABEL;
		}
		if (isMoveToDraft) {
			return TIME_SHEET_MAKE_DRAFT_MODAL_BUTTON_LABEL;
		}
		return TIME_SHEET_APPROVAL_MODAL_BUTTON_LABEL;
	};

	return (
		<>
			<ThemeProvider theme={modalFormTheme}>
				<Dialog
					disableBackdropClick
					open={open}
					onClose={onClose}
					aria-labelledby='dialog-title'
					aria-describedby='dialog-description'
					fullWidth={true}
					maxWidth={false}
				>
					<Box className={pendingApprovaDetailsStyles.container}>
						<Box className={formStyles.headerContainer}>
							<Typography className={formStyles.heading}>
								TIMESHEET DETAILS
							</Typography>
							<CloseIcon className={formStyles.closeIcon} onClick={onClose} />
						</Box>

						{/* TOOLBAR */}
						<Box className={pendingApprovaDetailsStyles.actionContainer}>
							{timesheet?.dateRange ? (
								<Box
									className={`${timesheetStyle.avatarContainer} ${pendingApprovaDetailsStyles.userDetailsContainer}`}
								>
									<Avatar
										className={timesheetStyle.avatar}
										src={`${timesheet?.user?.profilePic || ''}`}
									>
										{timesheet?.user?.name?.charAt(0).toUpperCase() || ''}
									</Avatar>
									<Typography className={timesheetStyle.employeeName}>
										{`${timesheet?.user?.name || ''}`}
									</Typography>
									<Box ml={1} mr={1}>
										<Typography
											className={pendingApprovaDetailsStyles.breadCrum}
										>
											{'>>'}
										</Typography>
									</Box>
									<Typography className={pendingApprovaDetailsStyles.dateRange}>
										{`Approve timesheet for ${timesheet?.dateRange}`}
									</Typography>
								</Box>
							) : (
								<Typography className={pendingApprovaDetailsStyles.dateRange}>
									- -
								</Typography>
							)}
							<Box display={'flex'}>
								{timesheet?.timesheetStatus === 'submitted' && (
									<Box marginRight={'6px'}>
										<Button
											variant='contained'
											className={`${pendingApprovaDetailsStyles.button} ${formStyles.rejectButton}`}
											onClick={() => {
												setIsMoveToDraft(false);
												setIsRejectTimesheet(true);
												setIsConfirmationModalShown(true);
											}}
											disabled={
												!timesheet?.dateRange || isRejectTimesheetLoading
											}
										>
											Reject
										</Button>
									</Box>
								)}
								{timesheet?.timesheetStatus === 'submitted' && (
									<Box>
										<Button
											variant='contained'
											className={`${pendingApprovaDetailsStyles.button} ${formStyles.approveButton}`}
											onClick={() => {
												setIsMoveToDraft(false);
												setIsRejectTimesheet(false);
												setIsConfirmationModalShown(true);
											}}
											disabled={
												!timesheet?.dateRange || isApproveTimesheetLoading
											}
										>
											Approve
										</Button>
									</Box>
								)}
							</Box>

							{timesheet?.timesheetStatus === 'approved' && (
								<Button
									variant='contained'
									className={`${pendingApprovaDetailsStyles.button} ${timesheetStyle.rejectButton}`}
									onClick={() => {
										setIsRejectTimesheet(false);
										setIsMoveToDraft(true);
										setIsConfirmationModalShown(true);
									}}
									disabled={!timesheet?.dateRange}
								>
									Move to Draft
								</Button>
							)}
						</Box>

						{/* BODY */}
						<Box className={pendingApprovaDetailsStyles.kpiContainer}>
							<Box className={pendingApprovaDetailsStyles.kpi}>
								<CircularProgressWithLabel
									value={Number(timesheet?.billablePercentage || 0)}
									variant='determinate'
									thickness={5.6}
									className={pendingApprovaDetailsStyles.circularProgress}
								/>
								<Box
									className={pendingApprovaDetailsStyles.billableHoursContainer}
								>
									<Typography
										className={pendingApprovaDetailsStyles.billableHours}
									>
										{`${timesheet?.billableHours || '00:00'} hrs`}
									</Typography>
									<Box display='flex' alignItems='center'>
										<Box
											className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.billableHoursIcon}`}
										></Box>
										<Typography
											className={pendingApprovaDetailsStyles.billableHoursLabel}
										>
											Billable
										</Typography>
									</Box>
								</Box>
								<Box
									className={pendingApprovaDetailsStyles.billableHoursContainer}
								>
									<Typography
										className={pendingApprovaDetailsStyles.billableHours}
									>
										{`${timesheet?.nonBillableHours || '00:00'} hrs`}
									</Typography>
									<Box display='flex' alignItems='center'>
										<Box
											className={`${pendingApprovaDetailsStyles.hoursIcon} ${pendingApprovaDetailsStyles.nonBillableHoursIcon}`}
										></Box>
										<Typography
											className={pendingApprovaDetailsStyles.billableHoursLabel}
										>
											Non-Billable
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box
								className={pendingApprovaDetailsStyles.kpi}
								justifyContent='center'
							>
								<Box
									width='100%'
									display='flex'
									alignItems='center'
									justifyContent='center'
								>
									<Box className={pendingApprovaDetailsStyles.iconContainer}>
										<AccessTimeIcon
											className={pendingApprovaDetailsStyles.accessTimeIcon}
										/>
									</Box>
									<Box
										display='flex'
										alignItems='flex-end'
										flexDirection='column'
									>
										<Typography
											className={pendingApprovaDetailsStyles.totalHoursValue}
										>
											{`${timesheet?.totalHours || '00:00'}`}
										</Typography>
										<Typography className={timesheetStyle.totalHoursLabel}>
											TOTAL HOURS
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
						{isTimesheetEntriesLoading ? (
							<Loading />
						) : (
							<Box mt={3}>
								<Box>
									<Box
										className={pendingApprovaDetailsStyles.timesheetsContainer}
									>
										<Box
											className={
												pendingApprovaDetailsStyles.timesheetContentContainer
											}
										>
											<Box className={timesheetStyle.timesheetContent}></Box>
											<Box className={timesheetStyle.timesheetHoursContainer}>
												<Box className={timesheetStyle.hoursContainer}>
													{timesheet?.timesheetPeriod &&
														timesheet?.timesheetPeriod.map((date, index) => (
															<Box
																key={index}
																className={
																	pendingApprovaDetailsStyles.navigationButtonLabelContainer
																}
															>
																<Typography
																	className={`${
																		timesheetStyle.dayNavigationButtonLabel
																	} ${
																		getTotalHoursByDay(date) === '00:00' &&
																		pendingApprovaDetailsStyles.noEntryHours
																	}`}
																>
																	<Box display='flex'>
																		<Box fontFamily={'Manrope-extrabold'}>
																			{dayjs(date).format('ddd').toUpperCase()}
																		</Box>
																		<Box>
																			{excludedLeaves?.length > 0 &&
																				excludedLeaves?.map(
																					(
																						leave: ExcludedLeaveProps,
																						index: any
																					) => {
																						if (date === leave?.leaveDate) {
																							return (
																								<Box
																									className={
																										timesheetStyle.leaveCircle
																									}
																									key={index}
																								>
																									<Tooltip
																										title={leave?.name}
																										placement='right'
																									>
																										<div>L</div>
																									</Tooltip>
																								</Box>
																							);
																						}
																					}
																				)}
																		</Box>
																		<Box>{checkHoliday(date)}</Box>
																	</Box>
																</Typography>
																<Tooltip
																	title={dayjs(date).format(dateFormat)}
																	placement='right'
																>
																	<Box
																		className={`${timesheetStyle.dayNavigationButtonLabel} 
																		${pendingApprovaDetailsStyles.date}
																		${ellipsisStyles.ellipsis}
																		`}
																		position={'relative'}
																		right={'15px'}
																	>
																		{dayjs(date).format(dateFormat)}
																	</Box>
																</Tooltip>
															</Box>
														))}
												</Box>
												<Box className={timesheetStyle.totalHoursContainer}>
													<Typography className={timesheetStyle.totalHours}>
														{timesheet?.totalHours}
													</Typography>
													<Typography
														className={timesheetStyle.totalHoursLabel}
													>
														TOTAL HOURS
													</Typography>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box>
									<Box
										className={pendingApprovaDetailsStyles.timesheetsContainer}
									>
										{submittedTimesheet &&
											submittedTimesheet.map((timesheetEntry, index) => (
												<Box
													key={index}
													className={
														pendingApprovaDetailsStyles.timesheetContentContainer
													}
												>
													<Box className={timesheetStyle.timesheetContent}>
														<Tooltip
															title={`${timesheetEntry?.projectName || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${timesheetStyle.projectName} ${ellipsisStyles.ellipsis}`}
															>
																{timesheetEntry?.projectName || '- -'}
															</Typography>
														</Tooltip>
														<Tooltip
															title={`${timesheetEntry?.companyName || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${timesheetStyle.companyName} ${ellipsisStyles.ellipsis}`}
															>
																{timesheetEntry?.companyName || '- -'}
															</Typography>
														</Tooltip>
														<Tooltip
															title={`${timesheetEntry?.taskName || '- -'}`}
															placement='right'
														>
															<Typography
																className={`${timesheetStyle.task} ${ellipsisStyles.ellipsis}`}
															>
																{timesheetEntry?.taskName || '- -'}
															</Typography>
														</Tooltip>
													</Box>
													<Box
														className={timesheetStyle.timesheetHoursContainer}
													>
														<Box className={timesheetStyle.hoursContainer}>
															{timesheet?.timesheetPeriod?.map(
																(date, index) => {
																	const taskWorkingHours =
																		timesheetEntry.timesheets.filter(
																			(timesheet) => timesheet.date === date
																		);
																	const workingHours =
																		taskWorkingHours?.length > 0
																			? taskWorkingHours
																					.map((taskHoursAndMinutes) =>
																						taskHoursAndMinutes.working_hours.split(
																							':'
																						)
																					)
																					.map((hoursAndMinutes) =>
																						dayjs.duration({
																							hours: Number(hoursAndMinutes[0]),
																							minutes: Number(
																								hoursAndMinutes[1]
																							),
																							seconds: Number(
																								hoursAndMinutes[2]
																							),
																						})
																					)
																					.reduce(
																						(total, durations) =>
																							total.add(durations),
																						dayjs.duration({
																							hours: 0,
																							minutes: 0,
																							seconds: 0,
																						})
																					)
																					.format('HH:mm')
																			: 0;
																	const notes = taskWorkingHours
																		?.map((note) => note?.notes)
																		.filter(Boolean);

																	return (
																		<Box display={'flex'} key={index}>
																			{workingHours ? (
																				<Box display='flex'>
																					<Typography
																						className={
																							timesheetStyle.dayNavigationButtonLabel
																						}
																					>
																						{workingHours}
																					</Typography>
																					{notes?.length > 0 && (
																						<Box>
																							<Tooltip
																								title={notes || '- -'}
																								placement='top'
																								arrow
																							>
																								<IconButton
																									className={
																										timesheetStyle.iconContainer
																									}
																								>
																									<SpeakerNotes
																										className={
																											timesheetStyle.icon
																										}
																									/>
																								</IconButton>
																							</Tooltip>
																						</Box>
																					)}
																				</Box>
																			) : (
																				<Typography
																					className={
																						pendingApprovaDetailsStyles.noEntryHours
																					}
																				>
																					00:00
																				</Typography>
																			)}
																		</Box>
																	);
																}
															)}
														</Box>
														<Box className={timesheetStyle.totalHoursContainer}>
															<Typography className={timesheetStyle.totalHours}>
																{getTaskTotalHours(
																	timesheetEntry.timesheets.map(
																		(taskHours) => taskHours.working_hours
																	)
																)}
															</Typography>
														</Box>
													</Box>
												</Box>
											))}
									</Box>
								</Box>
							</Box>
						)}
						<Box className={pendingApprovaDetailsStyles.timesheetsContainer}>
							<Box className={timesheetStyle.timesheetTotalHoursContainer}>
								<Box className={timesheetStyle.timesheetContent}>
									<Typography className={timesheetStyle.totalHoursTitle}>
										TOTAL HOURS
									</Typography>
								</Box>
								<Box className={timesheetStyle.timesheetHoursContainer}>
									<Box className={timesheetStyle.hoursContainer}>
										{timesheet?.timesheetPeriod &&
											timesheet?.timesheetPeriod.map((date, index) => (
												<Box
													key={index}
													className={
														pendingApprovaDetailsStyles.navigationButtonLabelContainer
													}
												>
													<Typography
														className={`${timesheetStyle.totalHoursRowData} ${
															getTotalHoursByDay(date) === '00:00' &&
															timesheetStyle.noEntryTotalHoursRowData
														}`}
													>
														{getTotalHoursByDay(date)}
													</Typography>
												</Box>
											))}
									</Box>
									<Box className={timesheetStyle.totalHoursContainer}>
										<Typography className={timesheetStyle.totalHours}>
											{timesheet?.totalHours || '00:00'}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Dialog>
			</ThemeProvider>
			<ConfirmationModal
				open={isConfirmationModalShown}
				onClose={() => {
					setIsConfirmationModalShown(false);
					setIsRejectTimesheet(false);
					setIsMoveToDraft(false);
				}}
				confirmationMessage={getConfirmationMessage()}
				onSubmit={() => {
					if (isRejectTimesheet) {
						handleRejectTimesheet();
						return;
					}
					if (isMoveToDraft) {
						handleMoveTimesheetToDraft();
						return;
					}
					handleApproveTimesheet();
				}}
				submitButtonLabel={getSubmitButtonLabel()}
				isWarningConfirmation={isRejectTimesheet || isMoveToDraft}
			/>
			<TimesheetCommentCapture
				open={isCommentCaptureShown}
				onClose={() => {
					setIsCommentCaptureShown(false);
				}}
				onSubmit={(value) => {
					rejectTimesheets(value?.notes || '');
				}}
				okButtonLabel={'Save'}
			/>
		</>
	);
};

export default TimesheetPendingApprovalDetailsModal;
